import cardanoWalletIcon from 'Assets/Icons/Blockchains/cardano.png';
import ethWalletIcon from 'Assets/Icons/Blockchains/ethereum.png';
import baseWalletIcon from 'Assets/Icons/Blockchains/base.png';
import polygonWalletIcon from 'Assets/Icons/Blockchains/polygon.png';
import optimismWalletIcon from 'Assets/Icons/Blockchains/optimism.png';
import arbitrumWalletIcon from 'Assets/Icons/Blockchains/arbitrum.png';
import avalancheWalletIcon from 'Assets/Icons/Blockchains/avalanche.png';
import binancesmartchainWalletIcon from 'Assets/Icons/Blockchains/binancesmartchain.png';
import bitcoinWalletIcon from 'Assets/Icons/Blockchains/bitcoin.png';
import tezosWalletIcon from 'Assets/Icons/Blockchains/tezos.png';
import solanaWalletIcon from 'Assets/Icons/Blockchains/solana.png';

export const getBlockchainIcon = (code) => {
    switch (code) {
        case "cardano":
            return cardanoWalletIcon;
        case "ethereum":
            return ethWalletIcon;
        case "base":
            return baseWalletIcon;
        case "polygon":
            return polygonWalletIcon;
        case "optimism":
            return optimismWalletIcon;
        case "arbitrum":
            return arbitrumWalletIcon;
        case "avalanche":
            return avalancheWalletIcon;
        case "bsc":
            return binancesmartchainWalletIcon;
        case "bitcoin":
            return bitcoinWalletIcon;
        case "tezos":
            return tezosWalletIcon;
        case "solana":
            return solanaWalletIcon;
        default:
            break;
    }
}