import "../Icons.scss";

const EditIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.67188 2.6665H3.00521C2.65159 2.6665 2.31245 2.80698 2.0624 3.05703C1.81235 3.30708 1.67188 3.64622 1.67188 3.99984V13.3332C1.67188 13.6868 1.81235 14.0259 2.0624 14.276C2.31245 14.526 2.65159 14.6665 3.00521 14.6665H12.3385C12.6922 14.6665 13.0313 14.526 13.2814 14.276C13.5314 14.0259 13.6719 13.6868 13.6719 13.3332V8.6665"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6719 1.66665C12.9371 1.40144 13.2968 1.25244 13.6719 1.25244C14.0469 1.25244 14.4067 1.40144 14.6719 1.66665C14.9371 1.93187 15.0861 2.29158 15.0861 2.66665C15.0861 3.04173 14.9371 3.40144 14.6719 3.66665L8.33854 9.99999L5.67188 10.6667L6.33854 7.99999L12.6719 1.66665Z"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditIcon;
