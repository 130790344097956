import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import PageLoader from "App/Components/PageLoader"
const LayoutCard1 = (props) => {
    const onNFTloads = (e) => {
        props?.onNFTLoad()
    }
    return (
        <>
            {(props?.isMultiGallery && !props?.loadingData) && 
                <PageLoader className={'multi-layout-loading'} />
            }
            {props?.parentCards?.map((parentCard, i) =>
                {
                    let styles = {left: `${parentCard?.metaPos?.left}%`, top: `${parentCard?.metaPos?.top}%`,  
                    width: `${parentCard?.metaPos?.width}%`, height: `${parentCard?.metaPos?.height}%`};
                    let imgSrc = parentCard?.nftcdnimage ?? parentCard?.details?.nftcdnimage;
                    if(props?.loadSmallSize)
                        imgSrc = parentCard?.nftcdnimage256 ?? parentCard?.details?.nftcdnimage256;
                    return <div key={'b' + i} className="preview-grid-show  items-center justify-center absolute"
                    style={styles}>
                    <GalleryImages
                        className={'w-full'}
                        onNFTLoad={onNFTloads}
                        clearCache={props?.clearCache}
                        frameName={`frame${i}`}
                        onFrameLoaded={props?.onFrameLoaded}
                        galleryPreview={true}
                        galleryNew={parentCard}
                        src={imgSrc}
                        compress={true}
                        nftGalleryNew={true}
                        imageRatioCalculated={props?.imageRatioCalculated}
                        onChainRenders={props?.onChainRenders}
                        onChainReRender={props.onChainReRender}
                    />

                </div>
                }
            )}</>
    )
}

export default LayoutCard1;