import WalletSelect from "App/Components/Ui/WalletSelect";
import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import useWallet from "App/hooks/use-wallet";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import useAuth from "App/hooks/use-auth";
import useWalletEVM from "App/hooks/use-wallet-evm";
import useWalletBitcoin from "App/hooks/use-wallet-bitcoin";
import useWalletTezos from "App/hooks/use-wallet-tezos";
import useWalletSolana from "App/hooks/use-wallet-solana";

const WalletSelector = () => {
    const [installedWallets, setInstalledWallets] = useState([])
    const [loading, setLoading] = useState();
    const walletConnector = useWallet()
    const walletConnectorEVM = useWalletEVM();
    const walletConnectorBitcoin = useWalletBitcoin();
    const walletConnectorTezos = useWalletTezos();
    const walletConnectorSolana = useWalletSolana();
    const [{ currentWallet }] = useStore(false);
    // eslint-disable-next-line no-unused-vars
    const [isLoggedIn, logout, login, guest, isGuest, removeItem] = useAuth();
    const selectWallet = useCallback(async (walletName, isEvmChain) => {
        if (isEvmChain)
        {
            await walletConnectorEVM.connectWallet(walletName);
        }
        else if (walletName === 'Bitcoin Ordinals')
        {            
            await walletConnectorBitcoin.connectWallet();
        }
        else if (walletName === 'Tezos')
        {
            await walletConnectorTezos.connectWallet();
        }
        else if (walletName === 'Solana')
        {
            await walletConnectorSolana.connectWallet();
        }
        else
        {
            await walletConnector.connectWallet(walletName);
        }        
    }, [walletConnector, walletConnectorEVM, walletConnectorTezos, walletConnectorBitcoin, walletConnectorSolana])
    
    useEffect(() => {
        const name = storage().get('currentWallet');
        if (name) {
            selectWallet(name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        const availableWallet = walletConnector.availableWallet();
        setInstalledWallets(availableWallet)
        const { connecting } = walletConnector;
        setLoading(connecting)
    }, [walletConnector])
    return (
        <WalletSelect availble={installedWallets} current={currentWallet} onSelect={selectWallet} loading={loading} />
    )
}
export default WalletSelector;