import "./Purchase.scss";
import Panel from "App/Components/Ui/Panel";
import Table from "App/Components/Ui/Table";
import { useState, useEffect, useCallback } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import TableHeader from "App/Components/Ui/TableHeader";
import tableSort from "App/Components/Ui/TableSort";
import Pagination from "App/Components/Ui/Pagination";
import { convertPriceToAda } from "App/Helper/utilities";
import FormatField from "App/Components/Ui/FormatField";
import exterNalLinkIcon from "Assets/Icons/external-link-grey.svg";
import SelectBox from "App/Components/Ui/SelectBox";
const paginationConfig = {
  perPage: 7,
};
const paymentStatusLink = "https://cardanoscan.io/transaction/";
const Purchase = () => {
  const SCREENSIZE = window.screen.width;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const stateFilterOption = [
    { value: "", name: "All", id: "none" },
    { value: "prepared", name: "In progress", id: "prepared" },
    { value: "finished", name: "Completed", id: "finished" },
    { value: "canceled", name: "Cancelled", id: "canceled" },
  ];
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [purchaseList, setPurchaseList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [sort, setSort] = useState({ direction: "", sortKey: "createdAt" });
  const [filteredData, setFilteredData] = useState("none");
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "";
      const direction = tableSort(dir);
      setSort({ direction, sortKey });
    },
    [sort]
  );
  const onPageChangeHandler = (currentPage) => {
    setPage(currentPage);
  };
  const getPurchaseListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { list, pageMeta } = res.data;
      const purchaseList = list.map((purchase) => {
        const transactionType = purchase?.transactionType?.split(" ") ?? [];
        purchase.createdAt = new Date(purchase.createdAt).toLocaleDateString(
          "en-us",
          { year: "numeric", month: "short", day: "numeric" }
        );
        purchase.currency = "ADA";
        purchase.description = `${purchase?.countNfts} x ${purchase?.asset?.name}`;
        purchase.state = `${
          purchase?.state === "prepared"
            ? "In progress"
            : purchase?.state === "finished"
            ? "Completed"
            : "Cancelled"
        }`;
        purchase.price = convertPriceToAda(purchase?.priceInLovelace);
        purchase.transactionType = transactionType.length
          ? transactionType[0]
          : "";
        return purchase;
      });
      setPurchaseList(purchaseList);
      setTotalPage(pageMeta.totalItems);
    },
    [dispatch]
  );

  const getPurchaseList = useCallback(() => {
    dispatch("showSpinner");
    let filter = {};
    if (sort.direction || filteredData) {
      if (sort.direction) {
        filter = {
          ...filter,
          sortBy: sort.sortKey,
          orderBy: sort.direction,
        };
      }
      if (filteredData && filteredData !== "none") {
        filter = {
          ...filter,
          state: filteredData,
        };
      }
    }
    triggerAPI(
      {
        url: `user/get/purchase-history`,
        data: {
          page: page,
          items: paginationConfig.perPage,
          filter,
        },
        method: "post",
      },
      getPurchaseListResult,()=>{
        dispatch("hideSpinner");
      }
    );
  }, [dispatch, getPurchaseListResult, page, sort, triggerAPI, filteredData]);

  useEffect(() => {
    getPurchaseList();
    dispatch("setPageTitle", "Purchase");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page, filteredData]);

  const onFilterChange = (e) => {
    setFilteredData(e?.value);
    setPage(1);
  };
  return (
    <div className={`w-full flex flex-col gap-2 h-full`}>
      <div className="purchase-section sm-none h-full">
        <div className="sm-none h-full">
          <Panel className="p-5 gap-5 h-full justify-between">
          <div className="gap-5 flex flex-col">
            <div className="ml-auto">
              <SelectBox
                className={`select-box-new cursor-pointer`}
                mainClassName={`purchase-select-filter`}
                itemsClassName={`pruchase-filter-items`}
                blueArrow={true}
                borderLess={false}
                currentId={filteredData}
                onChange={onFilterChange}
                options={stateFilterOption}
              />
            </div>

            <Table>
              <thead className="text-left purchase-table-head">
                <tr>
                  <th>
                    <TableHeader
                      label="Date"
                      sort={sort}
                      sortKey="createdAt"
                      onSort={onSortHandler}
                    />
                  </th>
                  <th>
                    <TableHeader label="Purchase Type" />
                  </th>
                  <th>
                    <TableHeader label="Description" />
                  </th>

                  <th>
                    <TableHeader label="Token" />
                  </th>
                  <th>
                    <TableHeader label="Amount" />
                  </th>
                  <th>
                    <TableHeader label="Transaction" />
                  </th>
                  <th>
                    <TableHeader label="State" />
                  </th>
                </tr>
              </thead>
              <tbody className="text-left purchase-table-body">
                {purchaseList?.map((purchase, index) => {
                  return (
                    <tr key={purchase?.id + "table" + index}>
                      <td>{purchase?.createdAt}</td>
                      <td>{purchase?.transactionType}</td>
                      <td>{purchase?.description}</td>
                      <td>{purchase?.currency}</td>
                      <td className="">{purchase?.price}</td>
                      <td>
                        {purchase?.txHash ? (
                          <a
                            href={`${paymentStatusLink}${purchase?.txHash}`}
                            target="_blank"
                            rel="noreferrer"
                            className="flex gap-2"
                          >
                            <FormatField
                              type="substring"
                              maxLength={8}
                              startLength={4}
                              endLength={4}
                              value={purchase?.txHash}
                            />
                            <img
                              src={exterNalLinkIcon}
                              alt="external link icon"
                            />
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="">{purchase?.state}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {!purchaseList?.length && (
              <div className="text-center no-rows pt-4 no-data">
               No purchases found.
              </div>
            )}
            </div>
            {!!purchaseList?.length && <div className="purchase-pagination mt-6 mb-5">
              <Pagination
                itemsPerPage={paginationConfig.perPage}
                rows={purchaseList?.length}
                total={totalPage}
                onPageChange={onPageChangeHandler}
                selected={page - 1}
                active={page}
              />
            </div>}
          </Panel>
        </div>
      </div>

      <div className="sm-block purchase-wrap">
        <div className="purchase-container-sm">
          {!(isPortrait&&SCREENSIZE<=550)&&<div className="ml-auto">
            <SelectBox
              className={`select-box-new cursor-pointer`}
              mainClassName={`purchase-select-filter flex justify-end`}
              itemsClassName={`pruchase-filter-items`}
              blueArrow={true}
              borderLess={false}
              currentId={filteredData}
              onChange={onFilterChange}
              options={stateFilterOption}
            />
          </div>}
          {purchaseList?.map((purchase, index) => {
            return (
              <div
                className="purchase-item flex justify-between flex-col gap-2"
                key={purchase?.id + "table" + index}
              >
                <div className="item-left flex gap-2 justify-between">
                  <div className="flex justify-between gap-6 grow shrink basis-[30%]">
                    <div className="text-left grey-w-color text-[0.625rem]">
                      {purchase?.createdAt}
                    </div>
                  </div>
                  <div className="flex gap-2 grow shrink basis-[50%] justify-between">
                    <div className="flex justify-between flex-col gap-[7px]">
                      <div className="text-left sm:text-[0.625rem] text-[0.75rem] highlight-color grey-w-color font-normal">
                        {purchase?.transactionType
                          ? purchase?.transactionType
                          : "NA"}
                      </div>
                      <div className="item-left flex gap-2">
                        <div className="text-left sm-date">
                          {purchase?.description}
                        </div>
                      </div>
                    </div>
                    <div className="sm:gap-6 gap-[0.375rem] flex justify-between ">
                      <div className="text-[0.625rem] grey-w-color font-normal mt-[2px]">
                        {purchase?.price} ADA
                      </div>
                      {purchase?.txHash && (
                        <a
                          href={`${paymentStatusLink}${purchase?.txHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={exterNalLinkIcon}
                            alt="external link icon"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="purchase-pagination mt-6 mb-5">
            <Pagination
              itemsPerPage={paginationConfig.perPage}
              rows={purchaseList?.length}
              total={totalPage}
              onPageChange={onPageChangeHandler}
              selected={page - 1}
              active={page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchase;
