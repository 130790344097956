import "../Icons.scss";

const FilterIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.726562 2H23.2716"
          stroke="#A0ABBB"
          strokeWidth="0.94"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M4.59375 9H19.3987"
          stroke="#A0ABBB"
          strokeWidth="0.94"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M7.6875 16H16.3125"
          stroke="#A0ABBB"
          strokeWidth="0.94"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default FilterIcon;
