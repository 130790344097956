import React, { useState } from "react";
import anywall from "Assets/Icons/anywall.svg";
import monet from "Assets/Icons/monetsociety.svg";
import theartbank from "Assets/Icons/theartbank.svg";
import cur8cafe from "Assets/Icons/cur8cafe.svg";
import splash from "Assets/Icons/splash.svg";
import monetmedia from "Assets/Icons/monetmedia.svg";
import styles from "./partnersmodule.module.scss";

const PartnersComponent = () => {
  const initialPartners = [
    {
      name: "Anywall",
      image: anywall,
      description:
        "Allows collectors to view art like never before on the world's best digital art displays available in a vast range of sizes and frame options.",
      link: "https://www.anywall.io/",
    },
    {
      name: "Monet Society",
      image: monet,
      description:
        "A club created to educate and incentivize creators and creative ecosystem stakeholders through a loyalty and recognition program.",
      link: "https://www.monetsociety.io/",
    },
    {
      name: "The Art Bank",
      image: theartbank,
      description:
        "Supports Web3 creators across multiple chains through digital art investment and financing.",
      link: "https://www.theartbank.io/",
    },
    {
      name: "Cur8 Cafés",
      image: cur8cafe,
      description:
        "Physical Web3 event spaces that host digital art galleries, workshops & events.",
      link: "https://cur8cafe.com/",
    },
    {
      name: "Splash",
      image: splash,
      description:
        "A Web3 directory showcasing the networks of the most popular communities and tokens.",
      link: "https://www.splash.club/",
    },
    {
      name: "Monet Media",
      image: monetmedia,
      description:
        "A Web3 marketing platform that is revolutionizing marketing campaigns with AI, fostering an inclusive and rewarding creative ecosystem.",
      link: "https://monetmedia.ai/",
    },
  ];

  const [partners, setPartners] = useState(initialPartners);

  const renderPartners = () => {
    return partners.map((partner, index) => (
      <div key={index} className={`${styles["partner-card"]}`}>
        <a href={partner.link} target="_blank" rel="noopener noreferrer">
          <div className={`${styles["image-container"]}`}>
            <img src={partner.image} alt={partner.name} />
          </div>
          <div>
            <h2 className={`${styles["partner-name"]}`}>{partner.name}</h2>
            <p className={`${styles["description-container"]}`}>
              {partner.description}
            </p>
          </div>
        </a>
      </div>
    ));
  };

  return (
    <div className={`${styles["parent-container"]}`}>
      <div className={`${styles["grid-container"]}`}>{renderPartners()}</div>
    </div>
  );
};

export default PartnersComponent;