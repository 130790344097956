import styles from '../../MyVerifiedNFTPolicies.module.scss'
import Panel from 'App/Components/Ui/Panel'

const AddPolicePanel = props => {
    return <Panel className={`${styles['add-policy-panel']} p-2.5`}>
        <div className={`${styles['add-policy']} w-full`}>
            <p className='font-14'>Add new collection</p>
            <p className='font-14 leading-3'>Curate and share your collection with the world.</p>
            <button disabled={props.editPolicies >= 0} onClick={props.onAddButtonClick}>Add</button>
        </div>
    </Panel>

}

export default AddPolicePanel