import styles from '../../MyVerifiedNFTPolicies.module.scss'
import approved from 'Assets/Icons/approved.svg'
import close from 'Assets/Icons/close.svg'
import pending from 'Assets/Icons/pending.svg'
import trash from 'Assets/Icons/Trash.svg'
import Panel from 'App/Components/Ui/Panel'
import ImageButton from 'App/Components/Ui/ImageButton'
import { useState } from 'react'
import DeleteWarning from './Components/DeleteWarning'
import SpanText from 'App/Components/Ui/SpanText'
import useScreenSize from 'App/hooks/use-screen-size'

const ViewPolicyPanel = props => {
    const screenSize = useScreenSize()?.width;
    const [deleteWarning, setdeleteWarning] = useState(false)
    const openDeleteWarnHandler = id => {
        setdeleteWarning(true)
    }
    const closeDeleteWarnHandler = () => {
        setdeleteWarning(false)
    }
    const deleteButtonClickHandler = e => {
        props.onDeleteButtonClick(props.policy.id)
    }
    return <Panel className='p-2.5'>
        <div className='flex flex-col gap-2.5 w-full'>
            <div className={`${styles["view-control"]} flex items-center gap-2.5 ml-2.5`}>
                <img alt='NoImg'
                    src={props.policy.verificationStatusId === 1 ? pending : props.policy.verificationStatusId === 2 ? approved :
                        props.policy.verificationStatusId === 3 ? close : ''} />
                <ImageButton data={props.policy.id} onClick={openDeleteWarnHandler} Src={trash}></ImageButton>
            </div>
            <div className={`${styles['policy-row']} flex gap-4`}>
                <div className={`${styles['form-row']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <span className={`${styles["label-color"]}`}>Policy ID
                        </span>
                    </div>
                    <div className={`${styles["policy-id-input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className={`${styles['input-long']}  ${screenSize > 551 ? 'mt-2' : ''}  ${styles["form-value"]} flex justify-start break-all text-left`}>
                            <SpanText className={styles["span-text"]}>{props.policy.policyId}</SpanText>
                        </div>
                    </div>

                </div>
                <div className={`${styles['form-row']} flex flex-col gap-1 w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>Policy Name
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex  ${screenSize > 551 ? 'mt-2' : ''} flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.name}</SpanText>

                    </div>
                </div></div>
            <div className={`${styles['policy-row']} flex gap-4`}>
                <div className={`${styles['form-row']} flex flex-col gap-1 w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>Description
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.description}</SpanText>

                    </div>
                </div>
                {/* <div className={`${styles['form-row']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>Style
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.styleId}</SpanText>

                    </div>
                </div> 
                        enable it on second phase
                */}
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1 w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>X
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.twitter}</SpanText>

                    </div>
                </div></div>
            <div className={`${styles['policy-row']} flex gap-4`}>
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>Discord
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.discord}</SpanText>

                    </div>
                </div>
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1 w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>Website
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.website}</SpanText>
                    </div>
                </div>
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center text-left`}>
                        <span className={`${styles["label-color"]}`}>Email
                        </span>
                    </div>
                    <div className={`${styles["form-value"]} flex flex-row basis-11/12 justify-start`}>
                        <SpanText className={styles["span-text"]}>{props.policy.email}</SpanText>
                    </div>
                </div></div>
        </div>
        {deleteWarning && <DeleteWarning onCancel={closeDeleteWarnHandler} onDelete={deleteButtonClickHandler} />}

    </Panel >
}

export default ViewPolicyPanel