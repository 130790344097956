import "../../Icons.scss";

const PotraitIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer rect-stroke-color display-lists ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="23"
        viewBox="0 0 16 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="22"
          rx="0.5"
          stroke="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default PotraitIcon;
