import styles from "./Register.module.scss";
import "./Register.scss";
import "../../AuthLayout.scss";
import emailIcon from 'Assets/Icons/email.svg'
import passwordIcon from 'Assets/Icons/password.svg'
import userIcon from 'Assets/Icons/userAvatar_black.svg'
import infoIcon from 'Assets/Icons/info-icon.png'
import approved from 'Assets/Icons/approved.svg'
import close from 'Assets/Icons/close.svg'
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import RoundedInput from "App/Components/Ui/RoundedInput";
import RoundedButton from "App/Components/Ui/RoundedButton"
import Validators from "App/Components/Ui/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useMemo } from "react";
import { capitalizeFirstLetter } from "App/Helper/utilities";
import Spinner from "App/Components/Ui/Spinner";
import useAuth from "App/hooks/use-auth";

const  Register = ({isGuestRegister = false, onClose}) => {
  const emailValidatorsList = useMemo(() => ([{
    type: 'Required',
    message: 'Invalid Email'
  }, {
    type: 'Email',
    message: 'Invalid Email'
  }]), []);
  const userNameValidatorsList = useMemo(() => ([{
    type: 'Required',
    message: 'Invalid Username'
  }, {
    type: 'Username',
    message: 'Invalid Username'
  }, {
    type: 'minLength',
    message: 'Invalid Username'
  }]), []);
  const passwordValidatorsList = useMemo(() => ([{
    type: 'Required',
    message: 'Password is not strong enough'
  }, {
    type: 'Password',
    message: 'Password is not strong enough'
  }]), []);
  const navigate = useNavigate();
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [formVaildState, setFormVaildState] = useState({});
  const [errorMsg, setErrorMsg] = useState([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formGroup = {
    username: {
      value: undefined,
      isRequired: true
    },
    email: {
      value: undefined,
      isRequired: true
    },
    password: {
      value: undefined,
      isRequired: true
    }
  }
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const [isUsserValid, setIsUsserValid] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [usercheckLoad, setUsercheckLoad] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');
  const [termsId, setTermsId] = useState(false);
  const [policyUrl, setPolicyUrl] = useState('');
  const [policyId, setPolicyId] = useState('');
  const timeOut = useRef(null);
  const login = useAuth()[2]
  const guest = useAuth()[3]
  //Registration Api
  const getRegisterResponse = useCallback((res) => {
    dispatch('hideSpinner');
    if(isGuestRegister) {
      login(res?.data?.token)
      guest(false)
      onClose && onClose(false)
      navigate("/home")
    } else {
      navigate("/auth/sign-in")
    }
    dispatch('showToast', { toast: { toastMode: 'success', message: capitalizeFirstLetter(res.message) } })
  }, [dispatch, navigate, guest, login, isGuestRegister, onClose])
  const Register = () => {
    dispatch('showSpinner')
    formValues.terms = termsId;
    formValues.privacy = policyId;
    const registerUrl = isGuestRegister ? 'user/convert-user' : 'user/register';
    triggerAPI({
      url: registerUrl, data: formValues, method: 'post'
    }, getRegisterResponse,()=>{
      dispatch("hideSpinner");
  });
  }

  const getValidateUserResponse = (res) => {
    const {data} = res;
    setIsUsserValid(data?.available);    
    setUsercheckLoad(false);
  }
  const validateUsername = useCallback((username) => {
    if(formVaildState?.username === null && username) {
      setUsercheckLoad(true);
      setIsFirstLoad(false)
      const data = {
        username
      }
      triggerAPI({
        url: `user/username-valid`, data: data, method: 'post'
      }, getValidateUserResponse);  
    } else {
      setIsFirstLoad(true)
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVaildState.username])

  //Form Submit handler
  const submitHandler = e => {
    const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
    e.preventDefault();
    if (valid && isUsserValid) {
      Register()
    }
    else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  }
  
  useEffect(() => {
    const errorMsg = [];
    for (const key in formVaildState) {
      if (Object.hasOwnProperty.call(formVaildState, key)) {
        const element = formVaildState[key];
        if(element) {
          for (const keyIn in element) { 
            if (Object.hasOwnProperty.call(element, keyIn)) { 
              const data = element[keyIn];
              errorMsg.push(data);
            }
          }
        }
    }
    setErrorMsg(errorMsg);
  }
}, [formVaildState])

  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e
      }
    })
  }
  const onUsernameChange = useCallback(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current)
    }
    timeOut.current = setTimeout(() => {
      validateUsername(formValues.username);
    }, 500)
    
  },[formValues.username, validateUsername]);
  useEffect(() => {
    onUsernameChange();
  }, [formValues.username, onUsernameChange])

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error
      }
    })
  }, [])
  const getTermsResponse = useCallback((res) => {
    const { data } = res;
    setTermsUrl(data?.fileUrl ?? data?.link);
    setTermsId(data?.id);
  },[])
  const getTerms = () => {
    triggerAPI({
      url: `terms-conditions/get-active/1`, method: 'get'
    }, getTermsResponse);
  }
  const getPolicyResponse = useCallback((res) => {
    const { data } = res;
    setPolicyUrl(data?.fileUrl ?? data?.link);
    setPolicyId(data?.id);
  },[])
  const getPolicy = () => {
    triggerAPI({
      url: `terms-conditions/get-active/2`, method: 'get'
    }, getPolicyResponse);
  }
  useEffect(() => {
    getTerms();
    getPolicy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleTerms = () => {
    window.open(termsUrl);
  }
  const handlePolicy = () => {
    window.open(policyUrl);
  }
  const handleBack = () => {
    navigate("/auth/sign-in")
  }
  return (
    <div className={`flex flex-col ${styles.register}`}>
      <div className="flex flex-col w-[24rem] gap-[2.5rem] h-full justify-center">
        <form className="rounded" onSubmit={submitHandler} autoComplete={'off'}>
          <div className="mb-2.5 w-[24rem]">
            <div className="flex gap-2 relative">
              <RoundedInput minLen={3} className={'flex-1 auth-round'} label="Username" icon={userIcon} Id="username" validateOnBlur type="text" controlName="username" Placeholder="Username" ChangeFunction={inputOnChange}
              ></RoundedInput>
              <div className={`absolute ${usercheckLoad ? 'right-[-1.45rem]' : 'right-[-1.7rem]'}  ${usercheckLoad ? 'top-[35%]' : 'top-[30%]'}`}>
                <Spinner show={usercheckLoad} size={10} />
                {!usercheckLoad && !isFirstLoad && <img src={isUsserValid ? approved : close} className={isUsserValid ? '' : styles['not-avail-icon']} alt={'icon'} />}
              </div>
              <div className={styles['validator-positon']}>
            <Validators value={formValues.username} controlName="username" onErrors={collectErrors} validators={userNameValidatorsList} minLength={3} />
            </div>
            </div>
          </div>
          <div className="mb-2.5 w-[24rem]">
            <RoundedInput label="Email" className={'auth-round'} icon={emailIcon} Id="email" validateOnBlur type="text" controlName="email" Placeholder="Email" ChangeFunction={inputOnChange}
            ></RoundedInput>
             <div className={`${styles['info-container']} relative`}>
            <div className={styles['validator-positon3']}>
            <Validators value={formValues.email} controlName="email" onErrors={collectErrors} validators={emailValidatorsList} />
            </div>
            </div>
          </div>
          <div className="mb-[1.875rem] w-[24rem]">
            <RoundedInput label="Password" className={'auth-round'} validateOnBlur icon={passwordIcon} type="password" controlName="password" Placeholder="Password" ChangeFunction={inputOnChange}
            ></RoundedInput>
            <div className={`${styles['info-container']} relative`}>
              <img className={`${styles['info-icon']} cursor-pointer`} src={infoIcon} alt='info icon' />
              <div className={`${styles['info-body']}`}>
                <p>Password should contain minimum of 8 characters and number, 1 Capital Alphabet, 1 character and 1 symbol</p>
              </div>
              <div className={styles['validator-positon2']}>
            <Validators value={formValues.password} controlName="password" onErrors={collectErrors} validators={passwordValidatorsList} />
            </div>
            </div>
          
          </div>
          <div className={`mb-[1.875rem] w-[24rem] ${styles['terms-policy']}`}>
            <span>
              By registering, I agree to the <span className="cursor-pointer" onClick={handleTerms}>Terms of Use</span>  and 
              the <span className="cursor-pointer" onClick={handlePolicy}>Privacy Policy</span>
            </span>
          </div>
          {/* <div className="all-errors w-full min-h-[2rem]">
            {errorMsg?.length ? errorMsg[0] : ''}
          </div> */}
          <div className={`flex items-center justify-center w-[24rem] ${styles['register-button']} mb-[1rem]`}>
            <RoundedButton OnClick={submitHandler} className="sign-btn" Name="Register" Type="12"></RoundedButton>
          </div>
          {!isGuestRegister && 
            <div className={`flex items-center justify-center w-[24rem] ${styles['login-link']}`}>
                Already have an account?&nbsp;<span className="cursor-pointer ml-1" onClick={handleBack}> Login</span>
            </div>
          }
        </form> 
        </div>
    </div>
  );
}

export default Register