import "../../Icons.scss";

const TimerIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_2819_6098)">
          <path
            d="M8.0026 14.6666C11.6845 14.6666 14.6693 11.6818 14.6693 7.99992C14.6693 4.31802 11.6845 1.33325 8.0026 1.33325C4.32071 1.33325 1.33594 4.31802 1.33594 7.99992C1.33594 11.6818 4.32071 14.6666 8.0026 14.6666Z"
            stroke="#A0ABBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 4V8L10.6667 9.33333"
            stroke="#A0ABBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2819_6098">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default TimerIcon;
