import API from "App/Helper/api";
import { capitalizeFirstLetter } from "App/Helper/utilities";
import { useStore } from "App/hooks-store/store";
import { useCallback } from "react";
import useAuth from "./use-auth";

const useHttp = () => {
    const dispatch = useStore(false)[1]
    const logout = useAuth()[1]
    const errorHandler = useCallback((error) => {
        console.log(error)
        if(error?.code!=="ERR_CANCELED"){
        const { message: globalMessage, response: { status = 500, data: { error: { message = '' } = {} } = {} } } = error;
        switch (status) {
            case 401:
                logout();
                break;
            case 429:
                logout();
                break;
            case 404:
                dispatch('showToast', { toast: { toastMode: 'error', globalMessage: capitalizeFirstLetter(globalMessage) } })
                break;

            default:
                dispatch('showToast', { toast: { toastMode: 'error', message: capitalizeFirstLetter(message) } })
                break;
        }
    }
        dispatch('hideSpinner')
    }, [dispatch, logout])
    const triggerAPI = useCallback(async (request = { url: 'noUrl', data: {}, method: 'get' || 'post' || 'put' || 'delete',cancelApi:true||false }, applyData, onError) => {
        try {
            const response = await API(request)
            applyData(response.data)
        } catch (error) {
            if (onError) {
                if (error?.response?.status === 401) {
                    errorHandler(error)
                }
                onError(error)
            } else {
                errorHandler(error)
            }
        }
    }, [errorHandler])
    return { triggerAPI }
}
export default useHttp;