import { useStore } from "App/hooks-store/store";
import { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import ImagePopup from "../ImagePopup";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import "./DatePickerFilter.scss"
import RoundedButton from "../RoundedButton";

const DatePickerFilter = ({disableDayPicker=false,setshowDate =()=>{},minDate,otherDate,value,disableMonthPicker=false,disableYearPicker=false,className,show,keyName,onDateSelect,showTime,notChanged,onSelectDate,dateSelected}) => {

    const [{ currentFormValues }] = useStore(false);
    const [change,setChange]=useState({mode:true})
    const [actualFormat,setActualFormat]=useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState(currentFormValues?.nftDropStartedAt ? (currentFormValues?.nftDropStartedAt?.includes('AM') || currentFormValues?.nftDropStartedAt?.includes('PM')) ? new Date(currentFormValues?.nftDropStartedAt) : new Date(currentFormValues?.nftDropStartedAt?.slice(0, -1)) :dateSelected?dateSelected: new Date());
    useEffect(() => {
        dateUpdateHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentFormValues?.id,dateSelected])

    const onCloseHandler=()=>{
        showTime&&selectedDateRange?.year&&onDateSelect({[keyName]:selectedDateRange?.toDate?.().toString()})
    }

    const dateUpdateHandler=()=>{
        if(selectedDateRange){
            let check = new Date(otherDate).getHours()===new Date(dateSelected).getHours()&&new Date(otherDate).getMinutes()===new Date(dateSelected).getMinutes()
            const startDate = otherDate&&!check?new Date(`${new Date(selectedDateRange).getFullYear()}-${new Date(selectedDateRange).getMonth()}-${new Date(selectedDateRange).getDate()} ${new Date(selectedDateRange).getHours()}:${new Date(selectedDateRange).getMinutes()} GMT`).toUTCString():showTime?new Date(`${selectedDateRange?.year}-${selectedDateRange.month?.number}-${selectedDateRange?.day} ${selectedDateRange?.hour}:${selectedDateRange?.minute} GMT`).toUTCString(): new Date(`${selectedDateRange?.year}-${selectedDateRange.month?.number}-${selectedDateRange?.day} GMT`);
        let format= { month: "short", day: '2-digit', year: 'numeric' }
        format=showTime?{...format,hour: "numeric",minute:"numeric",hour12: true}:format
        let selectedDate=showTime?selectedDateRange?.toDate?.().toString():startDate
        const value = selectedDate?.toLocaleString("en-US", format)
        !notChanged && typeof selectedDateRange!=="string" && value && value!="Invalid Date"&& onSelectDate(value)
        if(showTime&&notChanged) {
          setSelectedDateRange((pre)=>dateSelected) 
          onDateSelect({[keyName]:dateSelected})
        }
        else if(showTime&&(startDate !="Invalid Date"||!change?.mode))  {
            onDateSelect({[keyName]:otherDate?new Date(selectedDateRange).toString():selectedDateRange?.toDate?.().toString()})}
        else if (!isNaN(Date.parse(startDate))) {
            onDateSelect(startDate.toISOString().split('T')[0].split('-').join('-'))
        }
        else if (!currentFormValues?.nftDropStartedAt) {
            const dd = new Date().getDate();
            const mm = new Date().getMonth() + 1;
            const yyyy = new Date().getFullYear();
            const d = yyyy + '-' + String(mm).padStart(2, '0') + '-' + String(dd).padStart(2, '0')
            onDateSelect(d)
        }
        else {
            const dd = new Date(currentFormValues?.nftDropStartedAt).getDate();
            const mm = new Date(currentFormValues?.nftDropStartedAt).getMonth() + 1;
            const yyyy = new Date(currentFormValues?.nftDropStartedAt).getFullYear();
            const d = yyyy + '-' + String(mm).padStart(2, '0') + '-' + String(dd).padStart(2, '0')
            onDateSelect(d)
        }
        showTime&& setChange({mode:true})
    }
    }
const setDateToDefault=()=>setSelectedDateRange(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),9,0,0,0))
    useEffect(()=>{
    if(dateSelected) setSelectedDateRange(new Date(dateSelected))
    else  setDateToDefault()
     //setChange({mode:true})
    },[dateSelected])
    const dateChange=(e)=>{
        setActualFormat(e)
        let updatedDate=otherDate&&new Date(new Date(otherDate).getFullYear(),new Date(otherDate).getMonth(),new Date(otherDate).getDate(),e?.hour,e?.minute,0,0)?.toISOString();
        showTime&& setChange(e)
        setSelectedDateRange(otherDate?updatedDate:e)
    }
    const onClose=()=>{
        if(dateSelected) setSelectedDateRange(new Date(dateSelected))
        else setDateToDefault()
        setshowDate({ mode: false })
        setActualFormat(null)
    }
    const onSave=()=>{
        dateUpdateHandler()
        setshowDate({ mode: false })
    }
    return (
        <ImagePopup show={show} cssClass={`date-desk ${className} ${(dateSelected||actualFormat) && "active"}`} onClose={onClose}>
            <div className="date-picker">
                <Calendar className="date"
                format={showTime?"MM/DD/YYYY HH:mm":"MM/DD/YYYY"}
                    value={selectedDateRange}
                    onChange={dateChange}
                    numberOfMonths={1}
                    minDate={new Date(minDate)}
                    disableDayPicker={disableDayPicker}
                    disableMonthPicker={disableMonthPicker}
                    disableYearPicker={disableYearPicker}
                    plugins={[<TimePicker position="bottom" hideSeconds disabled={!showTime} />]}
                />
            </div>
            <div className="w-full flex justify-center gap-2 px-[1.5rem] date-btn-wrapper mt-[0.5rem] mb-2">
                <RoundedButton
                  className={"cancel-btn flex-1 btn-style"}
                  OnClick={onClose}
                  Name={"Cancel"}
                ></RoundedButton>
                <RoundedButton
                  disabled={!actualFormat}
                  className={`save-btn btn-style save flex-1`}
                  OnClick={onSave}
                  Name={ 'Confirm'}
                ></RoundedButton>
              </div>
        </ImagePopup>
    )
}


export default DatePickerFilter