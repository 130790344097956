import styles from './NftZoomed.module.scss'
import React, { useEffect, useState } from "react";
import { geReWriteURL, getURL } from 'App/Helper/utilities';
const NftZoomed = (props) => {
    const [src, setSrc] = useState(null)
    const onClickHandler = (e) => {
        if(!props?.noClick){
        if (!props?.noScrolls) {
            setTimeout(() => {
                e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
            }, 500)
        }

        if (props.onClick)
            props.onClick(e, props.frameDet)
    }
}
    const applyURL = async (imageURL) => {
        const url = getURL(imageURL);
        const formatedURL = url.includes('data:') ? await geReWriteURL(url) : url;
        setSrc(formatedURL);
    }
    useEffect(() => {
        if (props.nft) {
            applyURL(props.nft)
        }
    }, [props?.nft])

    return <div data-index={props["data-index"]} onClick={onClickHandler} className={`${props?.className ?? ''} ${styles["nft-zoom-viewer"]} ${props?.noClick&&"cursor-auto"}`} style={{ 'backgroundImage': "url(" + src + ")" }}>
        {props?.showAddDiv && <div className={`${props?.addDivClass ?? ''}`}></div>}
    </div>

}

export default NftZoomed