import { useEffect, useRef } from "react";
import SearchIcons from "Assets/Images/SearchIcons.svg";
import closeIcon from "Assets/Icons/close.svg";
import CloseIcon from "App/Components/IconsComponents/CloseIcon";

const SearchField = ({ hideLabel, onSearch, value = '', onClick }) => {
  const inputOnchange = (e) => {
    onSearch(e.target.value);
  };
  let searchRef = useRef(null);
  useEffect(() => {
    searchRef?.current?.focus();
  }, []);
  return (
    <>
      {!hideLabel && (
        <div className="sort-txt">
          <p>Search:</p>
        </div>
      )}
      <div className="search search-position nav-search left-[-1rem]">
        {/* <div className="search-icon-search cursor-pointer">
            <img alt="NoImg" className="h-[1rem]" src={SearchIcons} />
        </div> */}
        <input
          type="text"
          ref={searchRef}
          value={value}
          onChange={inputOnchange}
          onKeyUp={inputOnchange}
          placeholder="Search"
        />
        <div className="search-icon">
          <img className="img-icon" src={SearchIcons} alt="NoImg" />
        </div>
        <div
          className="search-icon-close cursor-pointer"
          data-id="close"
          onClick={onClick}
        >
          {/* <img className="img-icon h-[1rem]" alt="NoImg" src={closeIcon} /> */}
          <CloseIcon/>
        </div>
      </div>
    </>
  );
};
export default SearchField;
