import { capitalizeFirstLetter } from "App/Helper/utilities";
import { useStore } from "App/hooks-store/store";
import React, { useCallback, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import closeSvg from 'Assets/Icons/close-toast.svg'

const Toaster = React.memo((props = { mode: '', message: '' }) => {
    const { toastMode, message } = props;
    const dispatch = useStore(false)[1]
    const triggerToast = useCallback(() => {
        if (toastMode) {
            switch (toastMode) {
                case 'success':
                    toast.success(capitalizeFirstLetter(message))
                    break;
                case 'warning':
                    toast.warning(capitalizeFirstLetter(message))
                    break;
                case 'error':
                    toast.error(capitalizeFirstLetter(message))
                    break;
                default:
                    toast.info(capitalizeFirstLetter(message))
                    break;
            }
        }
    }, [toastMode, message])
    useEffect(() => {
        triggerToast();
        return () => {
            dispatch('hideToast')
        }
    }, [triggerToast, dispatch])
    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton = {closeSvg}
        />
    )
})
export default Toaster;