import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setTokenCollection: (current, token) => {
            let currentData = current?.tokens ?? [] ;
            const data = [...currentData, ...token.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                tokenLoaded: 0,
                tokenLoading: token.loading,
                tokens: finalData,
                tokenCount: token.count
            }
        },
        resetTokenCollection: (current, token) => {
            return {
                tokenLoaded: 0,
                tokenLoading: false,
                tokens: [],
                tokenCount: 0
            }
        },
        setTokenAssetsList: (current, assetsList) => {
            return {
                tokenAssetList: assetsList,

            }
        },
        updateTokenCount: (current) => {
            if (current.tokenLoaded < current.tokenCount)
            return {
                tokenLoaded: current.tokenLoaded + 1
            }
        },
        updateTokenLoadingStatus: (current,status) => {
            return {
                tokenLoadingStatus: status
            }
        },         
        updateTokenRawData: (current,data) => {
            return {
                tokenRawData: data
            }
        },        
        updateTokenAssetCount: (current, data) => {
            return {
                tokenAssetCount: data
            }
        },
        reFetchToken: (current) => {
            return {
                reFetchToken:  {mode:true}
            }
        },
        reFetchTokenDone: (current) => {
            return {
                reFetchToken:  {mode:false}
            }
        },
        favTokenToggle: (current, object) => {
            let find = current.tokens.find(collec => collec.policy === object.policy)
            if (find)
                find.isCollectionFavourite = object.value
            return {
                tokens: [...current.tokens]
            }
        },
        favtokenToggle: (current, object) => {
            let find = current.token.find(collec => collec.asset === object.asset)
            if (find)
                find.isFavourite = object.value
            return {
                token: current.token
            }
        },
        setWalletUpdatingStatus: (current, status) => {
            return {
                walletUpdatingstatus: status
            }
        }
    }
    initStore(actions, {
        collectionAssetList: [],
        tokenLoading: false,
        tokenLoaded: 0,
        tokens: [],
        tokenCount: 0,
        tokenAssetCount: 0,
        tokenRawData: [],
        tokenLoadingStatus:'finished',
        reFetchToken: {mode:false},
        walletUpdatingstatus: false
    });
}
export default configureStore;