import { useState,useEffect,useRef,useCallback } from 'react';
import { ListPlayer, ListPlayerContext } from 'react-list-player';
import "./MusicPlayer.scss";
import { useStore } from "App/hooks-store/store";
import Listen from 'Assets/Icons/listen-icon.svg';
import { getDurationOfAudio, getMetadataValue } from 'App/Helper/utilities';
import { useNavigate } from 'react-router-dom';

  const MusicPlayer = ({collection,toplevel,isPreview}) => {
const [trackCount,setTrackCount] = useState(null);
    function useCustomState(defaultvalue) {
        const [value, setValue] = useState(defaultvalue);
        useEffect(() => {
          return () => {
            // do cleanup
          };
        }, [value]);
      
        const customSetValue = useCallback((newValue) => {
          setValue((oldValue) => {            
            if (oldValue > -1)
            {                
                setSwitchingTrack(true);
            }
            
            const derivedValue = newValue;
            return derivedValue;
          });
        }, []);
      
        return [value, customSetValue];
    }

    const [selectedTrack, setSelectedTrack] = useCustomState(-1);   // -1 means no track is selected
    const [isPlaying, setIsPlaying] = useState(false);        // play/pause
    const [isMuted, setIsMuted] = useState(false);            // mute/unmute
    const [switchingTrack, setSwitchingTrack] = useState(false);            
    const [tracks, setTracks] = useState([]);            
    const [playlistInfo, setPlaylistInfo] = useState({});      
    const audioRef = useRef();      
    const store = useStore()[0];
    const dispatch = useStore(false)[1];
    const navigate = useNavigate();
    let { musicPlaylist, musicPlayerVisible, musicPlaying, musicSwitchingTrack } = store;

    useEffect(() => {
        let tracks = [], tempCollection = collection ? [...collection] : [];
        let playlistInfo = {type: 'playlist',
            name: '',
            numTracks: 0,
            duration: '',
            creationDate: '',
        };
        if (toplevel)
        {
            tempCollection = musicPlaylist;
        }
        if (tempCollection?.length > 0)
        {
            for (let i = 0; i < tempCollection.length; i++) {
                const nft = tempCollection[i];
                if (!playlistInfo.imageSrc)
                {
                    playlistInfo.imageSrc = nft.nftcdnimage256;
                }
                let nftartist = nft.onchain_metadata?.artist??
                                nft.onchain_metadata?.artists??
                                nft.onchain_metadata?.release?.artist??
                                nft.onchain_metadata?.release?.artists??
                                '';
                let nftartistlink = nft.onchain_metadata?.website??
                                    nft.onchain_metadata?.links?.website??
                                    nft.onchain_metadata?.youtube??
                                    nft.onchain_metadata?.links?.youtube;
                let nftalbum = nft.onchain_metadata?.release?.release_title??
                                nft.onchain_metadata?.release?.album??
                                nft.onchain_metadata?.release_title??
                                nft.onchain_metadata?.collection??
                                nft.onchain_metadata?.album;                                
                let nfttitle = nft?.onchain_metadata?.song_title ?? nft?.onchain_metadata?.song?.title ?? nft?.onchain_metadata?.song?.song_title ?? nft?.onchain_metadata?.song?.name ?? nft?.onchain_metadata?.title ?? nft?.onchain_metadata?.name ?? 'Unknown';
                if (nft.onchain_metadata)
                {
                    nftartist = getMetadataValue([nft.onchain_metadata?.release, nft.onchain_metadata], ["artist","artists"]);

                    nftartistlink = getMetadataValue([nft.onchain_metadata?.release, nft.onchain_metadata], ["website","youtube"]);
                    
                    nftalbum = getMetadataValue([nft.onchain_metadata?.release, nft.onchain_metadata], ["album","collection","release_title"]);

                    nfttitle = getMetadataValue([nft.onchain_metadata?.release, nft.onchain_metadata], ["song_title","title"])??nft.onchain_metadata?.name;
                }
                for (let j = 0; j < nft.files?.length; j++) {
                    const file = nft.files[j];
                    if (file.mediaType?.toString()?.toLowerCase().indexOf("audio") > -1)
                    {
                        let title = getMetadataValue([file.song, file], ["song_title","title","name"])??nfttitle??'Unknown';

                        let duration = getMetadataValue([file.song, file], ["song_duration","duration"]);

                        if (duration?.startsWith("PT"))
                        {
                            duration = getDurationOfAudio(duration)
                        }
                        
                        let artist = getMetadataValue([file.song, file], ["artist","artists"])??nftartist??'Unknown';
                        
                        let artistlink = getMetadataValue([file.song, file], ["website","youtube"])??nftartistlink;

                        tracks.push({
                            title: [
                                {
                                type: 'text',
                                content: title,
                                className: 'title'
                                }
                            ],
                            artist: [
                                {
                                  type: 'text',
                                  content: artist,
                                  className: 'artist',
                                  link: artistlink
                                }
                            ],
                            album: [                                             
                            ],
                            duration: duration,
                            source: file.nftcdnfile,
                            isPreview: nft.isPreview
                        })
                    }
                }

                if (nftartist && !nftalbum)
                {
                    playlistInfo.name = `${nftartist}`;                
                }
                else if (nftalbum && !nftartist)
                {
                    playlistInfo.name = `${nftalbum}`;                
                }
                else if (nftartist && nftalbum)
                {
                    playlistInfo.name = `${nftartist} - ${nftalbum}`;              
                }
                else if (nft?.onchain_metadata?.name)
                {
                    playlistInfo.name = nft?.onchain_metadata?.name;
                }
            }            
            playlistInfo.tracks = tracks.length;         
            
            let oneArtist = true;
            let artist = tracks[0]?.artist?.content;            
            for (let i = 1; i < tracks.length; i++) {
                const track = tracks[i];
                if (track.artist.content !== artist)
                {
                    oneArtist = false;
                    break;
                }
            }
            if (oneArtist)
            {
                for (let i = 0; i < tracks.length; i++) {
                    const track = tracks[i];
                    track.artist = [];
                }
            }
            setTracks(tracks);
            setTrackCount(tracks?.length)
            setPlaylistInfo(playlistInfo);
        }
        if (toplevel)
        {
            let setPlaying = true;
            if (tracks.length === 0)
            {   
                tracks.push({
                    title: [
                        {
                        type: 'text',
                        content: '',
                        className: 'title'
                        }
                    ],
                    artist: [
                        {
                        type: 'text',
                        content: 'Select tracks',
                        className: 'artist',
                        link: '/listen/collections/my-collections'
                        },                        
                    ],
                    album: [
                        {
                        type: 'text',
                        content: '',
                        className: 'album'
                        },                  
                    ],
                });  
                
                setPlaying = false;
                setTracks(tracks);
                setTrackCount(tracks?.length)
            }

            if (tracks.length > 0)
            {                
                setIsPlaying(false);                
                if (setPlaying)
                {
                    dispatch('setMusicPlayerVisible', true);
                    if (selectedTrack === 0)
                    {
                        //if the selected track was already the first track, then simulate a click to start it
                        setTimeout(function() {                                       
                            let playButton = document.querySelector(".music-player.toplevel .play-btn");                    
                            playButton?.click();
                        })                                                        
                    }
                    else
                    {
                        //switching to track 0 will play it automatically
                        setSelectedTrack(0);
                    }                                        
                }
            }
            
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collection, musicPlaylist])

    const handleOnPlay = (index, resume) => {
        if (toplevel)
        {
            if (!musicPlaying || switchingTrack || musicSwitchingTrack || resume)
            {                
                if (musicPlaying)
                {
                    audioRef.current?.pause();
                }
                dispatch('setMusicPlaying', true);
                if((switchingTrack || musicSwitchingTrack || !resume)) {
                    audioRef.current?.load();
                    audioRef.current?.addEventListener('loadeddata', () => {
                        audioRef.current?.play();
                    });
                } else {
                    audioRef.current?.play();
                }           
                setSwitchingTrack(false);
                dispatch('setMusicSwitchingTrack',false);
            }                     
        }
        else
        {
            //we will construct a new playlist and dispatch it to the top level music player, which will play it
            let newPlaylist = [];
            let indexChecked = 0;            
            for (let i = 0; i < collection.length; i++) {
                const collectionItem = collection[i];
                let musicfiles = collectionItem.files.filter(file=>file.mediaType?.toString()?.toLowerCase().indexOf("audio") > -1);
                if (indexChecked + musicfiles.length >= index)
                {
                    let newFile = musicfiles[indexChecked + index];
                    newPlaylist.push({...collectionItem});
                    newPlaylist[0].files = [newFile];
                }
                else
                {
                    indexChecked += musicfiles.length;
                }
            }
            for (let i = 0; i < newPlaylist.length; i++) {
                const file = newPlaylist[i];
                file.isPreview = isPreview;
            }
            //dispatch('setMusicSelectedTrack',-1);
            dispatch('setMusicPlaying', false);
            dispatch('setMusicPlayerVisible', true);
            setTimeout(() => {
                dispatch('setMusicPlaylist', newPlaylist);   
            }, 500);                     
            setSelectedTrack(-1); //ensure that our playlist doesn't show the track as playing, top level player handles play state
        }        
    }
    
    const handleOnPause = () => {
        if (toplevel)
        {
            audioRef.current?.pause();
        }
    };
    const handleToggle = () => {
        dispatch('setMusicPlayerVisible',!musicPlayerVisible);
    }

    const onTimeUpdate = (e) => {
        if (isPlaying && tracks[selectedTrack]?.isPreview && audioRef.current.currentTime >= 30)
        {
            setIsPlaying(false);            
            let playButton = document.querySelector(".music-player.toplevel .play-btn");                    
            playButton?.click();
            setSelectedTrack(-1);        
            audioRef.current.currentTime = 0;
        }        
    }
    const onMusicEnd = useCallback(() => {
        let nextIndex = selectedTrack + 1;
        if(tracks?.[nextIndex]) {
            setSelectedTrack(nextIndex)
        } else {
            audioRef.current?.pause();
            setIsPlaying(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTrack, tracks])
    useEffect(() => {
        const link = document.querySelector('.lt-info-title-h1 a');
        if (link) {
          const handleClick = (event) => {
            event.preventDefault();
            // Add any additional logic here
            navigate("/listen/collections/my-collections")
          };
    
          link.addEventListener('click', handleClick);
    
          // Clean up the event listener on component unmount
          return () => {
            link.removeEventListener('click', handleClick);
          };
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [toplevel ,musicPlayerVisible, tracks]);
    return (
        <>            
            <ListPlayerContext.Provider value={{selectedTrack, setSelectedTrack, isPlaying, setIsPlaying, isMuted, setIsMuted}}>            
            <div className={`music-player ${toplevel?'toplevel':'collectionlevel'} ${(!toplevel || musicPlayerVisible) && tracks.length > 0 && "w-full"} ${tracks?.[0]?.artist?.[0]?.content==='Select tracks'?'no-tracks':''}`}>                  
                {((!toplevel || musicPlayerVisible) && tracks.length > 0) && <ListPlayer                                     
                tracks={tracks} 
                listInfo={playlistInfo}
                playCallback={handleOnPlay} 
                pauseCallback={handleOnPause}                
                />}
                {toplevel && <div className={`toggle-music-player cursor-move ${musicPlayerVisible && "active"}`} onClick={handleToggle}>
                    <button >
                        <img src={Listen} alt={"Show Music Player"} />
                    </button>
                </div>}
            </div>
            <audio 
                ref={audioRef} 
                src={tracks[selectedTrack]?.source}
                muted={isMuted} 
                onTimeUpdate={onTimeUpdate}
                onEnded={onMusicEnd}
            />
            </ListPlayerContext.Provider>
            {!!(trackCount===0)&&<div className='no-data'>No songs available.</div>}
        </>        
    )
  }

  export default MusicPlayer;