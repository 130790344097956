import { useEffect, useState, useRef } from "react";

const TextArea = props => {
    const classes = props.className + ' form-input w-full';
    const [maxLength, setMaxLength] = useState();
    const [wordCount, setWordCount] = useState(0);
    const [wordValue, setWordValue] = useState('');
    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);
    if (props.ref) ref = props.ref;
    const onBlurHandler = (e) => {
        if (props.validateOnBlur) {
            e.target.value = e.target.value ?? '';
            onChangeHandler(e);
        }
    }
    const onChangeHandler = (e) => {
        setCursor(e.target.selectionStart);
        const onType = () => {
            const label = props.controlName ?? 'value'
            const value = {
                [label]: e.target?.value?.trimStart()
            }
            if (props.onChange) {
                props.onChange(value)
            }
        }
        if (props?.maxLength) {
            if ((e.target?.value?.trimStart()?.length<=props?.maxLength)||!e.target?.value) {
                onType()
            }
        } else {
            onType()
        }
    }
    useEffect(() => {
        if (props.maxLength) {
            setMaxLength(props.maxLength)
        }
    }, [props.maxLength])
    useEffect(() => {
        setWordValue(props.value)
    }, [props.value])
    useEffect(() => {
        if (props.maxWords) {
            const textValue = props.value;
            const wordArry = textValue ? textValue.split(' ') : [];
            const wordCount = wordArry?.length;
            setWordCount(wordCount);
            setMaxLength(null)
            if (wordCount > props.maxWords) {
                const newTextArr = wordArry.slice(0, (props.maxWords));
                setWordCount(newTextArr?.length);
                setWordValue(newTextArr.join(' '))
                setMaxLength(props?.value?.length)
            }
        }
    }, [props.maxWords, props.value])
    useEffect(() => {        
        //not ideal to keep the cursor in the correct place, as it flickers a bit, without setTimeout it doesn't work though
        setTimeout(function() {
            ref?.current && ref?.current.setSelectionRange(cursor, cursor);
        })                        
     }, [ref, cursor, props.value]);    
    return <div className="form w-full">
        <textarea ref={ref}
            className={`${classes} `}
            onBlur={onBlurHandler}
            readOnly={props.readonly}
            type={props.type}
            data-a={props.dataA}
            data-b={props.dataB}
            rows={props?.rows}
            disabled={props?.disabled}
            onChange={onChangeHandler}
            value={wordValue || ""}
            maxLength={maxLength}
            placeholder={props.placeholder}></textarea>
        {props?.showTextCount && props.value && <div className="text-right text-[0.625rem]">{props.value.length}/{props.maxLength}</div>}
        {props?.showWordCount && props.value && <div className="text-right text-[0.625rem]">{wordCount}/{props.maxWords}</div>}
        <div className="error-box">
            {props.children}
        </div>
    </div>

}

export default TextArea