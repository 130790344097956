import "../../Icons.scss";

const FrameIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color rect-stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3 13V3H11V13H3Z" stroke="#A0ABBB" />
        <rect
          x="0.5"
          y="0.5"
          width="13"
          height="15"
          rx="1.5"
          stroke="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default FrameIcon;
