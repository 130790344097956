import { useState } from "react";
import SkeltonCard from "../SkeltonCard";
import "./CustomImage.scss"
const CustomImage = (props) => {
    const [imageLoading, setImageLoading] = useState(true);
    const imageOnlad = () => {
        setImageLoading(false)
    }
    return (
        <div className={`custom-image-container relative ${!imageLoading && props?.className}`}>
            {(imageLoading) && <SkeltonCard show={imageLoading} height={props?.skeltonCardHeight} count={1} noAbsolute={false} inline={false} containerClassName={`h-full`} />}
            <img alt="NoImg" className={`custom-image ${imageLoading && 'opacity-0'}`} onClick={props?.onClick} onLoad={imageOnlad} src={props?.src} />
        </div>

    )
}
export default CustomImage;
