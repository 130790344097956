import styles from '../../MyVerifiedNFTPolicies.module.scss'
import updateProfile from 'Assets/Icons/updateProfile.svg'
import ImageButton from 'App/Components/Ui/ImageButton'
import Input from 'App/Components/Ui/Input'
import Label from 'App/Components/Ui/Label'
import Panel from 'App/Components/Ui/Panel'
import Validators from 'App/Components/Ui/Forms/Validators'
import TextArea from 'App/Components/Ui/TextArea'
import { useMemo } from 'react'

const EditPolicyPanel = props => {
    const validatorsList = useMemo(() => ([{
        type: 'Required',
        message: 'Required field'
    }, {
        type: 'policyID',
        message: 'Invalid policy ID'
    }]), [])
    const validatorsListTwitter = useMemo(() => ([{
        type: 'Required',
        message: 'Required field'
      }, {
        type: 'url',
        message: 'URL not valid'
      }]), [])
    return <Panel className='p-2.5'>
        <div className='flex flex-col gap-2.5 w-full'>
            <div className={`${styles['update-btn']} flex items-center w-4 h-4 mb-[.8rem] ml-2.5 mt-[0.05em]`}>
                <ImageButton onClick={props.onUpdateButtonClick} Src={updateProfile}></ImageButton>
            </div>
            <div className={`${styles['policy-row']} flex gap-4`}>
                <div className={`${styles['form-row']} flex flex-col gap-1 w-full`}>
                    <div className={`${styles["policy-id-row"]} flex flex-col gap-1 flex-1`}>
                        <div className={`${styles['form-title']} flex justify-start items-center `}>
                            <Label className={`${styles["label-color"]}`}>Policy ID*
                            </Label>
                        </div>
                        <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                            <div className={`${styles['input-long']} flex justify-start`}>
                                <Input className='w-full' type="text" label="policyId" validateOnBlur onChange={props.onInputChange} value={props.currentEditPolicy.policyId} controlName="policyId">
                                    <Validators value={props.currentEditPolicy.policyId} controlName="policyId" onErrors={props.collectErrors} validators={validatorsList} />
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['form-row']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <Label className={`${styles["label-color"]}`}>Policy Name*
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className={`${styles['input-long']} flex justify-start`}>
                            <Input className='w-full' label="name" value={props.currentEditPolicy.name} validateOnBlur type="text" onChange={props.onInputChange} controlName="name">
                                <Validators type="Required" value={props.currentEditPolicy.name} message="Required field" controlName="name" onErrors={props.collectErrors} />
                            </Input>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['policy-row']} flex gap-4`}>
                <div className={`${styles['form-row']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <Label className={`${styles["label-color"]}`}>Description
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className={`${styles['input-long']} flex justify-start`}>
                            <TextArea className={`${styles["creation-desc"]}`} label="description" showTextCount={true} validateOnBlur type="text" onChange={props.onInputChange} maxLength={200} controlName="description" value={props.currentEditPolicy.description}></TextArea>
                        </div>
                    </div>
                </div>
                {/* <div className={`${styles['form-row']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <Label className={`${styles["label-color"]}`}>Style
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className={`${styles["styles-select"]} flex justify-start w-full`}>
                            <Select className="p-[.312rem] col-gal-select font-14 text-[#F7F8F9]" type="text" label="styleId" validateOnBlur onChange={props.onInputChange} options={props.stylesList.options} controlName="styleId"></Select>
                        </div>
                    </div>
                </div> */}
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <Label className={`${styles["label-color"]}`}>X*
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className='flex flex-1 justify-start'>
                            <Input placeholder="https://twitter.com/username" label="X" validateOnBlur type="text" value={props.currentEditPolicy.twitter} onChange={props.onInputChange} controlName="twitter">
                                <Validators value={props.currentEditPolicy.twitter} controlName="twitter" onErrors={props.collectErrors} validators={validatorsListTwitter} />
                            </Input>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['policy-row']} flex gap-4`}>

                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <Label className={`${styles["label-color"]}`}>Discord
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className='flex flex-1 justify-start'>
                            <Input label="discord" placeholder='https://discord.gg/code' validateOnBlur type="text" value={props.currentEditPolicy.discord} onChange={props.onInputChange} controlName="discord">
                                <Validators type="url" value={props.currentEditPolicy.discord} message="URL not valid" controlName="discord" onErrors={props.collectErrors} />
                            </Input>
                        </div>
                    </div>
                </div>
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center`}>
                        <Label className={`${styles["label-color"]}`}>Website
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className='flex flex-1 justify-start'>
                            <Input placeholder="https://www.website.com" label="website" value={props.currentEditPolicy.website} validateOnBlur type="text" onChange={props.onInputChange} controlName="website">
                                <Validators type="url" value={props.currentEditPolicy.website} message="URL not valid" controlName="website" onErrors={props.collectErrors} />
                            </Input>
                        </div>
                    </div>
                </div>
                <div className={`${styles['form-row']} ${styles['social']} flex flex-col gap-1  w-full`}>
                    <div className={`${styles['form-title']} flex justify-start items-center `}>
                        <Label className={`${styles["label-color"]}`}>Email
                        </Label>
                    </div>
                    <div className={`${styles["input"]} flex flex-row basis-11/12 justify-start`}>
                        <div className='flex flex-1 justify-start'>
                            <Input type="text" label="email" validateOnBlur onChange={props.onInputChange} value={props.currentEditPolicy.email} controlName="email">
                                <Validators type="Email" value={props.currentEditPolicy.email} message="Invalid Email" controlName="email" onErrors={props.collectErrors} />
                            </Input>
                        </div>
                    </div>
                </div></div>
            <div className={`${styles['message']}`}>
                <p><span>⚠️⚠️ LAST STEP: Please DM @cur8or from your official Twitter account after submitting this form! ⚠️⚠️</span></p>
                <p><span> https://twitter.com/cur8or</span></p>
            </div>
        </div>
    </Panel>
}
export default EditPolicyPanel