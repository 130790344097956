import { deepCopy } from "App/Helper/utilities";
import { useCallback, useState } from "react";

const useCollectionDropDown = () => {
    const [dropDownFilter, setDropDownFilter] = useState(null);
    const updateCollectionFilter = useCallback((dropValue, walletValue) => {
        if(dropValue === 'My Tokens') {
            setDropDownFilter({isToken: true})
        } else if (!!(walletValue)) {
            setDropDownFilter({isWallet: true, value: walletValue})
        } else {
            setDropDownFilter(null)
        }
    }, [])
    const getDropValueByData = useCallback((collection, tokens) => {
        let finalList = collection ? deepCopy(collection) : [];
        if(dropDownFilter?.isWallet) {
            finalList = [...finalList].filter(item => item?.walletId === parseInt(dropDownFilter?.value));
        } else if (dropDownFilter?.isToken) {
            finalList = tokens;
        }
        return finalList
    }, [dropDownFilter])
    return {
        dropDownFilter, updateCollectionFilter, getDropValueByData
    }
}
export default useCollectionDropDown;