import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setSingleCollection: (current, singleCollection) => {
            let currentData = current?.singleCollection ?? [] ;
            const data = [...currentData, ...singleCollection.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {                
                singleCollection: finalData                
            }
        },
        resetSingleCollection: (current, singleCollection) => {
            return {                
                singleCollection: []                
            }
        },
        
        updateSingleCollectionLoadingStatus: (current,status) => {
            return {
                singleCollectionLoadingStatus: status
            }
        }       
    }
    initStore(actions, {        
        singleCollection: [],        
        singleCollectionLoadingStatus:'',        
    });
}
export default configureStore;