import "../Icons.scss";

const SortIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8105_12595)">
          <path
            d="M1 2H23.545"
            stroke="#A0ABBB"
            strokeWidth="0.94"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M1 9H15.805"
            stroke="#A0ABBB"
            strokeWidth="0.94"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M1 16H9.625"
            stroke="#A0ABBB"
            strokeWidth="0.94"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_8105_12595">
            <rect width="24" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SortIcon;
