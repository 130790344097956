import { removeDuplicateByElement } from "App/Helper/utilities"

const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCurrentCommunityCollectionIds: (current, ids) => {
            return {
                currentCommunityCollectionIds: ids
            }
        },
        setCommunityDetails: (current, communityDetails) => {
            return {
                communityDetails
            }
        },
        reFetchCommunityCollection: (current) => {
            return {
                reFetchCommunityCollection: {mode:true}
            }
        },
        updateCommunityCollectionAssetCount: (current, data) => {
            return {
                communityCollectionAssetCount: data
            }
        }, 
        updateCommunityCollectionRawData: (current,data) => {
            return {
                communityCollectionRawData: data
            }
        },
        updateCommunityCollectionLoadingStatus: (current,status) => {
            return {
                communityCollectionLoadingStatus: status
            }
        }, 
        setCurrentCommunityGallery: (current,data) => {
            return {
                currentCommunityGallery: data
            }
        }, 
        setCommunityCollections: (current, collections) => {
            let currentData = current?.communityCollection ?? [] ;
            const data = [...currentData, ...collections.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                ccommunityCollectionLoaded: 0,
                communityCollectionLoading: collections.loading,
                communityCollection: finalData,
                communityCollectionCount: collections.count
            }
        },
        resetCommunityCollections: (current, collections) => {
            return {
                ccommunityCollectionLoaded: 0,
                communityCollectionLoading: false,
                communityCollection: [],
                communityCollectionCount: 0
            }
        },
    }
    initStore(actions, {
        communityDetails: {},
        currentCommunityGallery: {},
        communityCollectionCount: 0,
        communityCollectionLoading: false,
        ccommunityCollectionLoaded: 0,
        communityCollection: [],
        communityCollectionRawData: [],
        currentCommunityCollectionIds: [],
        communityCollectionLoadingStatus: '',
        communityCollectionAssetCount: 0,
        reFetchCommunityCollection: {mode:false}
    });
}
export default configureStore;