import styles from './Account.module.scss'
import Panel from 'App/Components/Ui/Panel'
import Table from 'App/Components/Ui/Table'
import { useState, useEffect, useCallback } from 'react';
import { useStore } from 'App/hooks-store/store';
import useHttp from 'App/hooks/use-http';
import Select from 'App/Components/Ui/Select';
const Account = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    // const [filterStartDate, setFilterStartDate] = useState(new Date())
    // const [filterTotDate, setFilterToDate] = useState(new Date())
    const [transactions, setTransactions] = useState([])
    const tableRows = [
        { id: 0, value: "Date" },
        { id: 1, value: "Change in balance(₳)" },
        { id: 2, value: "EndingBalance(₳)" },
        { id: 3, value: "Change in balance(M)" },
        { id: 4, value: "EndingBalance(M)" },
    ]
    const [selectedRow, setSelectedRow] = useState(tableRows[0].value)


    // const filterStartDateHandler = e => {
    // }
    // const filterToDateHandler = e => {
    // }
    useEffect(() => {
        getMonetTokken()
        dispatch("setPageTitle", "Account")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getMonetTokenResult = useCallback((res) => {
        dispatch('hideSpinner');
        const data = res.data

        setTransactions([
            {
                type: "Deposit",
                date: new Date(),
                changeInBal: "+" + Number(data.total).toFixed(2),
                endingBal: Number(data.total).toFixed(2)
            }
        ])
    }, [dispatch])
    const getMonetTokken = useCallback(() => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/user-monet-token/get`, method: 'get'
        }, getMonetTokenResult);
    }, [dispatch, getMonetTokenResult, triggerAPI])
    const selectRowHandler = (e) => {
        setSelectedRow(e.row)
    }

    return (
        <div className={`${styles['account']} w-full flex flex-col gap-2`}>
            <Panel className='p-5 gap-5'>
                <div className='flex flex-row pb-5 font-14'>
                    <div>
                        <span>Balance: </span>
                    </div>
                    <div className='ml-4 flex gap-2.5 flex-col text-left'>
                        <p>00</p>
                    </div>
                </div>
                {/* <div className={`${styles['wallet-enter']} flex flex-col items-start gap-11 font-12`}>
                    <div className='flex flex-col items-start gap-2'>
                        <p>
                            Enter your wallet address to withdraw your entire balance.
                        </p>
                        <p>
                            Wallet Address
                        </p>
                        <input type="text">
                        </input>
                    </div>
                    <p className='flex flex-col items-start font-10'>
                        <button>Withdraw All</button>
                    </p>
                </div> */}
            </Panel>
            <Panel className='p-5 gap-5'>
                {/* <div className={`${styles['date-select']} flex flex-row gap-12`}>
                    <div className={`${styles['instruction-box']}`}>
                        <p>Choose Date</p>
                    </div>
                    <div className='flex flex-1 items-start flex-col gap-2 font-10'>
                        <div className='flex flex-row gap-4'>
                            <DatePickerUi placeholder="from date" endDate={new Date} onChange={filterStartDateHandler} />
                            <DatePickerUi placeholder="to date" endDate={new Date} onChange={filterToDateHandler} />
                        </div>
                    </div>
                </div> */}
                <div className={`${styles["table-container"]}`}>
                    <Table>
                        <thead className='font-12'>
                            <tr>
                                <th className="text-center">Transaction</th>
                                <th className="text-left">Date</th>
                                <th className="text-right">Change in Balance (₳)</th>
                                <th className="text-right">Ending Balance (₳)</th>
                                <th className="text-right">Change in Balance (M)</th>
                                <th className="text-right">Ending Balance (M)</th>
                            </tr>
                        </thead>
                        {/* <tbody className='font-10'>
                            {transactions.map((trans, index) => (<tr key={index}>
                                <td>{trans.type}</td>
                                <td>date</td>
                                <td>00</td>
                                <td>00</td>
                                <td>{trans.changeInBal}</td>
                                <td>{trans.endingBal}</td>
                            </tr>
                            ))}
                        </tbody> */}
                    </Table>
                    <div className='flex justify-center items-center w-full py-6'>No data</div>

                </div>
                <div className={styles["responsive-table"]}>
                    <Table>
                        <thead className='font-12'>
                            <tr>
                                <th className="text-left">Transaction</th>
                                <th className='account-drop ml-auto'>
                                    <Select className="p-[.3125rem]" onChange={selectRowHandler} controlName="row" options={tableRows} />
                                </th>
                            </tr>
                        </thead>
                        <tbody className='font-10'>
                            {transactions.map((trans, index) => (<tr key={index}>
                                <td className='text-left'>{trans.type}</td>
                                {selectedRow === tableRows[0].value && <td className='text-right'>date</td>}
                                {selectedRow === tableRows[1].value && <td className='text-right'>00</td>}
                                {selectedRow === tableRows[2].value && <td className='text-right'>00</td>}
                                {selectedRow === tableRows[3].value && <td className='text-right'>{trans.changeInBal}</td>}
                                {selectedRow === tableRows[4].value && <td className='text-right'>{trans.endingBal}</td>}
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Panel>
        </div>
    )
}

export default Account 