import Panel from "App/Components/Ui/Panel"
const GalariesRent = () => {
return <div  className="w-full">
    <Panel>
        <div className="p-5">
            <p>
                Galaries I Rent
            </p>
        </div>
    </Panel>
</div>
}

export default GalariesRent