const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCurrentGalleryUpdateId: (current, currentGalleryUpdateId) => {
            return {
                currentGalleryUpdateId
            }
        },
        setCurrentGallery: (current, currentGallery) => {
            return {
                currentGallery
            }
        },
        setCurrentSelectedNfts: (current, currentSelectedNfts) => {
            return {
                currentSelectedNfts
            }
        },
        setCurrentFrameDetails: (current, currentFrameDetails) => {
            return {
                currentFrameDetails
            }
        },
        setCurrentList: (current, currentGalleryList) => {
            return {
                currentGalleryList
            }
        },
        setCurrentFrame: (current, currentFrame) => {
            return {
                currentFrame
            }
        },
        setCurrentFormValues: (current, currentFormValues) => {
            return {
                currentFormValues
            }
        },
        setCurrentTabSelected: (current, currentTabSelected) => {
            return {
                currentTabSelected
            }
        },
        setCurrentFrameSelected: (current, currentFrameSelected) => {
            return {
                currentFrameSelected
            }
        },
        setFreeFrames: (current, currentFreeFrames) => {
            return {
                currentFreeFrames
            }
        },
        setNftFrames: (current, currentNftFrames) => {
            return {
                currentNftFrames
            }
        },
        setDropType: (current, dropType) => {
            return {
                dropType
            }
        },
        setDropStyle: (current, dropStyle) => {
            return {
                dropStyle
            }
        },
        setCurrentSelectedTab: (current, currentSelectedTab) => {
            return {
                currentSelectedTab
            }
        },
        setCurrentWallList: (current, currentWallList) => {
            return {
                currentWallList
            }
        }
    }
    initStore(actions, {
        currentGallery: {},
        currentGalleryUpdateId: {},
        currentSelectedNfts: [],
        currentFrameDetails: [],
        currentGalleryList: [],
        currentFrame: {},
        currentFormValues: {},
        currentTabSelected: '',
        currentFrameSelected: [],
        currentFreeFrames: [],
        currentNftFrames: [],
        dropType: [],
        dropStyle: [],
        currentSelectedTab: 'My Collections',
        currentWallList: []
    });
}
export default configureStore;