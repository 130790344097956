import "../../Icons.scss";

const ListIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 4.5C1.75 4.08579 2.08579 3.75 2.5 3.75C2.91421 3.75 3.25 4.08579 3.25 4.5C3.25 4.91421 2.91421 5.25 2.5 5.25C2.08579 5.25 1.75 4.91421 1.75 4.5ZM5 4.5C5 4.22386 5.22386 4 5.5 4H13.5C13.7761 4 14 4.22386 14 4.5C14 4.77614 13.7761 5 13.5 5H5.5C5.22386 5 5 4.77614 5 4.5ZM1.75 8C1.75 7.58579 2.08579 7.25 2.5 7.25C2.91421 7.25 3.25 7.58579 3.25 8C3.25 8.41421 2.91421 8.75 2.5 8.75C2.08579 8.75 1.75 8.41421 1.75 8ZM5 8C5 7.72386 5.22386 7.5 5.5 7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H5.5C5.22386 8.5 5 8.27614 5 8ZM1.75 11.5C1.75 11.0858 2.08579 10.75 2.5 10.75C2.91421 10.75 3.25 11.0858 3.25 11.5C3.25 11.9142 2.91421 12.25 2.5 12.25C2.08579 12.25 1.75 11.9142 1.75 11.5ZM5 11.5C5 11.2239 5.22386 11 5.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H5.5C5.22386 12 5 11.7761 5 11.5Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default ListIcon;
