import "../Icons.scss";

const ReloadIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="ac-rotate 1">
          <path
            id="Vector"
            d="M8 1C10.5 1 12.75 2.3 14 4.4V4C14 3.7 14.2 3.5 14.5 3.5C14.8 3.5 15 3.7 15 4V6C15 6.3 14.8 6.5 14.5 6.5H12.5C12.2 6.5 12 6.3 12 6C12 5.7 12.2 5.5 12.5 5.5H13.45C12.45 3.35 10.35 2 8 2C4.7 2 2 4.7 2 8C2 11.3 4.7 14 8 14C10.4 14 12.6 12.55 13.5 10.35C13.6 10.1 13.9 10 14.15 10.1C14.4 10.2 14.5 10.5 14.4 10.75C13.35 13.35 10.8 15 8 15C4.15 15 1 11.85 1 8C1 4.15 4.15 1 8 1Z"
            fill="#A0ABBB"
          />
        </g>
      </svg>
    </div>
  );
};

export default ReloadIcon;
