import validationCheck from "App/Helper/validationCheck";
import React, { Fragment, useCallback, useEffect, useState } from "react";

const Validators = React.memo(({ value, type, message, controlName, onErrors, matchValue = '', validators = [], multiple, minLength = 0, minVal = 0, maxVal = 0 }) => {
    const [error, setError] = useState(null);
    const [multiError, setMultiError] = useState(validators)
    const validate = useCallback((type, message) => {
        let errors = null;
        if (value !== undefined) {
            switch (type) {
                case 'Required':
                    if(typeof value === 'string' && !value.toString().replace(/\s/g, '').length ) {
                        errors = {
                            [type]: message
                        }
                    } else if (!value) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Email':
                    if (!!value && validationCheck({ type: 'email', value })) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Password':
                    if (!!value && validationCheck({ type: 'password', value })) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Match':
                    if (value !== matchValue) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'NotMatch':
                    if (!!value && value === matchValue) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'policyID':
                    if (!!value && (value.length < 10 || value.length > 100)) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'url':
                    if (!!value && validationCheck({ type: 'url', value })) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Username':
                if (!!value && validationCheck({ type: 'username', value })) {
                    errors = {
                        [type]: message
                    }
                }
                break;
                case 'minLength':
                    if (!!value && (value.length < minLength)) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Number':
                    if (!!value && validationCheck({ type: 'number', value })) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                    case 'String':
                        if (!!value && ((typeof value!=="string")||Number(value))) {
                            errors = {
                                [type]: message
                            }
                        }
                        break;
                case 'Required Array':
                    if (!value || value.length === 0) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'minVal':
                    if (!!value && !(minVal <= value)) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                    case 'maxVal':
                        if (!!value && !(maxVal >= value)) {
                            errors = {
                                [type]: message
                            }
                        }
                        break;
                        case 'NumberWithZero':
                        if (!value&&value !==0) {
                            errors = {
                                [type]: message
                            }
                        }
                        break;
                default:
                    break;
            }
        }
        return errors
    }, [value, matchValue, minLength, minVal,maxVal])
    useEffect(() => {
        if (validators.length) {
            let result = null
            const mErrors = [];
            validators.map((v) => {
                const exec = validate(v.type, v.message);
                if (exec) {
                    result = { ...result, ...exec }
                    mErrors.push({ type: v.type, message: v.message })
                }
                return exec
            })
            setMultiError(mErrors)
            onErrors({ [controlName]: result })
            setError(result)
        } else {
            const exec = validate(type, message);
            onErrors({ [controlName]: exec })
            setError(exec)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlName, onErrors, validate, type, message])
    return (
        <Fragment>
            <div className="error-validation">
                <Fragment>
                    {error && !validators.length && (
                        <p>
                            {error[type]}
                        </p>
                    )}
                </Fragment>

                <Fragment>
                    {
                        multiError.map((x, i) =>
                        (multiple ? <p key={i}>
                            {x.message}
                        </p> :
                            (i === 0 && <p key={i}>{x.message}</p>))

                        )
                    }
                </Fragment>
            </div>
        </Fragment>
    )
})
export default Validators;