import "../../Icons.scss";

const RightArrow = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12.6641 3.33398V12.6673"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33594 2.66602L10.0026 7.99935L3.33594 13.3327V2.66602Z"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default RightArrow;
