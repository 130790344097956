import './DetailRightPanel.scss';

const DetailRightPanel = (props) => {

    return (
        <div className={`${props?.className} list-right-panel`}>
            <div className='right-panel-container relative'>
                {props.children}
            </div>
        </div>
    )

}

export default DetailRightPanel;