import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from 'App/hooks/use-auth';
const PrivateRoute = ({ children }) => {
  const isLoggedIn = useAuth()[0]
  if (isLoggedIn) {
    return children;
    // user is not authenticated
  }
  return <Navigate to="/home" />;
}

export default PrivateRoute;