import { useGLTF, useTexture } from '@react-three/drei';
import { useSelector,useDispatch } from 'react-redux'


import * as THREE from 'three';
import MetaGalleryFrame from '../Component/MetaGalleryFrame';
import { setframepositions,settotalnfts } from '../Component/MetaGalleryState';

const MetaGallery1 = ({ galleryList, captionControl }) => {  
  const dispatch = useDispatch(); 

  const colorMap = useTexture('/metagalleryassets/textures/wall3.jpg')
  colorMap.repeat.set(2,2);
  colorMap.wrapS=THREE.RepeatWrapping;
  colorMap.wrapT=THREE.RepeatWrapping;

  const { nodes, materials } = useGLTF('/metagalleryassets/models/gallery2-transformed.glb')    
  let framepositions = useSelector((state) => state.appState.framepositions.payload)??[];  
  
  if (framepositions.length === 0)
  {       
    framepositions=[...framepositions];  
    framepositions.push(      
      [[-4.38, 2, 7.65],[-3, 2, 7.65]],
      [[-4.38, 2, 4.35],[-3, 2, 4.35]],
      [[-4.38, 2, 1.05],[-3, 2, 1.05]],
      [[-4.38, 2, -2.25],[-3, 2, -2.25]],
      [[-1.7, 2, -5.08],[-1.7, 2, -3.7]],
      [[1.55, 2, -5.08],[1.55, 2, -3.7]],
      [[4.38, 2, -2.25],[3, 2, -2.25]],
      [[4.38, 2, 1.05],[3, 2, 1.05]],
      [[4.38, 2, 4.35],[3, 2, 4.35]],
      [[4.38, 2, 7.65],[3, 2, 7.65]]      
    );

    dispatch(setframepositions(framepositions));    
  }

  // useEffect(() => {
  //   console.log("effected")
  //   setTimeout(function() {
  //     dispatch(setispaused(false));
  //   },1000)
    
  // })

//   if (loading)
//   {
//   try {
//     loading = false;
//     setloading(loading);
//     console.log("loading");
//     const queryParameters = new URLSearchParams(window.location.search)
//     const id = queryParameters.get("id")
//     let type = queryParameters.get("type")??"0";

//     if (type==="0")
//     {
//       type = "gallery";
//     }
//     if (type==="1")
//     {
//       type = "nft-drop";
//     }

//     fetch(`https://api-cur8-nft-dev.devtomaster.com/${type}/stand-alone/get/${id}`)
//     .then(response => response.json())
//     .then(result => {
//       setloading(false);
//       if (result.data?.assets)
//       {
//         for (let i = 0; i < result.data.assets.length; i++) {
//           const asset = result.data.assets[i];

          
//           if (asset.frame?.s3ImageUrl?.endsWith(".svg"))
//           {
//             //asset.frame.s3ImageUrl += "?func=proxy";
//             asset.frame.s3ImageUrl = null;
//           }
//           if (asset.details?.image?.startsWith("Qm"))
//           {
//             asset.details.image = "https://cyeepyhsfa.cloudimg.io/https://ipfs.io/ipfs/" + asset.details.image;
//           }
//         }
//         setnfts(result.data?.assets??[]);      
//         dispatch(settotalnfts((result.data?.assets??[]).length));        
//       }      
//     });
//   } catch(error){
//       console.log('ERROR ->', error);
//       throw error;
//   }
// }

if (galleryList.length < 10)
{
  let len = galleryList.length;
  let j = -1;
  for (let i = len; i < 11; i++) {
    if (j === galleryList.length)
    {
      j = 0;
    }
    else 
    {
      j++;
    }
    galleryList.push(galleryList[j]);    
  }
}
//setnfts(galleryList);      
dispatch(settotalnfts(galleryList.length));      


  let index = useSelector((state) => state.appState.index.payload)??0;
  let isviewingframe = useSelector((state) => state.appState.isviewingframe.payload)??0;     

  return (    
    <group dispose={null}>

{galleryList.length>0 && <>   


<ambientLight intensity={2.4} />
      <spotLight penumbra={0.1} position={[0, 25, 0]} intensity={1000} />            

      <group dispose={null} rotation={[0, 0, 0]} position={[0,-1,1.5]} scale={[2.2,2.2,2.2]}>
      <mesh geometry={nodes.floor_2_Baked.geometry} material={materials['floor 2_Baked']} />
      <mesh geometry={nodes.skylight_Baked.geometry} material={materials['skylight_Baked.002']} position={[-0.887, 2.95, 2.913]} />
      <mesh geometry={nodes.baseboard_Baked.geometry} material={materials.baseboard_Baked} />
      <mesh geometry={nodes.Walls001_Baked.geometry} material={materials['Walls.001_Baked.003']} />
      <mesh geometry={nodes.Walls002_Baked.geometry} material={materials['Walls.002_Baked.003']} />
      <mesh geometry={nodes.Walls003_Baked.geometry} material={materials['Walls.003_Baked.003']} />
      <mesh geometry={nodes.Walls004_Baked.geometry} material={materials['Walls.004_Baked.003']} />
      <mesh geometry={nodes.bench_1_Baked.geometry} material={materials['bench 1_Baked']} position={[0.001, 0, -0.705]} />
      <mesh geometry={nodes.bench_2_Baked.geometry} material={materials['bench 2_Baked']} position={[0.001, 0, 1.7]} />
      <mesh geometry={nodes.hallway_wall_Baked.geometry} material={materials['hallway wall_Baked']} position={[0.011, 0, 6.328]} />
      <mesh geometry={nodes.floor_1_Baked.geometry} material={materials['floor 1_Baked']} />
      <mesh geometry={nodes.rail_light_right_Baked.geometry} material={materials['rail light right_Baked']} position={[1.253, 2.691, 0.514]} />
      <mesh geometry={nodes.rail_light_left_Baked.geometry} material={materials['rail light left_Baked']} position={[-1.25, 2.691, 0.514]} />
      <mesh geometry={nodes.rail_light_back_Baked.geometry} material={materials['rail light back_Baked']} position={[0, 2.691, -2.258]} />
      <mesh geometry={nodes.ceiling_Baked.geometry} material={materials.ceiling_Baked} position={[0, 2.7, 0]} />
    </group>
    

         <MetaGalleryFrame position={framepositions[0][0]} nft={galleryList[0]} rotation={[0,Math.PI,0]} renderonchain={index===0&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

  <MetaGalleryFrame position={framepositions[1][0]} nft={galleryList[1]} rotation={[0,Math.PI,0]} renderonchain={index===1&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>
         
       <MetaGalleryFrame position={framepositions[2][0]} nft={galleryList[2]} rotation={[0,Math.PI,0]} renderonchain={index===2&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>


          <MetaGalleryFrame position={framepositions[3][0]} nft={galleryList[3]} rotation={[0,Math.PI,0]} renderonchain={index===3&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

         <MetaGalleryFrame position={framepositions[4][0]} nft={galleryList[4]} rotation={[0,-Math.PI/2,0]} renderonchain={index===4&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

        <MetaGalleryFrame position={framepositions[5][0]} rotation={[0,-Math.PI/2,0]} nft={galleryList[5]} renderonchain={index===5&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

      
        <MetaGalleryFrame position={framepositions[6][0]} nft={galleryList[6]} renderonchain={index===6&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>           

         <MetaGalleryFrame position={framepositions[7][0]} nft={galleryList[7]} renderonchain={index===7&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>                  

         <MetaGalleryFrame position={framepositions[8][0]} nft={galleryList[8]} renderonchain={index===8&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>           

         <MetaGalleryFrame position={framepositions[9][0]} nft={galleryList[9]} renderonchain={index===9&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>                  

</>}
        
  </group>           
  );
};

export default MetaGallery1;
