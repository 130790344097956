import axios from "axios";
import storage from "./storage";
const baseURL = process.env.REACT_APP_BASE_URL
let cancelTokenSource = axios.CancelToken.source();
const API = ({ url = 'noUrl', data = {}, method = 'get' || 'post' || 'put' || 'delete',cancelApi}) => {
    const token = storage().get('token')
    let headers = {}
    cancelTokenSource.cancel("Previous request cancelled");
    // Create a new cancel token for the current request
    cancelTokenSource = axios.CancelToken.source();
    if(token)
        headers = {
            Authorization: `Bearer ${token}`,
            isb2c: true
        }
        let axiosData={
            method,
            url: `${baseURL}/${url}`,
            data,
            headers,
        }
        if(cancelApi){
            axiosData={...axiosData, cancelToken: cancelTokenSource.token }
        }
    return axios(axiosData)
    
}
export default API;