import { useSelector } from 'react-redux';
import { useFrame } from '@react-three/fiber';
import { useState, useRef } from 'react';
import { Mask, useMask } from '@react-three/drei'
import * as THREE from 'three';


const MetaGalleryFrameImage = ({ nft, renderonchain, imagexpos, imagetexture, videotexture }) => {      
  imagetexture.anisotropy = 4096

  let [opacity, setopacity] = useState(1);  
 
  let waittotalsecs = useSelector((state) => state.appState.waittotalsecs.payload)??0;
  let turntotalsecs = useSelector((state) => state.appState.turntotalsecs.payload)??0;
  let starttime = useSelector((state) => state.appState.starttime.payload)??new Date().getTime();

  // let zoom = 1;
  // if (nft?.frame?.metaInfo?.image && nft?.frame?.metaInfo?.frame)
  // {
  //   zoom = (nft.frame.metaInfo.image.height/nft.frame.metaInfo.image.width)/
  //           (nft.frame.metaInfo.frame.height/nft.frame.metaInfo.frame.width);
  // }  
  //console.log("imageheight",imagetexture.image.height)
  //console.log("imagewidth",imagetexture.image.width)
  let imagear = imagetexture.image.width/imagetexture.image.height;
  //console.log("imagear",imagear)
  
  let framear = (nft?.frame?.metaInfo?.frame?.width/nft?.frame?.metaInfo?.frame?.height)??1;
  //console.log("framear",framear)
  
  
  let width = (nft?.frame?.metaInfo?.image?.widthPercent??100)/100;
  let height= (nft?.frame?.metaInfo?.image?.heightPercent??100)/100;
  let left= (nft?.frame?.metaInfo?.image?.xPercent??0)/100;
  let top= (nft?.frame?.metaInfo?.image?.yPercent??0)/100;
  //console.log("left",left)  
  let stencilwidth = width;
  let stencilheight = height;
  let iswide = imagear>framear;
  if (nft?.frame)
  {
    if (iswide)
    {
  
      width = width * imagear/framear;
      stencilwidth = (imagear/framear-1)/2;
    }
    else
    {
      height = height * framear/imagear;
      stencilheight = (framear/imagear-1)/2;
    }
  }
  
  //console.log("width",width)

  
  let imageposition = [];
  imageposition[0]=imagexpos;
  //imageposition[2]=-left*width;
  //imageposition[1]=-top*height;  
  imageposition[2]=0;
  imageposition[1]=0;  

  function getonchainhtml(nft) {     
    if (!renderonchain) return null;
    let files = nft?.details?.onchain_metadata?.files;
    if (files?.length>0)
    {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.mediaType === "text/html")
        {
          if (file.src.join)
          {
            return file.src.join('');
          }
          if (file.src.startsWith("ipfs://"))
          {
            return file.src.replace("ipfs://"," https://ipfs.io/ipfs/") + "?func=proxy";
          }
          return file.src;
        }
      }
    }
       
    return null;
  }

  function getgif(nft) {
    if (!renderonchain) return null;
    let files = nft?.details?.onchain_metadata?.files;
    if (files?.length>0)
    {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.mediaType === "image/gif")
        {          
          return file.src;
        }
      }      
    }
    
    if (nft?.details?.onchain_metadata?.mediaType == "image/gif")
    {
      return nft?.details?.onchain_metadata?.image;
    }
    if (nft?.details.onchain_metadata?.GIF)
    {
      return nft?.details.onchain_metadata?.GIF;
    }
    if (nft?.details.onchain_metadata?.gif)
    {
      return nft?.details.onchain_metadata?.gif;
    }
       
    return null;
  }

  function getvideo(nft) {
    if (!renderonchain) return null;
    if (videotexture) return true;    
  }

  useFrame(() => {    
    let framesecs = (new Date().getTime() - starttime)/1000;
   
    if (renderonchain && (getonchainhtml(nft) && framesecs<turntotalsecs+2.5))
    {    
      let opacity = 1-((framesecs-turntotalsecs)/2.5);
      setopacity(opacity);              
    }
    else if (renderonchain && ((getgif(nft) || getvideo(nft)) && framesecs<turntotalsecs+0.5))
    {
      let opacity = 1-((framesecs-turntotalsecs)/0.5);
      setopacity(opacity);              
    }
    else if (renderonchain && (getonchainhtml(nft) || getgif(nft) || getvideo(nft)) && framesecs<waittotalsecs-2.5)
    {
      let opacity = 0;
      setopacity(opacity);              
    }
    else if (renderonchain && (getonchainhtml(nft) || getgif(nft) || getvideo(nft)) && framesecs>=waittotalsecs-2.5)
    {
      let opacity = (framesecs-(waittotalsecs-2.5))/2.5;
      setopacity(opacity);                      
    }  
  });

    


  const stencil = useMask(1, true);

  const objref = useRef()  
  let bbox;
  let bboxSize;
  let bb;
  if (objref.current?.parent)
  {
    bbox = objref.current?.parent.geometry.boundingBox;
    let tempbbox = new THREE.Box3().setFromObject(objref.current?.parent)
    if (bbox)
    {      
      // Init your size variable
      bboxSize = new THREE.Vector3(bbox)
      // Get the size    
      bb = bbox.getSize(bboxSize)     
    }
  }      
  
  return (
    <mesh ref={objref}>      
      <Mask visible={nft?.frame!=null} id={1} position={[0,!iswide?(-bb?.y??0)*0.5-stencilheight*0.45:0,iswide?(-bb?.z??0)*0.5-stencilwidth*0.45:0]} scale={[1,1*stencilheight,1*stencilwidth]}>
        <boxGeometry args={[0.0001,1,1]}/>
      </Mask>      
      <Mask visible={nft?.frame!=null} id={1} position={[0,!iswide?(bb?.y??0)*0.5+stencilheight*0.45:0,iswide?(bb?.z??0)*0.5+stencilwidth*0.45:0]} scale={[1,1*stencilheight,1*stencilwidth]}>
        <boxGeometry args={[0.01,1,1]}/>
      </Mask>
      <mesh position={imageposition} scale={[1,1*height,1*width]} renderOrder={-1}>
        <boxGeometry args={[0.0001,1,1]}/>
        <meshBasicMaterial map={imagetexture} transparent={true} opacity={opacity} {...stencil}></meshBasicMaterial>  
      </mesh>    
    </mesh>
  )
};

export default MetaGalleryFrameImage;