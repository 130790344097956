import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {          
        updatePreviewGalleryList: (current, data) => {
            return {
                previewGalleryList: data
            }
        },
        updateSettingControls: (current, data) => {
            return {
                settingControls: data
            }
        },
    }
    initStore(actions, {
        previewGalleryList: [],
        settingControls: { }
    });
}
export default configureStore;