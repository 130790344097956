import SkeletonLoader from "../SkeletonLoader";
import "./DetailsSkeleton.scss";

const DetailsSkeltonCard = ({
  show = false,
  height,
  count = 1,
  inline,
  containerClassName = "",
  details,
  parentDiv=""
}) => {
  return (
    show && (
      <div className={`details-skeleton ${parentDiv}`}>
        {[...Array(count)]?.map((el, index) => (
          <div className={"h-full w-full skeleton-wrapper"} key={index + 1}>
            <SkeletonLoader
              height={height}
              inline={inline}
              details={details}
              containerClassName={containerClassName}
            />
          </div>
        ))}
      </div>
    )
  );
};

export default DetailsSkeltonCard;
