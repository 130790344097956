import Input from 'App/Components/Ui/Input'
import Label from 'App/Components/Ui/Label'
import styles from "./DisplayUpdate.scss";
import anywallSvg from 'Assets/Images/Anywall_brand_icon.svg'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import {useState } from 'react';

const DisplayUpdate = (props) => {    
    const { triggerAPI } = useHttp();
    const [{currentUserId}, dispatch] = useStore();

    const [displayState, setDisplayState] = useState({
        name:'',
        pairCode:'',
        userId:currentUserId        
    });

    const pairClick = () => {
        dispatch('showSpinner')
        triggerAPI({
            url: `display/pair`, data: displayState
            , method: 'post'
        }, (res) => {
            dispatch('hideSpinner');            
            dispatch('showToast', { toast: { toastMode: 'success', message: "Display paired Successfully" } })
            props?.onClose();
        }, err => {
            dispatch('hideSpinner');
            dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message||"Display pairing failed" } })
        });
    }

    const onChange=(e)=> {
        setDisplayState({...displayState, ...e});     
    }

    return  (
        <div className="display-update-overlay">
            <div className="display-update-content">
                <img className="anywall-logo" src={anywallSvg} alt=""/>
                <div className="text-[0.875rem]">
                    Pair Display
                </div>
                <div className='flex gap-[1rem] sm:gap-[1.25rem] flex-col sm:flex-row'>
                    <div className='flex  w-full'>
                    
                        <div className={`${styles["profile-row"]} w-full flex flex-col edit-mob`}>
                            <div >
                                <Label className={`label-color text-left mb-[0.375rem]`}>ID (6-Digit Code)
                                </Label>
                            </div>
                            <div className="light-txt">
                                <Input label="pairCode" validateOnBlur type="text" onChange={onChange} controlName="pairCode" value={displayState.pairCode}> 
                                </Input>

                                {/* <Input label="twitter" validateOnBlur placeholder="username" type="text" onChange={props.onInputChange} controlName="twitter" value={props.user.twitter}>                                     */}
                            </div>
                            <div>
                                <Label className={`label-color text-left mb-[0.375rem]`}>Screen Name
                                </Label>
                            </div>
                            <div className="light-txt">
                                <Input label="name" validateOnBlur type="text" onChange={onChange} controlName="name" value={displayState.name}> 
                                </Input>

                                {/* <Input label="twitter" validateOnBlur placeholder="username" type="text" onChange={props.onInputChange} controlName="twitter" value={props.user.twitter}>                                     */}
                            </div>
                           {/* <div>
                                <Label className={`label-color text-left mb-[0.375rem]`}>Screen Location
                                </Label>
                            </div>
                            <div className="light-txt"> */}
                                {/* This needs logic to handle the screen location dropdown. This is currently a copy of the "name" input. */}
                                {/* <Input label="name" validateOnBlur type="text" onChange={onChange} controlName="name" value={displayState.name}> 
                                </Input>
                            </div> 
                             */}
                        </div>
                    </div>
                </div>      

                <div className="display-actions update-actions">
                    <button onClick={props?.onClose} className='cancel-button'>Cancel</button>
                    <button onClick={pairClick} className='pair-button'>Pair</button>
                </div>  
            </div>      
        </div>
    )
}

export default DisplayUpdate