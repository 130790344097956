import { filterCollections } from "App/Helper/utilities";
import { useStore } from "App/hooks-store/store";
import { useMemo } from "react";

const useCollectionCount = () => {
    const store = useStore()[0];
    const { collections, activeMenuIcons, collectionWalletData } = store
    const collectionCount = useMemo(() => {
        let myCollection = collections ? [...collections] : [];
        let tempcollectionWalletData = collectionWalletData ? [...collectionWalletData] : [];
        let tempCollection = filterCollections(myCollection, activeMenuIcons?.filter);
        if(!!activeMenuIcons?.favourite)
          tempCollection = tempCollection?.filter(item => !!item?.isCollectionFavourite)
        const grouped = tempCollection.reduce((grouped, obj) => {
            const key = obj['walletId'];
            if (!grouped[key]) {
              grouped[key] = 0;
            }
            grouped[key]++;
            return grouped;
          }, {});
        let tempWalletsCount =  Object.entries(grouped).map(([key, length]) => ({ key, length }));
        let walletData = [];
        tempWalletsCount?.forEach(element => {
            let temp = tempcollectionWalletData?.find(item => item?.walletId?.toString() === element?.key?.toString())
            if(temp)
            walletData = [...walletData, {...temp, assetCount: element?.length}]
        });
        let count = { collection: tempCollection?.length, walletData }
        return count;
    }, [collections, activeMenuIcons?.filter, collectionWalletData, activeMenuIcons?.favourite])

    return  {collectionCount}
}
export default useCollectionCount;