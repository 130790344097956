import styles from './AuthLayout.module.scss'
import { Outlet } from 'react-router-dom'
import Banner from 'App/Components/Banner'
import logo from 'Assets/Icons/cur8-logo-new.svg';

const AuthLayout = () => {
    return (
        <div className={styles.backdrop}>
            <div className={styles.backTrans}>
                <div className={styles["ipad-baner"]}>
                    <Banner></Banner>
                </div>
                <div className='w-[24rem] '>
                <img src={logo} alt='icon' className={styles.pageLogo} />
                </div>
                <Outlet/>
            </div>
        </div>

    )
}

export default AuthLayout