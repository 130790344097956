/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export const useDebounce = (value, delay) => {
  const [data, setData] = useState("");

  useEffect(() => {
    let timer = setTimeout(() => {
      setData(value);
    }, delay || 500);
    return () => clearTimeout(timer);
  }, [value, delay]);
  return data;
};
