import "../Icons.scss";

const TileMediumIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons">
          <path
            id="Vector"
            d="M21 14H14V21H21V14Z"
            stroke="#A0ABBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M10 14H3V21H10V14Z"
            stroke="#A0ABBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M21 3H14V10H21V3Z"
            stroke="#A0ABBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M10 3H3V10H10V3Z"
            stroke="#A0ABBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default TileMediumIcon;
