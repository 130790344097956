import regex  from "App/Configs/regex";

const validationCheck = ({type,value}) => {
    let isValid = false;
    switch (type) {
        case 'email':
            isValid = !regex.email.test(value);
            break;
        case 'password':
            isValid = !regex.password.test(value);
            break;
        case 'url':
            isValid = !regex.url.test(value);
            break;
        case 'username':
            isValid = !regex.username.test(value);
            break;
            case 'number':
                isValid = !regex.number.test(value);
                break;
        default:
            break;
    }
    return isValid;
}
export default validationCheck;