const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setLayoutData: (current, data) => {
            return {
                layoutData: data
            }
        },
        setScreenShotTake: (current, data) => {
            let latestData = {...current?.screenShotTake, ...data}
            return {
                screenShotTake: latestData
            }
        },
        setLoadingLayout: (current, data) => {
            let currentData = current?.loadingLayout ?? []
            let indexToChange = currentData?.findIndex(item => item?.loadingIndex === data?.loadingIndex);
            if(indexToChange >= 0)
                currentData[indexToChange] = data;
            else 
                currentData = [...currentData, data];
            return {
                loadingLayout: currentData
            }
        },
        resetLoadingLayout: () => {
            return {
                loadingLayout: []
            }
        }
    }
    initStore(actions, {
        layoutData: [],
        screenShotTake: { frame: false, nft: false},
        loadingLayout: []
    });
}
export default configureStore;