import "../Icons.scss";

const GalleryIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7829_11750)">
          <path
            d="M19.5396 23.0464C19.3261 23.0463 19.1214 22.9614 18.9704 22.8105C18.8194 22.6596 18.7344 22.4549 18.7342 22.2414V19.3903H15.8834C15.6733 19.3851 15.4735 19.298 15.3267 19.1476C15.1798 18.9971 15.0977 18.7953 15.0977 18.5851C15.0977 18.3748 15.1798 18.173 15.3267 18.0225C15.4735 17.8721 15.6733 17.785 15.8834 17.7799H18.7342V14.9306C18.7315 14.8232 18.7504 14.7163 18.7897 14.6164C18.829 14.5164 18.8879 14.4253 18.9629 14.3484C19.0379 14.2715 19.1276 14.2104 19.2266 14.1687C19.3256 14.127 19.4319 14.1055 19.5394 14.1055C19.6468 14.1055 19.7531 14.127 19.8521 14.1687C19.9511 14.2104 20.0408 14.2715 20.1158 14.3484C20.1908 14.4253 20.2497 14.5164 20.289 14.6164C20.3283 14.7163 20.3472 14.8232 20.3446 14.9306V17.7813H23.1948C23.4049 17.7864 23.6048 17.8735 23.7516 18.024C23.8984 18.1744 23.9806 18.3763 23.9806 18.5865C23.9806 18.7967 23.8984 18.9986 23.7516 19.149C23.6048 19.2995 23.4049 19.3866 23.1948 19.3917H20.3446V22.2414C20.3443 22.4549 20.2594 22.6595 20.1085 22.8104C19.9576 22.9613 19.753 23.0462 19.5396 23.0464Z"
            fill="#F7F8F9"
          />
          <path
            d="M11.7034 19.1053C11.6674 19.1053 11.6315 19.1029 11.5959 19.0981L1.17218 17.709C0.847858 17.6651 0.550413 17.5052 0.334908 17.2589C0.119402 17.0126 0.000431545 16.6966 1.93841e-05 16.3693V6.26529C-0.00163234 5.95763 0.102319 5.65872 0.294512 5.41848C0.486706 5.17824 0.755506 5.01121 1.05602 4.94528L18.768 0.986245C18.8657 0.964256 18.9655 0.953149 19.0656 0.953125C19.4233 0.954396 19.7659 1.09745 20.0183 1.35092C20.2707 1.60438 20.4123 1.94759 20.412 2.30528V10.2526C20.4069 10.4628 20.3198 10.6626 20.1694 10.8094C20.0189 10.9562 19.817 11.0384 19.6068 11.0384C19.3966 11.0384 19.1947 10.9562 19.0443 10.8094C18.8938 10.6626 18.8068 10.4628 18.8016 10.2526V2.62784L1.60994 6.47265V16.1432L11.808 17.5026C12.0101 17.5298 12.1944 17.6327 12.3236 17.7905C12.4527 17.9483 12.5172 18.1493 12.5039 18.3528C12.4906 18.5563 12.4006 18.7472 12.252 18.8868C12.1034 19.0265 11.9073 19.1046 11.7034 19.1053Z"
            fill="#F7F8F9"
          />
        </g>
        <defs>
          <clipPath id="clip0_7829_11750">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default GalleryIcon;
