import "./AddPlusIconButton.scss";

const AddPlusIconButton = ({ onClick, disabled, className }) => {
  const onClickHandler = () => {
    if (disabled) return;
    onClick();
  };
  return (
    <div
      className={`plus-button-icon ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${className}`}
      onClick={onClickHandler}
    ></div>
  );
};

export default AddPlusIconButton;
