import "../Icons.scss";

const ArrowWithBgIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer toggle fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="#3A3C43" />
        <g clipPath="url(#clip0_2135_5382)">
          <path
            d="M13.2198 16.6662C13.1202 16.6665 13.0218 16.6446 12.9318 16.6019C12.8418 16.5592 12.7625 16.4969 12.6998 16.4195L9.47978 12.4195C9.38173 12.3002 9.32812 12.1506 9.32812 11.9962C9.32812 11.8418 9.38173 11.6922 9.47978 11.5729L12.8131 7.57287C12.9263 7.43672 13.0889 7.35111 13.2652 7.33485C13.4415 7.3186 13.617 7.37304 13.7531 7.4862C13.8893 7.59936 13.9749 7.76197 13.9911 7.93825C14.0074 8.11453 13.9529 8.29005 13.8398 8.4262L10.8598 11.9995L13.7398 15.5729C13.8213 15.6707 13.8731 15.7899 13.889 15.9162C13.9049 16.0426 13.8843 16.1709 13.8296 16.2859C13.7749 16.4009 13.6884 16.4979 13.5803 16.5653C13.4723 16.6327 13.3471 16.6677 13.2198 16.6662Z"
            fill="#B8C0CC"
          />
        </g>
        <defs>
          <clipPath id="clip0_2135_5382">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowWithBgIcon;
