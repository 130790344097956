

const PublicRoute = ({children}) => {
  return children;
  // if (!isLoggedIn) {
  //   return children;
  //   // user is not authenticated
  // }
  // return <Navigate to="/home" />;
}

export default PublicRoute;