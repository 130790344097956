const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCategories: (current, newCategories) => {
            return {
                categories: newCategories
            }
        }
    }
    initStore(actions, {
        categories: null
    });
}
export default configureStore;