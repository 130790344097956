import "../Icons.scss";

const TileListIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color mb-1 ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M8 18H21"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 18H3.01"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12H21"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12H3.01"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 6H21"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 6H3.01"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default TileListIcon;
