import { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = (props) => {
    return (
        <Fragment>
            {!!props?.show && (<div className={props.className + " grid place-items-center"}>
                <p className="absolute">{props.children}</p>
                <ClipLoader color='#fff' loading={props.show} size={props.size} />
            </div>)}
        </Fragment>
    )
}
export default Spinner;