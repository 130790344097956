import "../Icons.scss";

const TileLargeIcon = ({className,dataIndex,onClick=()=>{}}) => {
  return (
    <div className={`icon-wrapper cursor-pointer fill-color ${className}`} data-index={dataIndex} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.55088 2.65918H3.10254C2.85606 2.65918 2.65625 2.85899 2.65625 3.10546V6.55381C2.65625 6.80028 2.85606 7.00009 3.10254 7.00009H6.55088C6.79735 7.00009 6.99717 6.80028 6.99717 6.55381V3.10546C6.99717 2.85899 6.79735 2.65918 6.55088 2.65918Z"
          fill="#A0ABBB"
        />
        <path
          d="M12.8946 2.65918H9.44629C9.19981 2.65918 9 2.85899 9 3.10546V6.55381C9 6.80028 9.19981 7.00009 9.44629 7.00009H12.8946C13.1411 7.00009 13.3409 6.80028 13.3409 6.55381V3.10546C13.3409 2.85899 13.1411 2.65918 12.8946 2.65918Z"
          fill="#A0ABBB"
        />
        <path
          d="M6.55088 9H3.10254C2.85606 9 2.65625 9.19981 2.65625 9.44628V12.8946C2.65625 13.1411 2.85606 13.3409 3.10254 13.3409H6.55088C6.79735 13.3409 6.99717 13.1411 6.99717 12.8946V9.44628C6.99717 9.19981 6.79735 9 6.55088 9Z"
          fill="#A0ABBB"
        />
        <path
          d="M12.8946 9H9.44629C9.19981 9 9 9.19981 9 9.44628V12.8946C9 13.1411 9.19981 13.3409 9.44629 13.3409H12.8946C13.1411 13.3409 13.3409 13.1411 13.3409 12.8946V9.44628C13.3409 9.19981 13.1411 9 12.8946 9Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default TileLargeIcon;
