import "./Card.scss";

const Card = ({ children, className, height }) => {
   return (
      <div className={`${className} card p-3`} style={{height:height||"auto"}}>
         {children}
      </div>
   )
}

export default Card