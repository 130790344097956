
import Logo from "App/Components/Logo";

const ComingSoon = () => {
    return (
        <div className="flex column items-center justify-center h-full flex-1 overflow-auto">
            <div className='flex items-center flex-col-reverse gap-2'>
                <h2 className="text-white text-2xl">Coming Soon...</h2>
                <img src={Logo()} alt="timer" className='max-w-[5rem] w-100'/>
            </div>
        </div>
    )
}
export default ComingSoon;