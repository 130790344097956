import "./metagallerybasescene.css";
import { Canvas } from '@react-three/fiber';
import { Physics } from '@react-three/cannon';
import Camera from '../MetaGalleryCamera';
import { useEffect,useState } from 'react'
import { useProgress } from "@react-three/drei";
import { setframe,setloop,setispaused,setindex } from '../MetaGalleryState';
import { useSelector, useDispatch } from 'react-redux';
import PageLoader from "App/Components/PageLoader";
// import Lights from '../components/Lights.js';
// import Floor from '../components/Floor.js';
// import GUI from './GUI';

const BasicScene = ({ children, oldGalleryId }) => {
  const dispatch = useDispatch();  
  const { progress } = useProgress()
  let debugtext = useSelector((state) => state.appState.debugtext.payload)??"";

  let [isloaded, setisloaded] = useState(false);  

  const queryParameters = new URLSearchParams(window.location.search)
  const debug = queryParameters.get("debug");
  
  useEffect(() => {
    if (progress < 100) {
      dispatch(setispaused(true));  
      setisloaded(false);       
    } else if (progress === 100) {
      setTimeout(function() {
        setisloaded(true);          
        dispatch(setloop(0));
        dispatch(setframe(0));
        dispatch(setindex(0)); 
        
          setTimeout(function() {            
            dispatch(setispaused(false));   
          }, 2000);
                
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])   
  
  return (
    <>
      <div className='rootdiv'>      
          <Canvas shadows camera={{ fov: 50 }}
            onCreated={({ gl }) => {
              gl.setPixelRatio(window.devicePixelRatio);
              //gl.outputColorSpace = SRGBColorSpace;
              gl.physicallyCorrectLights = true;
              gl.shadowMap.enabled = true;          
              //gl.shadowMap.type = PCFSoftShadowMap;
              //gl.toneMapping = ACESFilmicToneMapping;
            }}
          >       
            {isloaded && <color attach="background" args={["lightcyan"]} />}

            <Physics gravity={[0, -9.8, 0]}>
            <Camera/>
              {children}

            </Physics>              
          </Canvas>       
      </div>   
      <div className={`${!isloaded && 'visible'} loadinglogo`}>
              {/* <span className="z-9 absolute bg-transparent bottom-0 left-0">{tempProg}% Loading</span> */}
              <PageLoader />
      </div>
      { debug && <div className="debugoverlay">{debugtext}</div> }
    </>
  );
};

export default BasicScene;