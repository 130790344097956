import GalleryIcon from "App/Components/IconsComponents/GalleryIcon";
import "./FloatingCreateGallery.scss";

const FloatingCreateGallery = ({disabled,onClickHandler}) => {
  return (
    <div className={`floating-create-gallery cursor-pointer ${disabled&&"disabled"}`} onClick={onClickHandler}>
      <GalleryIcon className={` ${disabled&&"disabled"}`}  />
    </div>
  );
};

export default FloatingCreateGallery;
