import { useState } from "react";
import logoAnywall from 'Assets/Images/Anywall_brand_icon.svg'
import "./BannerImage.scss";
import RoundedButton from "App/Components/Ui/RoundedButton";
import SkeltonCard from "App/Components/Ui/SkeltonCard";

const BannerImage = ({ data, className ,skeltonCardHeight="100%"}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div className={`${className} relative banner-image-wrapper w-full`}>
      <img
        src={data?.image}
        className={`w-[100%] object-cover object-center banner-img ${!imageLoaded && 'opacity-0'}`}
        onLoad={() => setImageLoaded(true)}
        alt=""
      />
       {(!imageLoaded) && <SkeltonCard show={!imageLoaded} height={skeltonCardHeight} count={1} noAbsolute={false} inline={false} containerClassName={`h-full`} />}
      {imageLoaded && (data?.description || data?.siteLink || data?.logo) && ( 
        <div className="content">
          {!!data?.logo && !!data?.siteLink && <img className="logo-img" src={logoAnywall} alt=""/>} 
          {!!data?.description && <h1>{data?.description}</h1>}
          {!!data?.siteLink && (
             <a href="https://www.anywall.io" target="_blank" rel="noreferrer">
              <RoundedButton className="curate-gradient-button" Name="Learn More" Type="6" OnClick={()=>{}}></RoundedButton>
             </a>
          )}
           {!!data?.logo && !data?.siteLink && <img className="logo-img" src={logoAnywall} alt=""/>} 
        </div>
      )}
    </div>
  );
};

export default BannerImage;
