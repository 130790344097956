import { useFrame,useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useSelector, useDispatch } from 'react-redux';
import { setframe, setindex, setloop, setisviewingframe, setwaittotalsecs, setturntotalsecs, setdebugtext, setstarttime, setnextimage } from '../MetaGalleryState';
import { useState } from 'react';
// const THREE = require('three')
const MetaGalleryCamera = ({ ...props }) => {  
  const dispatch = useDispatch();  

  const { camera } = useThree();

  const queryParameters = new URLSearchParams(window.location.search)
  const indexdefault = queryParameters.get("index")??0

  let waittime = useSelector((state) => state.appState.waittime.payload)??120000;

  let frame = useSelector((state) => state.appState.frame.payload)??0;

  let loop = useSelector((state) => state.appState.loop.payload)??0;

  let index = useSelector((state) => state.appState.index.payload)??indexdefault;

  let isviewingframe = useSelector((state) => state.appState.isviewingframe.payload)??false;

  let waittotalsecs = useSelector((state) => state.appState.waittotalsecs.payload)??0;

  let turntotalsecs = useSelector((state) => state.appState.turntotalsecs.payload)??0;

  let ispaused = useSelector((state) => state.appState.ispaused.payload)??false;
 
  let framepositions = useSelector((state) => state.appState.framepositions)??[];

  let debugtext = useSelector((state) => state.appState.debugtext.payload)??"";

  let starttime = useSelector((state) => state.appState.starttime.payload)??0;

  let isnextimage = useSelector((state) => state.appState.isnextimage.payload)??false;

  let [startq, setstartq] = useState(null);
  let [endq, setendq] = useState(null);
  let [startm, setstartm] = useState(null);
  let [endm, setendm] = useState(null);  
  let [isstartwalk, setisstartwalk] = useState(false);  
  let [isendwalk, setisendwalk] = useState(false);    
  let [waitingforpause, setwaitingforpause] = useState(false);    
  let [pausetime, setpausetime] = useState(null);  
      
  let turnsecs = 10;    
  let waitsecs = waittime/1000;    
  let startturnsecs = turnsecs;    
  
  if (waittotalsecs!==startturnsecs+waitsecs)
  {    
    waittotalsecs = startturnsecs+waitsecs
    dispatch(setwaittotalsecs(waittotalsecs));
  }

  if (turntotalsecs!==startturnsecs)
  {    
    turntotalsecs = startturnsecs
    dispatch(setturntotalsecs(turntotalsecs));
  }

  if (camera.position.x===0)
  {
    camera.position.set(0, 2, 11)  
    camera.rotation.x = 0;
    camera.rotation.y = 0;
    camera.rotation.z = 0;
  }

  //camera.removeEventListener()
  function easeInOutQuad(x) {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
  }

  function easeInQuad(x) {
    return x * x;
  }

  function easeOutQuad(x) {
    return 1 - (1 - x) * (1 - x);
  }


  useFrame(() => {

    
    if (!ispaused)
    {
      waitingforpause = true;
      setwaitingforpause(waitingforpause);

      if(frame===0)
      {
        starttime = new Date().getTime();
        dispatch(setstarttime(starttime));        
      }

      if (pausetime)
      {
        let pauselength = (new Date().getTime() - pausetime);

        starttime += pauselength;
        dispatch(setstarttime(starttime));        

        pausetime = null;
        setpausetime(pausetime);        
      }

      if (isnextimage)
      {
        if (isviewingframe)
        {
          debugtext += `\r\nold starttime ${starttime}`;
          dispatch(setdebugtext(debugtext));
          starttime=new Date().getTime() - (waittotalsecs-2.5)*1000;
          debugtext += `\r\nnew starttime ${starttime}`;
          dispatch(setdebugtext(debugtext));
          
          dispatch(setstarttime(starttime));       
        }        
        dispatch(setnextimage(false));
      }

      let framesecs = (new Date().getTime() - starttime)/1000;
      if (framesecs>19.8)
      {
        debugtext += `\r\n${framesecs}`;
        dispatch(setdebugtext(debugtext));
      }            
      
      //start walk
      if (framesecs<=startturnsecs/3)
      {
        if (!isstartwalk)
        {            
            if (endm) 
            {              
              camera.position.set(endm.x,endm.y,endm.z);
            }
            if (endq)
            {
              camera.rotation.setFromQuaternion(endq, endq.order)
            }
            startm = camera.position.clone();
            debugtext += `\r\ncamerarot ${camera.rotation.x},${camera.rotation.y},${camera.rotation.z}`;
            dispatch(setdebugtext(debugtext));
            endm = new THREE.Vector3(framepositions?.[index][1][0], framepositions?.[index][1][1], framepositions?.[index][1][2]);
            endm.z = (startm.z*2/3 + endm.z/3);
            endm.x = (startm.x*2/3 + endm.x/3);
            setstartm(startm);
            setendm(endm);

            startq = camera.quaternion.clone();
            let origrotation = {...camera.rotation};
            let origpos = {...camera.position};
            camera.position.set(endm.x, endm.y, endm.z);

            camera.lookAt(new THREE.Vector3(framepositions[index][0][0]*0.8,framepositions[index][0][1],framepositions[index][0][2]));
            endq = camera.quaternion.clone();            
            camera.rotation.setFromVector3(origrotation, origrotation.order);
            camera.position.set(origpos.x, origpos.y, origpos.z);
            setstartq(startq);            
            setendq(endq);            
            isstartwalk = true;
            setisstartwalk(isstartwalk);
        }
        

        let progressrot = easeInOutQuad(framesecs/(startturnsecs/3));        
        camera.quaternion.slerpQuaternions(startq, endq,Math.max(Math.min(progressrot,1),0));
        let progresspos = easeInQuad(framesecs/(startturnsecs/3));        
        camera.position.lerpVectors(startm,endm,Math.max(Math.min(progresspos,1),0));
      }
      //end walk
      else if (framesecs<=startturnsecs) {
        if (!isendwalk)
        {
            startm = camera.position.clone();
            endm = new THREE.Vector3(framepositions?.[index][1][0], framepositions?.[index][1][1], framepositions?.[index][1][2]);            
            setstartm(startm);
            setendm(endm);

            startq = camera.quaternion.clone();
            let origrotation = {...camera.rotation};
            let origpos = {...camera.position};
            camera.position.set(endm.x, endm.y, endm.z);
            camera.lookAt(new THREE.Vector3(framepositions[index][0][0],framepositions[index][0][1],framepositions[index][0][2]));
            endq = camera.quaternion.clone();
            camera.rotation.setFromVector3(origrotation, origrotation.order);
            camera.position.set(origpos.x, origpos.y, origpos.z);
            setstartq(startq);            
            setendq(endq);      
            isendwalk = true;
            setisendwalk(isendwalk);
        }
        

        let progressrot = easeInOutQuad((framesecs-(startturnsecs/3))/(startturnsecs/3*2));        
        camera.quaternion.slerpQuaternions(startq, endq,Math.max(Math.min(progressrot,1),0));
        let progresspos = easeOutQuad((framesecs-(startturnsecs/3))/(startturnsecs/3*2));
        camera.position.lerpVectors(startm,endm,Math.max(Math.min(progresspos,1),0));
      }
      else if (framesecs<=startturnsecs+waitsecs)
      {      
        if (!isviewingframe)
        {
          isviewingframe = true;
          dispatch(setisviewingframe(isviewingframe));
        }        
        //console.log("rot " + camera.rotation.y); 
      }   
      else 
      {
        if (endm) 
        {          
          camera.position.set(endm.x,endm.y,endm.z);
        }
        if (endq)
        {
          camera.rotation.setFromQuaternion(endq, endq.order)
        }
        frame=-1;        
        index++;
        if (index===framepositions.length)
        {
          loop++;
          index=0;
          dispatch(setloop(loop));
        } 
        dispatch(setindex(index));
        isstartwalk = false;
        isendwalk = false;
        setisstartwalk(isstartwalk);
        setisendwalk(isendwalk);
        isviewingframe = false;
        dispatch(setisviewingframe(isviewingframe));
        debugtext += `\r\nisstartwalk ${isstartwalk}`;
        dispatch(setdebugtext(debugtext));
      }        
      frame++;    
      dispatch(setframe(frame));      
    }
    else if (waitingforpause)
    {
      waitingforpause=false;
      setwaitingforpause(waitingforpause);

      pausetime = new Date().getTime();
      setpausetime(pausetime);
    }
  });

  return (
    <group {...props} dispose={null}>
       
    </group>
  );
};

export default MetaGalleryCamera;