import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import downArrow from "Assets/Icons/arrow_down.svg"

const TableHeader = React.memo(({ label, sortKey, sort, onSort }) => {
    const [direction, setDirection] = useState('');
    const sortHandler = () => {
        onSort(sortKey)
    }
    useEffect(() => {
        if (sort?.sortKey === sortKey) {
            setDirection(sort?.direction)
        } else {
            setDirection('')
        }
    }, [sort, sortKey])
    return (
        <div className="flex items-center gap-1">
            <span className="mb-[3px]">
                {label}
            </span>

            {!!sortKey && (<span onClick={sortHandler}>
                <img src={downArrow} alt="sort up" className={`${direction === 'ASC' || !direction ? 'sort-down' : 'sort-up'} ml-1 cursor-pointer`} width="12px" height="4px"></img>
            </span>)}
        </div>
    )
})
export default TableHeader;
