import styles from './RoundedInput.module.scss'
import { useRef, useState } from "react";

const RoundedInput = props => {
    const inputRef = useRef()   
    const [eyeStatus, setEyeStatus] = useState('on')
    let addOne = null;
    const onBlurHandler = (e) => {
        if (props.validateOnBlur) {
            e.target.value = e.target.value ?? '';
            onChangeHandler(e);
        }
    }
    const onChangeHandler = (e) => {
        const label = props.controlName ?? 'value'
        const value = {
            [label]: e.target.value
        }
        if (props.ChangeFunction) {
            props.ChangeFunction(value)
        }
    }
    const onEycClickHandler = () => {
        const isPassword = inputRef.current.type === 'password'
        inputRef.current.type = isPassword ? 'text' : 'password';
        const statusClass = isPassword ? 'off' : 'on'
        setEyeStatus(statusClass);
    }
    if (props?.config?.hasEyeIcon) {
        addOne = <button type="button" className={`${styles['btnIcon']} ${styles['btnIcon-eye']} ${styles[`btnIcon-eye-${eyeStatus}`]}`} onClick={onEycClickHandler}></button>
    }
    if (props?.config?.hasUserIcon) {
        addOne = <span className="icon-person"></span>
    }
    return (<div className={`${styles['rounded-input']} ${props.className}`}>
            {props.icon && <img src={props.icon} alt='icon' />}
            <input
                className={`${styles.input} flex-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${props.Error && styles['error']} ${!!addOne && styles['passwordfield']}`}
                id={props.Id}
                type={props?.type}
                ref={inputRef} 
                placeholder={props.Placeholder}
                onChange={onChangeHandler}
                value={props.value}
                onBlur={onBlurHandler}
            ></input>
            {addOne}
        </div>
    )
}

export default RoundedInput