import { useStore } from "App/hooks-store/store"
import useHttp from "./use-http";

const useLayoutData = () => {
    const [{ layoutData = [] }, dispatch] = useStore();
    const { triggerAPI } = useHttp();

    const getLayoutAPI = (callback) => {
        triggerAPI({
            url: `layout`, method: 'get'
        }, callback);
    }
    const getLayoutFromStore = (callback) => {
        try {
            if (layoutData?.length) {
                callback(layoutData)
            } else {
                getLayoutAPI(({ data }) => {
                    dispatch('setLayoutData', data)
                    callback(data)
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        getLayoutFromStore,
        getLayoutAPI
    }
}
export default useLayoutData;