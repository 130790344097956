import { extractTextFromHtmlContent } from "App/Helper/formValidation";

const FormatField = ({ type, letter, value, maxWidth = '', maxLength = '', startLength = '', endLength = ''
}) => {
    switch (type) {
        case 'substring':
            return value?.length > maxLength ? value?.toString()?.substring(0, startLength) + '...' + value?.toString()?.substring(value?.length - endLength)
                : value?.toString()
        case 'sub':
            return value?.substring(0, maxWidth) + (value?.length > maxWidth ? "..." : "");
        case 'htmlContent':
            const { formattedText } = extractTextFromHtmlContent(value);
            return formattedText?.substring(0, maxWidth) + (formattedText?.length > maxWidth ? "..." : "");
        default:
            break;
    }

}

export default FormatField