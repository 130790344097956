const ControlCard = ({children, className = '', onClick, style={}}) => {
    const handleClick = (e) => {
        e.stopPropagation();
        onClick && onClick();
    }
    return (
        <div className={`${className} control-card`} onClick={handleClick} style={style}>
            {children}
        </div>
    )
} 
export default ControlCard;