import useHttp from "App/hooks/use-http";

const useHomeApiHelper = () => {    
    let { triggerAPI } = useHttp();
    const getCollectorGallery = (data, callback) => {
        triggerAPI({
            url: `gallery/list/get`, data, method: 'post'
          }, (res => {
            const { list, pageMeta } = res?.data;            
            callback({list: list, pageMeta})
          }));
    }
    const getCreatorGallery = (data, callback) => {
        triggerAPI({
            url: `gallery/creators/gallery/get`, data, method: 'post'
          }, (res => {
            const { list, pageMeta } = res?.data;            
            callback({list: list, pageMeta})
          }));
    }
    const getCreator = (data, callback) => {
        triggerAPI({
            url: `community/gallery/get`, data, method: 'post'
          }, (res => {
            const { list, pageMeta } = res?.data;            
            callback({list: list, pageMeta})
          }));
    }
    const getNewCollection = (data, callback) => {
        triggerAPI({
            url: `community/creators/collections`, data, method: 'post'
          }, (res => {
            const { list, pageMeta } = res?.data;            
            callback({list: list, pageMeta})
          }));
    }
    const getStatitics = (data, callback) => {
        triggerAPI({
            url: `home/statitics`, data, method: 'get'
          }, (res => {       
            callback({data: res?.data})
          }));
    }
    const getAnnouncement = (data, callback) => {
        triggerAPI({
            url: `user/announcements/get`, data, method: 'post'
          }, (res => {       
            callback({data: res?.data})
          }));
    }
    const getEvents = (data, callback) => {
        triggerAPI({
            url: `user/events/get`, data, method: 'post'
          }, (res => {       
            callback({data: res?.data})
          }));
    }
    return {
      getCollectorGallery, getCreatorGallery, getCreator, getNewCollection, getStatitics,
      getAnnouncement, getEvents
    }
}
export default useHomeApiHelper;