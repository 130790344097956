export const validateFormGroup = ({ errors, formGroup, values }) => {
    const obj = {
        valid: true,
        validators: {}
    };
    for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
            const element = errors[key];
            if (element) {
                obj.valid = false
                obj.validators[key] = element;
            } else {
                const isValid = !(formGroup[key].isRequired && (!values[key] && !(formGroup[key].zeroAllowed &&Number(values[key])===0)));
                if (!isValid) {
                    obj.valid = isValid;
                    obj.validators[key] = '';
                }
            }
        }
    }
    return obj;
}
export const markAsTouched = (formValues) => {
    const newValue = {};
    for (const key in formValues) {
        if (Object.hasOwnProperty.call(formValues, key)) {
            const element = formValues[key];
            newValue[key] = element ?? ''
        }
    }
    return newValue;
}
export const getInitial = (formGroup) => {
    const newValue = {};
    for (const key in formGroup) {
        if (Object.hasOwnProperty.call(formGroup, key)) {
            const element = formGroup[key];
            newValue[key] = element.value
        }
    }
    return newValue;
}

export const checkHtmlContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser?.parseFromString(htmlString, 'text/html');
    const textContent = doc?.body?.textContent || '';

    return {
        isEmpty: textContent?.trim() === '',
        length: textContent?.length,
    };
};

export const extractTextFromHtmlContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser?.parseFromString(htmlString, 'text/html');

    const extractText = (element) => {
        return element.textContent.trim();
    };
    
    const formattedText = Array.from(doc.body.children)
        .map((child) => extractText(child))
        .join('\n\n');

    return {
        formattedText,
    };
};