import sortArrow from "Assets/Images/sort-arrow.svg";
import { useEffect, useState } from "react";

const SortHeader = ({ children, keyName, playList, setPlayList }) => {
  const [arrowFlip, setArrowFlip] = useState({
    field: null,
    sortName: "Name A-Z",
    flip: false,
  });
  const sortByKey = (key, orderAz) => {
    const tempData = playList?.map((nft) => {
      const sortedData = [...nft?.files].sort((a, b) => {
        if (typeof a.song_details[key] === "string") {
          return orderAz
            ? a.song_details[key].localeCompare(b.song_details[key])
            : b.song_details[key].localeCompare(a.song_details[key]);
        } else if (typeof a.song_details[key] === "number") {
          return orderAz
            ? a.song_details[key] - b.song_details[key]
            : b.song_details[key] - a.song_details[key];
        } else {
          return 0; // Default case if the type is neither string nor number
        }
      });
      return { ...nft, files: [...sortedData] };
    });
    setPlayList(tempData);
  };
  const sortByHandler = (_e, headerName) => {
    setArrowFlip({ field: headerName, flip: !arrowFlip?.flip });
  };
  useEffect(() => {
    sortByKey(arrowFlip?.field, arrowFlip?.flip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowFlip]);
  return (
    <div className="header-title">
      {children}
      <img
        src={sortArrow}
        className={`cursor-pointer mt-[0.2rem] ${
          arrowFlip?.field === keyName
            ? `${arrowFlip?.flip && "flip-icon"} opacity-100`
            : "opacity-50"
        }`}
        onClick={(e) => sortByHandler(e, keyName)}
        alt=""
      />
    </div>
  );
};
export default SortHeader;
