import { useState } from 'react'

const MySwipe = (props) => {
    const minSwipeDistance = 50
    const [touchStart, setTouchStart] = useState(0)
    const [touchEnd, setTouchEnd] = useState(0)
    const [touchVertStart, setTouchVertStart] = useState(0)
    const [touchVertEnd, setTouchVertEnd] = useState(0)

    const onTouchStart = (e) => {
        setTouchEnd(0)
        setTouchStart(e.targetTouches[0].clientX)

        setTouchVertEnd(0)
        setTouchVertStart(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
        setTouchVertEnd(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        if (!touchVertStart || !touchVertEnd) return

        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const distanceVert = touchVertStart - touchVertEnd
        const isUpSwipe = distanceVert > minSwipeDistance
        if (Math.abs(touchStart - touchEnd) > Math.abs(touchVertStart - touchVertEnd)) {
            if (isLeftSwipe) {
                props.onSwipedLeft()
            } else {
                props.onSwipedRight()
            }
        } else {

            if (isUpSwipe) {
                props.onSwipedUp()
            } else {
                props.onSwipedDown()
            }
        }
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}
    export default MySwipe