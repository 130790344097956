import "../Icons.scss";

const LockIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99844 0.800049C6.01021 0.800049 4.39844 2.41182 4.39844 4.40005V7.20005H3.99844C3.11478 7.20005 2.39844 7.91639 2.39844 8.80005V13.6C2.39844 14.4837 3.11478 15.2 3.99844 15.2H11.9984C12.8821 15.2 13.5984 14.4837 13.5984 13.6V8.80005C13.5984 7.91639 12.8821 7.20005 11.9984 7.20005H11.5984V4.40005C11.5984 2.41182 9.98666 0.800049 7.99844 0.800049ZM10.3984 7.20005V4.40005C10.3984 3.07457 9.32392 2.00005 7.99844 2.00005C6.67295 2.00005 5.59844 3.07457 5.59844 4.40005V7.20005H10.3984Z"
          fill="#0F172A"
        />
      </svg>
    </div>
  );
};

export default LockIcon;
