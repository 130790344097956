import Card from "App/Components/Ui/Card"
import Website from 'Assets/Images/Web.svg'
import Twitter from 'Assets/Icons/social-twitter.svg'
import Youtube from 'Assets/Icons/social-youtube-blue.svg'
import Discord from 'Assets/Icons/social-discord.svg'
import { Fragment, useCallback, useEffect, useState } from "react"
import useHttp from "App/hooks/use-http"
import { useStore } from "App/hooks-store/store"
import Person from "Assets/Images/Person.svg";
import "./UserDetails.scss"
import NftImage from "Assets/Icons/nftlogo.svg";

const UserDetails = ({ formData, galleryType = 0, fetchProfile }) => {
    const [list, setList] = useState({})
    const [formValue, setFormValue] = useState([])
    const [dispatch] = useStore(false);
    const { triggerAPI } = useHttp();

    const getProfileResult = useCallback((res) => {
        // setList(res.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getProfile = () => {
        triggerAPI({
            url: `user/profile/get`, method: 'get'
        }, getProfileResult);
    }

    useEffect(() => {
        if (!fetchProfile)
            getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setFormValue(formData)
        const profileData = fetchProfile ? formData?.profileData : formData?.profileData ?? formData?.user;
        setList(profileData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    return (
        <>
            <Card className="preview preview-user ">
                <div className="flex flex-col pt-2">
                    <div className="flex pt- w-full justify-between user-container">
                        <div className="flex gap-4 user-profile w-full">
                            <div className="headerArea-wrap-dp" style={{ backgroundImage: `url(${list?.profilePicUrl ?? Person})` }}>
                            </div>
                            <div className="flex flex-col items-start w-full">
                                <div className="flex justify-between w-full">
                                    {list?.name}
                                    <div className='flex gap-1 asset-count ml-auto items-center'>
                                        {formValue?.assets?.length}<img src={NftImage} className="w-4 h-4" alt="moi" />
                                    </div>
                                </div>

                                <div className="flex gap-2 flex-wrap items-center pt-2">
                                    {list?.website &&
                                        <div >
                                            <a className="flex gap-1 items-center cursor-pointer" href={list?.website} target="_blank" rel="noopener noreferrer">  <img src={Website} alt="images" /></a>
                                        </div>
                                    }
                                    {list?.twitter &&
                                        <div>
                                            <a className="flex gap-1 items-center cursor-pointer" href={list?.twitter} target="_blank" rel="noopener noreferrer"> <img src={Twitter} alt="images" /></a>
                                        </div>
                                    }
                                    {list?.discord &&
                                        <div>
                                            <a className="flex gap-1 items-center cursor-pointer" href={list?.discord} target="_blank" rel="noopener noreferrer"> <img src={Discord} alt="images" /></a>
                                        </div>
                                    }
                                    {list?.youtube &&
                                        <div>
                                            <a className="flex gap-1 items-center cursor-pointer" href={list?.youtube} target="_blank" rel="noopener noreferrer"> <img src={Youtube} alt="images" /></a>
                                        </div>
                                    }
                                </div>
                                <div className="flex user-details items-center  pt-2">
                                    {list?.nationality &&
                                        <ul className="a gap-2 flex flex-wrap">
                                            <li>{list?.nationality}</li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex user-details user-details-view items-center  pt-5">
                            <ul className="a gap-2 flex flex-wrap">
                                <div className="user-details-name">{formValue?.name}</div>

                            </ul>
                        </div>
                    </div>
                    <div className="user-details user-details-value user-details-desc pt-2">
                        {formValue?.description}</div>
                </div>
            </Card>
        </>
    )
}

export default UserDetails