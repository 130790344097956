import SkeletonLoader from "../SkeletonLoader"

const SkeltonCard = ({ show = false, height, count = 1, inline, containerClassName = '', noAbsolute = false, }) => {
    return (
        show &&
        <div className={noAbsolute ? 'h-full w-full skeltonMainClass' : 'absolute top-0 left-0 right-0 bottom-0 skeltonMainClass'}>

            <SkeletonLoader height={height} count={count} inline={inline} containerClassName={containerClassName} />
        </div>
    )
}

export default SkeltonCard