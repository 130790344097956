const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setBlockchains: (current, newBlockchains) => {
            return {
                blockchains: newBlockchains
            }
        }
    }
    initStore(actions, {
        blockchains: null
    });
}
export default configureStore;