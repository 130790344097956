import './CustomTable.scss';
import sortArrow from 'Assets/Images/sort-arrow.svg';
const CustomTable = props => {
    return (
        <div className={props?.mainContainerClass}>
            <div className={`${props?.tableHeadClass}`}>
                <table cellPadding="0" cellSpacing="0" border="0" className={`customeTable `}>
                    <thead className={`sticky `}>
                        <tr className='w-full'>
                            {props?.tableHead.map((tableHead, i) => 
                                 <th key={i} className={`${tableHead?.isSort? 'cursor-pointer' :''} ${i === 0 ? 'w-[22%]' :i === 2 || i === 6 ? 'w-[7%]' : 'w-[9%]'}`}
                                onClick={tableHead?.isSort && props?.onClick ? props?.onClick : null} data-sortby={tableHead?.sortName}>
                                    <div className={`flex ${i=== 0 || i === 1  ? 'justify-start' : 'justify-end'} items-center gap-6`}>
                                        <span className='capitalize'>{tableHead?.name}</span>
                                        {tableHead?.isSort && 
                                            <span>
                                                <img className={`${(props?.activeSort?.sortName === tableHead?.sortName && props?.activeSort?.flip) ? 'flip-icon' : ''} min-w-[0.7rem] w-[0.7rem] max-w-[0.7rem] mt-1 opacity-80`} alt='icon' src={sortArrow} />
                                            </span>
                                        }
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tbl-content ">
                <table cellPadding="0" cellSpacing="0" border="0" className={`customeTable ${props?.tableBodyClass}`}>
                    <tbody>
                        {props.children}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CustomTable