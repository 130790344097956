import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import ImagePopup from "App/Components/Ui/ImagePopup"
import closeIcon from "Assets/Icons/close.svg"

const FramePopup = (props) => {
    return (
        <ImagePopup cssClass="frame-popup" show={props?.show} onClose={props?.onClose}>
            <div>
                <div className="flex justify-end absolute top-0 right-4 z-[3] details-icon cursor-pointer gap-2 mt-2.5" onClick={props.onClose} >
                    <img alt="NoImg" className="gallery-close" src={closeIcon} />
                </div>
                <GalleryImages nftGalleryNew={props?.nftGalleryNew} nftFrames={props?.nftFrames} gallery={props?.gallery} galleryNew={props?.galleryNew} src={props?.src} />
            </div>
        </ImagePopup>
    )
}


export default FramePopup