import { configureStore } from '@reduxjs/toolkit'
import gallerySlice from './Slicers/gallerySlice'
import { AppStateSlice } from 'App/Pages/MetaGallery/Component/MetaGalleryState'
import userCollectionSlice from './Slicers/userCollectionSlice'
import galleryPreviewSlice from './Slicers/galleryPreviewSlice'
import frameSlice from './Slicers/frameSlice'
import authSlice from './Slicers/authSlice'
import spinSlice from './Slicers/spinSlice'
export const reduxStore = configureStore({
  reducer: { 
    gallery: gallerySlice,
    userCollection: userCollectionSlice,
    galleryPreview: galleryPreviewSlice,
    frame: frameSlice,
    auth:authSlice,
    spin:spinSlice,
    appState: AppStateSlice.reducer },
})