import LockIcon from "App/Components/IconsComponents/LockIcon"
import "./LockComponent.scss";
import ToolTip from "App/Components/ToolTip";

const LockComponent=({message})=>{
    return <div className="lock-component absolute inset-0 flex items-center justify-center">
    <ToolTip message={message}>
        <LockIcon/>
    </ToolTip>
   </div>
}

export default LockComponent