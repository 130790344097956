import { Html } from '@react-three/drei';

const MetaGalleryFrameOnChain = ({ nft, renderonchain, rotatey, imagexpos, imagezpos, videotexture }) => {
  const width = (nft?.frame?.metaInfo?.image?.widthPercent??100)/100;
  const height= (nft?.frame?.metaInfo?.image?.heightPercent??100)/100;
  const left= (nft?.frame?.metaInfo?.image?.xPercent??0)/100;
  const top= (nft?.frame?.metaInfo?.image?.yPercent??0)/100;

  let iframeposition = [];
  iframeposition[0]=imagexpos;
  iframeposition[2]=-left*width/8;
  iframeposition[1]=-top*height/8;
  iframeposition[2]=imagezpos;
  iframeposition[1]=0;

  function shapeipfs(ipfs) {    
    if (ipfs.join)
    {
      ipfs = ipfs.join('');
    }
    if (ipfs.startsWith("ipfs://"))
    {
      ipfs = ipfs.replace("ipfs://"," https://ipfs.io/ipfs/") + "?func=proxy";
    }

    return ipfs;
  }

  function getgif(nft) {
    if (!renderonchain) return null;

    //unable to get extra files from nftcdn
    // let files = nft?.details?.onchain_metadata?.files;
    // if (files?.length>0)
    // {
    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];
    //     if (file.mediaType === "image/gif")
    //     {          
    //       return shapeipfs(file.src);
    //     }
    //   }      
    // }
    
    if (nft?.details?.onchain_metadata?.mediaType == "image/gif")
    {
      return nft?.details?.nftcdnimage;      
    }
    if (nft?.details.onchain_metadata?.GIF)
    {
      return nft?.details?.nftcdnimage;
    }
    if (nft?.details.onchain_metadata?.gif)
    {
      return nft?.details?.nftcdnimage;
    }       

    return null;
  }  

  function getonchainhtml(nft) { 
    
    if (!renderonchain) return null;
    let files = nft?.details?.onchain_metadata?.files;
    if (files?.length>0)
    {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.mediaType === "text/html")
        {
          if (file.src.join)
          {
            return file.src.join('');
          }          
          return shapeipfs(file.src);
        }
      }
    }
    
    return null;
  }

  function GetNftContent({nft}) {
    let onchainhtml = getonchainhtml(nft);   

    if (onchainhtml)
    {           
      return <>      
        <Html className="content" position={iframeposition} rotation-y={-rotatey*Math.PI / 2}  scale={0.0401*width} transform occlude="blending">
            <iframe src={onchainhtml} style={{
                width: '100%',
                height: '100%'                
            }}></iframe>            
        </Html>
      </>
    }
    else
    {   
      let gifurl = getgif(nft);
      if (gifurl)
      {
        return <>      
          <Html className="content" position={iframeposition} rotation-y={-rotatey*Math.PI / 2}  scale={0.0401*width} transform occlude="blending">
              <img src={gifurl} style={{
                  width: '100%',
                  height: '100%'                
              }}></img>            
          </Html>
        </>
      }
      else if (videotexture)
      {
        return <>      
          <mesh position={iframeposition} >
            <boxGeometry args={[0.0001,1,1]}/>
            <meshBasicMaterial map={videotexture}></meshBasicMaterial>  
          </mesh>
        </>
      }      
    }

    return "";
  }
  
    return (
      <GetNftContent nft={nft}/>   
    )
  };
  
  export default MetaGalleryFrameOnChain;