import borderIcon from "Assets/Icons/TopMenu/border-line-icon.svg"
import "./BorderRight.scss";

const BorderRight = () => {
    return (
        <div className="border-line">
            {/* <img alt={''} src={borderIcon} /> */}
        </div>
    )
}
export default BorderRight;