import styles from "./AttributesTab.module.scss";

const AttributesTab = (props) => {  
  function renderprops(props) {  
    let result = [];
    if (props)
    {
      const keys = Object.keys(props).sort((a,b)=>{return a>b?1:-1});
      for (let p = 0; p < keys.length; p++) {
        const prop = keys[p];
        if(prop === 'Description' || prop === 'description'){
          continue;
        }
        //?.toLowerCase()==="items"?"pieces":keys[p]?.toLowerCase()==="item"?"piece":keys[p];
        if (typeof(props[prop])=="string" || typeof(props[prop])=="number" )
        {        
          result.push(<div key={prop} className="flex gap-[1rem] ml-[.5rem]">
                  <div className={`${styles["data-title"]} `} tooltip={prop}>{prop}</div>
                  <div className={`${styles["data-value"]}`}>
                    {props[prop]?.startsWith && (props[prop].startsWith('http') || props[prop].startsWith('ipfs')) ? <a href={props[prop]} rel="noreferrer"  target="_blank">{props[prop]}</a> : props[prop]}
                    
                  </div>  
                </div>)       
        }
        else if (Array.isArray(props[prop]))
        {     
          if (props[prop].length> 0)
          {          
            if (prop != "files" && prop != "description")
            {         
              if (props[prop].filter(p=>typeof(p)!="string").length > 0)
              {
                let childresults = [];
                for (let i = 0; i < props[prop].length; i++) {
                  const propitem = props[prop][i];            
                  childresults.push(renderprops(propitem));
                }
                result.push(
                  <div key={prop} className="flex gap-[1rem] ml-[.5rem]">
                    <div className={`${styles["data-title"]} `} tooltip={prop}>{prop}</div>
                    <div className={`${styles["data-subvalue"]} `}>{childresults}</div>            
                  </div>);
              }   
              else //its just an array of strings, join them and show them
              {
                const str = props[prop].join(' ');
                if (!str.startsWith("data:"))
                {
                  result.push(<div key={prop} className="flex gap-[1rem] ml-[.5rem]">
                              <div className={`${styles["data-title"]} `} tooltip={prop}>{prop}</div>
                              <div className={`${styles["data-value"]}`}>
                                {str}
                              </div>  
                            </div>)
                }
              } 
            }         
          }
        }
        else
        {        
          
          result.push(
            <div className="flex flex-col gap-[0.5rem]" key={prop}>
              <div className={`${styles["data-title"]} ml-[0.5rem] `} tooltip={prop}>{prop}</div>
              <div className={`${styles["data-subvalue"]} `}>{renderprops(props[prop])}</div>            
            </div>);
        } 
      }
    }   
    
    return result;
  }
  return (    
    <div className={`${styles["attributes-tab"]}`}>
      <div className={`${styles["attribute-details"]}`}>
        <div className={`${styles["attribute-details-box"]} scroller side-scroller`}>            
          <div className={`${styles["data-box"]} ${styles["mr-0"]} ${styles["pr-0"]} ${styles["border-0"]}`}>              
            {renderprops(!props.isGallery ? props.nft?.onchain_metadata : props.nft?.assetMetadata)}                           
          </div>
        </div>
      </div>
    </div>    
  );
};

export default AttributesTab;