import "../Icons.scss";

const TileIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer rect-stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="7" height="10" rx="0.5" stroke="#A0ABBB" />
        <rect x="11" y="2" width="11" height="10" rx="0.5" stroke="#A0ABBB" />
        <rect x="2" y="14" width="20" height="8" rx="0.5" stroke="#A0ABBB" />
      </svg>
    </div>
  );
};

export default TileIcon;
