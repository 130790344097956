import styles from '../../Profile.module.scss'
import Panel from "App/Components/Ui/Panel"
import Label from "App/Components/Ui/Label"
import Input from "App/Components/Ui/Input"
import RoundedButton from 'App/Components/Ui/RoundedButton'
import { useState, useCallback, useMemo } from "react";
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import Validators from 'App/Components/Ui/Forms/Validators'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import useScreenSize from 'App/hooks/use-screen-size'

const ChangePassword = props => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        oldPassword: {
            value: undefined,
            isRequired: true
        },
        newPassword: {
            value: undefined,
            isRequired: true
        },
        confirmPassword: {
            value: undefined,
            isRequired: true
        }
    }
    const passwordValidatorsList = useMemo(() => ([{
        type: 'Required',
        message: 'Required field'
    }, {
        type: 'Password',
        message: 'Password is not strong enough'
    }, {
        type: 'NotMatch',
        message: 'Old and New passwords should not match'
    }]), []);
    const [formValues, setFormvalues] = useState(getInitial(formGroup));

    //Change password API
    const changePasswordResponse = (params) => {
        dispatch('hideSpinner');
        props.onComplete()
        dispatch('showToast', { toast: { toastMode: 'success', message: "Password change successful" } })
    }
    const changePassword = () => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/profile/change-password`, data: { oldPassword: formValues.oldPassword, newPassword: formValues.newPassword }, method: 'patch'
        }, changePasswordResponse, err => {
            dispatch('hideSpinner');
            dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
        });
    }

    //Form input handler
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }

    //Form submit hanler
    const submitHandler = e => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        e.preventDefault();
        if (valid)
            changePassword()
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }

    //Form error handler
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    const screenSize = useScreenSize()?.width;
    return <div>
        <Panel className={`${styles['change-password-panel']} p-5 ${screenSize < 551 ? 'mt-[2.5rem]' : ''}`}>
            <div className={`${styles["content-box"]} flex w-full`}>
                <div className='basis-[32rem] '>
                    <div>
                        <div className='flex flex-col text-left gap-2.5'>
                            <div className='flex'>
                                <div className={`${styles["input-row"]} flex flex-col  basis-80`}>
                                    <div className={`${styles['password-label']} font-14 mb-[.8rem]`}>
                                        <Label>Old Password
                                        </Label>
                                    </div>
                                    <div className={styles["input"]}>
                                        <Input label="oldPassword" validateOnBlur onChange={inputOnChange} controlName="oldPassword" type="password" value={formValues.oldPassword}
                                        ></Input>
                                        <Validators type="Required" value={formValues.oldPassword} message="Required field" controlName="oldPassword" onErrors={collectErrors} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${styles["input-row"]} flex flex-col basis-80`}>
                                    <div className={`${styles['password-label']} font-14 mb-[.8rem]`}>
                                        <Label>New Password
                                        </Label>
                                    </div>
                                    <div className={styles["input"]}>
                                        <Input label="newPassword" validateOnBlur controlName="newPassword" onChange={inputOnChange} type="password" value={formValues.newPassword}
                                        ></Input>
                                        <Validators type="Password" value={formValues.newPassword} matchValue={formValues.oldPassword} controlName="newPassword" onErrors={collectErrors} validators={passwordValidatorsList} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${styles["input-row"]} flex flex-col basis-80`}>
                                    <div className={`${styles['password-label']} font-14 mb-[.8rem]`}>
                                        <Label>Confirm New Password
                                        </Label>
                                    </div>
                                    <div className={styles["input"]}>
                                        <Input label="confirmPassword" validateOnBlur controlName="confirmPassword" onChange={inputOnChange} type="password" value={formValues.confirmPassword}
                                        ></Input>
                                        <Validators type="Match" value={formValues.confirmPassword} matchValue={formValues.newPassword} message="Password and confirm password must match" controlName="confirmPassword" onErrors={collectErrors} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <div className='flex w-full'>
                            <div className={`${styles['confirm-btn-box']}`}>
                                <RoundedButton OnClick={submitHandler} Name="Confirm" Type="4"></RoundedButton>
                            </div>
                            <div className={`${styles['confirm-btn-box']}`}>
                                <RoundedButton OnClick={props.onCancel} Name="Cancel" Type="4"></RoundedButton>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={`${styles["instructions"]} text-left font-12 flex flex-col gap-2.5`}>
                    <p className={`${styles["password-instructions"]}`}>Password must contain:</p>
                    <div className='flex flex-col gap-[0.375rem]'>
                        <p>At least 1 upper case letter (A-Z)</p>
                        <p>At least 1 number</p>
                        <p>At least 1 symbol</p>
                        <p>At least 8 characters</p>
                    </div>
                </div>
            </div>
            <div>
                <div className='flex w-full change-pass-btn'>
                    <div className={`${styles['confirm-btn-box']}`}>
                        <RoundedButton className={`${styles['confirm-btn-cancel-box']}`} OnClick={props.onCancel} Name="Cancel" Type="10"></RoundedButton>
                    </div>
                    <div className={`${styles['confirm-btn-box']} confirm`}>
                        <RoundedButton className={`${styles['confirm-btn-cancel-box']}`} OnClick={submitHandler} Name="Confirm" Type="10"></RoundedButton>
                    </div>
                </div>

            </div>
        </Panel>
    </div>
}

export default ChangePassword