import SkeltonCard from "App/Components/Ui/SkeltonCard";
import { memo, useCallback, useState } from "react";

const LayoutImageCard = ({design, selected, handleLayoutClick}) => {
    const [loading, setLoading] = useState(true);
    const onImageLoad = useCallback((e) => {
        setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[design?.s3ImageUrl]);
    return (
        <div className={`relative h-fit max-h-[3rem] cursor-pointer rounded-[.25rem] overflow-hidden ${(design?.id===selected?.id && !loading) && "active"} layout-image`} data-id={design?.id} 
            onClick={handleLayoutClick}>
            {loading && <div className="skelcontainer w-full h-full"><SkeltonCard show={loading} containerClassName={`layout-loading-image`} height="2rem" inline={false}/></div>}
            {<img src={design?.s3ImageUrl} onLoad={onImageLoad} className={loading ? 'opacity-0' : 'opacity-1'} alt="" crossOrigin="anonymous" />}
        </div>
    )
}
export default memo(LayoutImageCard);