import "../../Icons.scss";

const BackgroundIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color rect-stroke-color fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.4401 8.88802L2.77344 12.4436H13.4401L10.1068 7.99913L7.4401 11.5547L5.4401 8.88802Z"
          fill="#A0ABBB"
        />
        <path
          d="M10.2623 5.84358C10.5142 6.09543 10.8327 6.22135 11.2179 6.22135C11.6031 6.22135 11.9216 6.09543 12.1734 5.84358C12.4253 5.59172 12.5512 5.27321 12.5512 4.88802C12.5512 4.50284 12.4253 4.18432 12.1734 3.93247C11.9216 3.68061 11.6031 3.55469 11.2179 3.55469C10.8327 3.55469 10.5142 3.68061 10.2623 3.93247C10.0105 4.18432 9.88455 4.50284 9.88455 4.88802C9.88455 5.27321 10.0105 5.59172 10.2623 5.84358Z"
          fill="#A0ABBB"
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="1.5"
          stroke="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default BackgroundIcon;
