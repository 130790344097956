import { useGLTF, useTexture } from '@react-three/drei';
import { useSelector,useDispatch } from 'react-redux'


import * as THREE from 'three';
import MetaGalleryFrame from '../Component/MetaGalleryFrame';
import { setframepositions,settotalnfts } from '../Component/MetaGalleryState';

const MetaGallery3 = ({ galleryList, captionControl }) => {  
  const dispatch = useDispatch(); 

  const colorMap = useTexture('/metagalleryassets/textures/wall3.jpg')
  colorMap.repeat.set(2,2);
  colorMap.wrapS=THREE.RepeatWrapping;
  colorMap.wrapT=THREE.RepeatWrapping;

  const { nodes, materials } = useGLTF('/metagalleryassets/models/gallery4-transformed.glb');
  materials['candle_Glass'].transparent = true;  
  materials['candle_Glass'].opacity = 0.2;
  // materials['glass edge'].transparent = true;
  // materials['glass edge'].opacity = 0.1;
  let framepositions = useSelector((state) => state.appState.framepositions.payload)??[];      
  
  if (framepositions.length ===0)
  {         
    framepositions.push(      
      [[1.05,2.2,3.05],[1.05,2.2,4]],
      [[3.35,2.2,3.05],[3.3,2.2,4]],
      [[6.35,2.2,4.8],[5.25,2.2,4.8]],
      [[6.24,2.2,7.2],[5.25,2.2,7.2]],
      [[4.51,2.2,8.99],[4.51,2.2,7.99]],
      [[2.2,2.2,8.99],[2.2,2.2,7.99]]      
    );

    dispatch(setframepositions(framepositions));    
  }

  if (galleryList.length < 10)
  {
    let len = galleryList.length;
    let j = -1;
    for (let i = len; i < 11; i++) {
      if (j === galleryList.length)
      {
        j = 0;
      }
      else 
      {
        j++;
      }
      galleryList.push(galleryList[j]);    
    }
  }
  //setnfts(galleryList);      
  dispatch(settotalnfts(galleryList.length));      


  let index = useSelector((state) => state.appState.index.payload)??0;
  let isviewingframe = useSelector((state) => state.appState.isviewingframe.payload)??0;     

  return (    
    <group dispose={null}>      

{galleryList.length>0 && <>   
{/* 
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.galleryunitybare002_1.geometry} receiveShadow>
          <meshStandardMaterial map={colorMap}/>
        </mesh>
        <mesh geometry={nodes.galleryunitybare002_2.geometry} receiveShadow>
          <meshStandardMaterial map={colorMap}/>
        </mesh>
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.galleryunitybareprops001_1.geometry} material={materials['TrackLightingA1_Emmisive_MAT.004']} />
        <mesh geometry={nodes.galleryunitybareprops001_2.geometry} material={materials['TrackLightingA1_MAT.004']} />        
      </group>            
                                         */}

    <ambientLight intensity={4.4} />
    <spotLight penumbra={0.1} position={[0, 25, 0]} intensity={1000} />            

    <group dispose={null} rotation={[0, -Math.PI/2+0.0015, 0]} position={[1.25,0.45,6]} scale={[1,1,1]}>
    
      <mesh geometry={nodes.bar_back_cab_Baked.geometry} material={materials['bar back cab_Baked']} position={[-3, 0.75, 5]} />
      <mesh geometry={nodes.bar_back_frame_Baked.geometry} material={materials['bar back frame_Baked']} position={[-2, 0, 5]} />
      <mesh geometry={nodes.bar_back_cab_doors_Baked.geometry} material={materials['bar back cab doors_Baked']} position={[2.476, 0.1, 4.445]} />
      <mesh geometry={nodes.bar_back_shelves_Baked.geometry} material={materials['bar back shelves_Baked']} position={[-1.7, 0.85, 4.95]} />
      <mesh geometry={nodes.bar_counter_bot_Baked.geometry} material={materials['bar counter bot_Baked']} position={[-1.8, 0, 3.75]} />
      <mesh geometry={nodes.bar_counter_top_Baked.geometry} material={materials['bar counter top_Baked']} position={[-2, 0.95, 3.75]} />
      <mesh geometry={nodes.menu_1_Baked.geometry} material={materials['menu 1_Baked']} position={[2.497, 1.678, 4.999]} />
      <mesh geometry={nodes.menu_2_Baked.geometry} material={materials['menu 2_Baked']} position={[-2.536, 1.678, 4.999]} />
      <mesh geometry={nodes.wall_bot_Baked.geometry} material={materials['wall bot_Baked.001']} position={[-3, 0, 5.2]} />
      <mesh geometry={nodes.wall_hallway_Baked.geometry} material={materials['wall hallway_Baked.001']} position={[5.2, 0, 5.2]} />
      <mesh geometry={nodes.wall_left_1_Baked.geometry} material={materials['wall left 1_Baked.001']} position={[-3.2, 0, -1.2]} />
      <mesh geometry={nodes.wall_left_2_Baked.geometry} material={materials['wall left 2_Baked.001']} position={[-3.2, 0, -1.2]} />
      <mesh geometry={nodes.wall_left_3_Baked.geometry} material={materials['wall left 3_Baked.001']} position={[-3.2, 0, -1.2]} />
      <mesh geometry={nodes.wall_right_1_Baked.geometry} material={materials['wall right 1_Baked.001']} position={[3, 0, -1.2]} />
      <mesh geometry={nodes.wall_right_2_Baked.geometry} material={materials['wall right 2_Baked.001']} position={[3, 0, -1.2]} />
      <mesh geometry={nodes.wall_right_3_Baked.geometry} material={materials['wall right 3_Baked']} position={[3, 0, -1.2]} />
      <mesh geometry={nodes.wall_top_1_Baked.geometry} material={materials['wall top 1_Baked']} position={[-3.2, 0, -5]} />
      <mesh geometry={nodes.wall_top_2_Baked.geometry} material={materials['wall top 2_Baked']} position={[-3.2, 0, -5]} />
      <mesh geometry={nodes.baseboard_Baked.geometry} material={materials.baseboard_Baked} position={[2.954, 0, 0.8]} />
      <mesh geometry={nodes.mouldings_Baked.geometry} material={materials.mouldings_Baked} position={[-3, 0, -4.975]} />
      <mesh geometry={nodes.doorway_Baked.geometry} material={materials.doorway_Baked} position={[2.95, 0, 0.8]} />
      <mesh geometry={nodes.ledge_table_path001_Baked.geometry} material={materials['ledge table path.001_Baked']} position={[-3, 0.85, 1.975]} />
      <mesh geometry={nodes.ledge_table_path002_Baked.geometry} material={materials['ledge table path.002_Baked']} position={[-3, 0.85, 1.975]} />
      <mesh geometry={nodes.ledge_table_path003_Baked.geometry} material={materials['ledge table path.003_Baked']} position={[-3, 0.85, 1.975]} />
      <mesh geometry={nodes.ledge_table_path_Baked.geometry} material={materials['ledge table path_Baked']} position={[-3, 0.85, 1.975]} />
      <mesh geometry={nodes.ceiling_bar_Baked.geometry} material={materials['ceiling bar_Baked']} position={[-3, 2.7, 5]} />
      <mesh geometry={nodes.ceiling_hallway_Baked.geometry} material={materials['ceiling hallway_Baked']} position={[3.2, 3, 5.2]} />
      <mesh geometry={nodes.ceiling_joists_Baked.geometry} material={materials['ceiling joists_Baked']} position={[-3.1, 3, -4.75]} />
      <mesh geometry={nodes.ceiling_lights_Baked.geometry} material={materials['ceiling lights_Baked']} position={[2.144, 2.744, 3.4]} />
      <mesh geometry={nodes.ceiling_Baked.geometry} material={materials.ceiling_Baked} position={[-3.15, 3.2, 2.05]} />
      <mesh geometry={nodes.floor_1_Baked.geometry} material={materials['floor 1_Baked']} position={[-2, -0.1, 3.5]} />
      <mesh geometry={nodes.floor_2_Baked.geometry} material={materials['floor 2_Baked']} position={[-2, -0.1, 3.5]} />
      <mesh geometry={nodes.floor_hallway_Baked.geometry} material={materials['floor hallway_Baked']} position={[3.2, -0.1, 5.2]} />
      <mesh geometry={nodes.wall_light_Baked.geometry} material={materials['wall light_Baked.003']} position={[-2.842, 2.417, -2.118]} />
      <mesh geometry={nodes.wall_light_Baked001.geometry} material={materials['wall light_Baked.003']} position={[-2.842, 2.417, 0.212]} />
      <mesh geometry={nodes.wall_light_Baked002.geometry} material={materials['wall light_Baked.003']} position={[-1.195, 2.417, -4.842]} />
      <mesh geometry={nodes.wall_light_Baked003.geometry} material={materials['wall light_Baked.003']} position={[1.194, 2.417, -4.842]} />
      <mesh geometry={nodes.wall_light_Baked004.geometry} material={materials['wall light_Baked.003']} position={[2.842, 2.417, -3.256]} />
      <mesh geometry={nodes.wall_light_Baked005.geometry} material={materials['wall light_Baked.003']} position={[2.842, 2.417, -0.944]} />
      <mesh geometry={nodes.bar_table_Baked.geometry} material={materials['bar table_Baked']} position={[-0.231, 0, 0.231]} />
      <mesh geometry={nodes.bar_table_Baked001.geometry} material={materials['bar table_Baked']} position={[-0.293, 0, -2.218]} />
      <mesh geometry={nodes.Bar_Stool_Baked.geometry} material={materials['Bar Stool_Baked']} position={[0.452, 0, 0.018]} />
      <mesh geometry={nodes.Bar_Stool_Baked001.geometry} material={materials['Bar Stool_Baked']} position={[-0.239, 0, 0.386]} />
      <mesh geometry={nodes.Bar_Stool_Baked002.geometry} material={materials['Bar Stool_Baked']} position={[-0.206, 0, -0.403]} />
      <mesh geometry={nodes.Bar_Stool_Baked003.geometry} material={materials['Bar Stool_Baked']} position={[0.233, 0, -1.734]} />
      <mesh geometry={nodes.Bar_Stool_Baked004.geometry} material={materials['Bar Stool_Baked']} position={[-0.432, 0, -2.148]} />
      <mesh geometry={nodes.Bar_Stool_Baked005.geometry} material={materials['Bar Stool_Baked']} position={[0.268, 0, -2.513]} />
      <mesh geometry={nodes.Bar_Stool_Baked006.geometry} material={materials['Bar Stool_Baked']} position={[2.533, 0, -0.541]} />
      <mesh geometry={nodes.Bar_Stool_Baked007.geometry} material={materials['Bar Stool_Baked']} position={[2.566, 0, -1.33]} />
      <mesh geometry={nodes.Bar_Stool_Baked008.geometry} material={materials['Bar Stool_Baked']} position={[2.541, 0, -2.869]} />
      <mesh geometry={nodes.Bar_Stool_Baked009.geometry} material={materials['Bar Stool_Baked']} position={[2.574, 0, -3.658]} />
      <mesh geometry={nodes.Bar_Stool_Baked010.geometry} material={materials['Bar Stool_Baked']} position={[-2.562, 0, -1.735]} />
      <mesh geometry={nodes.Bar_Stool_Baked011.geometry} material={materials['Bar Stool_Baked']} position={[-2.526, 0, -2.515]} />
      <mesh geometry={nodes.Bar_Stool_Baked012.geometry} material={materials['Bar Stool_Baked']} position={[-2.581, 0, 0.624]} />
      <mesh geometry={nodes.Bar_Stool_Baked013.geometry} material={materials['Bar Stool_Baked']} position={[-2.545, 0, -0.156]} />
      <mesh geometry={nodes.Bar_Stool_Baked014.geometry} material={materials['Bar Stool_Baked']} position={[-1.599, 0, -4.565]} />
      <mesh geometry={nodes.Bar_Stool_Baked015.geometry} material={materials['Bar Stool_Baked']} position={[-0.819, 0, -4.529]} />
      <mesh geometry={nodes.Bar_Stool_Baked016.geometry} material={materials['Bar Stool_Baked']} position={[0.799, 0, -4.565]} />
      <mesh geometry={nodes.Bar_Stool_Baked017.geometry} material={materials['Bar Stool_Baked']} position={[1.578, 0, -4.529]} />
      <mesh geometry={nodes.Bar_Stool_Baked018.geometry} material={materials['Bar Stool_Baked']} position={[0, 0, 2.428]} />
      <mesh geometry={nodes.Bar_Stool_Baked019.geometry} material={materials['Bar Stool_Baked']} position={[0.8, 0, 2.428]} />
      <mesh geometry={nodes.Bar_Stool_Baked020.geometry} material={materials['Bar Stool_Baked']} position={[1.6, 0, 2.428]} />
      <mesh geometry={nodes.Bar_Stool_Baked021.geometry} material={materials['Bar Stool_Baked']} position={[-0.8, 0, 2.428]} />
      <mesh geometry={nodes.Bar_Stool_Baked022.geometry} material={materials['Bar Stool_Baked']} position={[-1.6, 0, 2.428]} />
      <instancedMesh args={[nodes.Circle006.geometry, materials['candle base_Baked'], 6]} instanceMatrix={nodes.Circle006.instanceMatrix} />
      <instancedMesh args={[nodes.Circle007.geometry, materials.candle_Baked, 6]} instanceMatrix={nodes.Circle007.instanceMatrix} />
      <instancedMesh args={[nodes.Cube024.geometry, materials['Layer Fire'], 6]} instanceMatrix={nodes.Cube024.instanceMatrix} />
      <instancedMesh args={[nodes.Circle008.geometry, materials.candle_Glass, 6]} instanceMatrix={nodes.Circle008.instanceMatrix} />
      <instancedMesh args={[nodes.Cylinder012.geometry, materials.candle_Glass, 68]} instanceMatrix={nodes.Cylinder012.instanceMatrix} />
      
    </group>
    

         <MetaGalleryFrame parentscale={0.8} scale={[0.8,0.8,0.8]} shadowsign={[1,0,-1]} position={[1.05,2.2,3.01]} nft={galleryList[0]} rotation={[0,Math.PI/2,0]} renderonchain={index===0&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

  <MetaGalleryFrame parentscale={0.8} scale={[0.8,0.8,0.8]} shadowsign={[1,0,-1]} position={[3.35,2.2,3.022]} nft={galleryList[1]} rotation={[0,Math.PI/2-0.04,0]} renderonchain={index===1&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>
         
       <MetaGalleryFrame parentscale={0.8} scale={[0.8,0.8,0.8]}  position={[6.24,2.2,4.8]} nft={galleryList[2]}  renderonchain={index===2&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>


          <MetaGalleryFrame parentscale={0.8} scale={[0.8,0.8,0.8]} position={[6.24,2.2,7.2]} nft={galleryList[3]} renderonchain={index===3&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

         <MetaGalleryFrame parentscale={0.8} scale={[0.8,0.8,0.8]} endwall={true} rotatey={true} position={[4.51,2.2,8.99]} nft={galleryList[4]} rotation={[0,-Math.PI/2,0]} renderonchain={index===4&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

<MetaGalleryFrame parentscale={0.8} scale={[0.8,0.8,0.8]} endwall={true} rotatey={true} position={[2.2,2.2,8.99]} nft={galleryList[4]} rotation={[0,-Math.PI/2,0]} renderonchain={index===5&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

</>}
        
  </group>           
  );
};

export default MetaGallery3;
