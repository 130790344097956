import styles from "./ForgotPassword.module.scss";
import RoundedInput from '../../../../Components/Ui/RoundedInput'
import RoundedButton from "App/Components/Ui/RoundedButton";
import emailIcon from 'Assets/Icons/email.svg'
import { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Validators from "App/Components/Ui/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { capitalizeFirstLetter } from "App/Helper/utilities";

function ForgotPassword() {
  const dispatch = useStore(false)[1]
  const { triggerAPI } = useHttp();
  const navigate = useNavigate();
  const [formVaildState, setFormVaildState] = useState({});
  const formGroup = {
    email: {
      value: undefined,
      isRequired: true
    }
  }
  const emailValidatorsList = useMemo(() => ([{
    type: 'Required',
    message: 'Required field'
  }, {
    type: 'Email',
    message: 'Invalid Email'
  }]), []);
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const getForgotPasswordResponse = useCallback((res) => {
    dispatch('hideSpinner');
    navigate("/")
    dispatch('showToast', { toast: { toastMode: 'success', message: capitalizeFirstLetter(res.message) } })
  }, [dispatch, navigate])
  const forgotPassword = () => {
    dispatch('showSpinner')
    triggerAPI({
      url: `user/forget-password`, data: { email: formValues.email }, method: 'post'
    }, getForgotPasswordResponse,()=>{
      dispatch("hideSpinner");
  });
  }

  const submitHandler = e => {
    const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
    e.preventDefault();
    if (valid)
      forgotPassword()
    else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  }
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e
      }
    })
  }
  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error
      }
    })
  }, [])

  return (
    <div className={`w-full max-w-xs justify-items-center ${styles['forgot-pass']}`}>
      <p className={styles["label"]}>Trouble Signing in?</p>
      <form className=" rounded pt-[2.1875rem] pb-8 mb-4 max-w-[15.75rem] m-auto" onSubmit={submitHandler}>
        <div className="mb-1">
          <RoundedInput label="Email" className={styles['auth-round']} icon={emailIcon} Id="email" validateOnBlur type="text" controlName="email" Placeholder="Email" ChangeFunction={inputOnChange}
          ></RoundedInput>
          <Validators type="Email" value={formValues.email} controlName="email" onErrors={collectErrors} validators={emailValidatorsList} />
        </div>
        <div className={`flex items-center justify-between mt-4 ${styles['forgot-pass-button']}`}>
          <RoundedButton OnClick={submitHandler} Name="Reset Password" Type="2"></RoundedButton>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword