import { useEffect, useLayoutEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import './App.scss';
import { updateViewPortMeta } from './Helper/utilities';
import Main from './Layouts/Main'
import Store from './Store';
import { MsalProvider } from "@azure/msal-react";
import ZenKakuGothicAntique from "../Assets/Fonts/ZenKakuGothicAntique-Regular.ttf"
import BlockchainsProvider from './BlockchainsProvider';

function App({instance}) {
  
  useLayoutEffect(() => {
    updateViewPortMeta()
  },[])
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    // Function to check if font is loaded
    const font = new FontFace('ZenKakuGothicAntique-Regular', `url(${ZenKakuGothicAntique})`);
    font.load()
      .then(() => {
        document.fonts.add(font);
        setTimeout(()=>{
          setFontLoaded(true);
        },500)
      })
      .catch((error) => {
        console.error('Font failed to load:', error);
      });
      
    //create header links dependent upon company env variable
    var linkIcon = document.createElement("link")      
    linkIcon.rel = "icon"      
    linkIcon.href = process.env.REACT_APP_COMPANY==='anywall'?"/anywall-favicon.png":"/favicon.ico.png";
    document.head.appendChild(linkIcon);

    var link = document.createElement("link")      
    link.rel = "apple-touch-icon"      
    link.href = process.env.REACT_APP_COMPANY==='anywall'?"/anywall-favicon.png":"/favicon.ico.png";
    document.head.appendChild(link);                

    var linkMainFest = document.createElement("link")      
    linkMainFest.rel = "manifest"      
    linkMainFest.href = process.env.REACT_APP_COMPANY==='anywall'?"/anywall-manifest.json":"/manifest.json";
    document.head.appendChild(linkMainFest);    
    
    var title = document.createElement("title")      
    title.textContent = process.env.REACT_APP_COMPANY==='anywall'?"Anywall: Curate The World’s Digital Assets - Discover, curate, and dis(play) your digital collections"
                          :"Cur8: Curate The World’s Digital Assets - Discover, curate, and dis(play) your digital collections";
    document.head.appendChild(title);        
  }, []);
  if(!fontLoaded) return

  return (
    <div className="App h-full">
      <MsalProvider instance={instance}>
        <Store>
          <BlockchainsProvider>
            <Main />
          </BlockchainsProvider>
        </Store>
      </MsalProvider>
    </div>
  );
}

export default App;
