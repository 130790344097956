import styles from './Panel.module.scss'

const Panel = props => {

    const classNames = `${props.className} ${styles['content-sec']} ${props["border-r-1"] ? styles["border-r-1"] : ""}`
    return (
        <div className={`${classNames} ${props.borderLess ? styles["borderless"] : ""} ${props.transparent ? styles["transparent"] : ""}`}>
            {props.children}
        </div>
    )
}

export default Panel