import styles from './AccessPlans.module.scss'
import { useState, useEffect } from 'react'
import Card from 'App/Components/Ui/Card'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import RoundedButton from 'App/Components/Ui/RoundedButton'
import { thousandSeparator } from 'App/Helper/utilities'
import AccessPlanImg from "Assets/Images/AccessPlanImg.png"
import useScreenSize from 'App/hooks/use-screen-size'

const AccessPlans = () => {
    const screenSize = useScreenSize()?.width;
    const dispatch = useStore(false)[1];
    const [accessPlans, setAccessPlans] = useState([])
    const { triggerAPI } = useHttp();
    useEffect(() => {
        getAccessPlanList()
        dispatch("setPageTitle", "Access Plans")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getAccessPlanList = () => {
        const getApiResult = (url) => {
            return new Promise((resolve) => {
                triggerAPI({
                    url, method: 'get'
                }, resolve);
            })
        }
        const promiseList = [getApiResult(`access-plan/get`), getApiResult('access-plan/check/user/subscription')]
        Promise.all(promiseList).then(([{ data: list }, { data: listData }]) => {
            const accessPlan = []
            list?.forEach(element => {
                const find = listData?.find((x) => x.id === element?.id) ?? {}
                accessPlan.push({ ...element, ...find })

            });
            let isSubscribing = false
            accessPlan.sort((a, b) => Number(a?.minimumStake) - Number(b?.minimumStake));

            accessPlan?.forEach((y, i) => {
                y.stakePercent = y?.stakedAmount >= Number(y?.minimumStake) ? 100 : ((y?.stakedAmount / Number(y?.minimumStake)) * 100)
                y.minimumStakeString = thousandSeparator(y.minimumStake)
                y.stakedAmountString = thousandSeparator(Math.round(y.stakedAmount))
                if (!isSubscribing) {
                    const allCompleted = accessPlan?.length - 1 === i
                    y.isSubscribing = isSubscribing = (y.stakePercent < 100 && y.stakePercent >= 0) || (allCompleted)
                }
            })
            setAccessPlans(accessPlan);
        })
    }

    return <div className={`${styles['access-plans']} w-full h-full ${screenSize < 551 ? 'mt-[1rem]' : ''}`}>
        <div className='w-full h-full flex gap-3 overflow-auto pln-mob'>
            {accessPlans?.map((access, i) =>
                (access?.isSubscribing) &&
                <AccessPlan key={i} access={access} i={i} accessPlans={accessPlans} />

            )}

        </div>

    </div>

}

const AccessPlan = ({ access, i, accessPlans }) => {
    const screenSize = useScreenSize()?.width;
    const onClickHandler = () => {
        window?.open('https://pooltool.io/pool/d19db3e7e7b4c7b3673e6f8ae92cbf46eb4c13897d28363e0fdc1f44/epochs')
    }
    return <div className={`${styles['pln']} ${styles["boxwrapper"]} p-5 w-full`}>
        <div className={`flex flex-col justify-between w-full ${screenSize > 550 &&  'gap-5'} h-full ${styles['plan-wrapper']}`}>
        {<div className={`flex justify-between w-full flex-col sm:flex-row ${screenSize <= 550 &&  'mb-[1.875rem]'}`}>
            <div className={`text-xl capitalize ${styles['heading']}`}>The Art Bank Access Plan</div>
           <div className={`${styles['access-learn']}`}>Learn</div>
                   </div>}
        <div className={`${styles['wrap']} flex justify-between w-full`}>
            {i === 0 ?
                <div className={`${styles['cardmob']} ${styles['cardmobs']}`}>
                    <div className={`${styles['tier']} pb-2`}>Current Tier : <span> None</span></div>
                    <img className='h-[10rem] m-auto' src={AccessPlanImg} alt="Noimg" />

                </div> :
                <div className={`${styles['cardmob']} ${styles['cardmobs']}`}>
                    <Card className={`${styles['card']}`}>
                        <div className={`${styles['tier']} pb-2`}>Current Tier : <span> {accessPlans[i - 1]?.name}</span></div>
                        <img className={`${styles["plan-img"]}`} alt='No Img' src={accessPlans[i - 1]?.imageUrl} />

                    </Card>
                </div>}
            <div className={`w-full flex flex-col items-center justify-center gap-2 access h-full ${screenSize <= 550 &&  'my-[0.625rem]'}`}>
                <div className=''>
                <div className='flex justify-between items-center '>
                    The Art Bank Stake
                </div>
                <div className={`${styles['stake-value']} text-xs text-left`}>{
                    access?.stakedAmountString
                }</div>
                </div>
                <div className='flex w-full  items-start justify-center pt-3 px-2'>
                    <div className={`${styles['min-stake']} `}>{accessPlans[i - 1]?.minimumStakeString ?? 0}</div>

                    <div className='w-full flex flex-col gap-2 items-center '>
                        <progress max="100" value={access?.stakePercent} className='w-full px-4'></progress>
                    </div>

                    <div className={`${styles['min-stake']} `}>{access?.minimumStakeString}</div>
                </div>
                <div className='w-40'>
                    <RoundedButton Name="Stake" Type="7" OnClick={onClickHandler}></RoundedButton>
                </div>
            </div>
            <div className={`${styles['cardmob']} ${styles['cardmobs']}`}>
                <Card className={`${styles['right-card']}`}>
                    <div className={`${styles['tier']} pb-2`}>Next Tier : <span> {access?.name}</span></div>
                    <img className={`${styles["plan-img"]}`} alt='No Img' src={access?.imageUrl} />
                </Card>
            </div>
            
        </div>
        </div>
    </div>
}

export default AccessPlans

