import Popup from "../Popup"
import RoundedButton from "../RoundedButton"

const MessageBox = props => {

    return <div>
        <Popup handleClose={props.onCloseBtnClick}>
            <div className="flex flex-col gap-5 justify-center items-center">
            <div className="p-4">
                <p>{props.children}</p>
            </div>
            <div className="w-20 ">
                <RoundedButton Type='1' OnClick={props.onCloseBtnClick} Name="OK">
                </RoundedButton>
            </div>
            </div>
        </Popup>
    </div>
}

export default MessageBox
