import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from 'App/hooks/use-auth';
const AuthRoute = ({ children }) => {
  const isLoggedIn = useAuth()[0]
  if (isLoggedIn) {
    return <Navigate to="/home" />;
  }
  return children;
}

export default AuthRoute;