import "../Icons.scss";

const FrameIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color rect-stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="17"
          height="19"
          rx="1.5"
          stroke="#A0ABBB"
        />
        <path d="M3 17V3H15V17H3Z" stroke="#A0ABBB" />
      </svg>
    </div>
  );
};

export default FrameIcon;
