import "./ToolTip.scss";

const ToolTip = ({ children, message ,className}) => {
  return (
    <div className={`tooltip-container ${className}`}>
      {children}
      <div className="tooltip-message-wrapper"><div className="tooltip-message">{message}</div></div>
    </div>
  );
};

export default ToolTip;
