import FormatField from "App/Components/Ui/FormatField";
import { useEffect } from "react";
import { useState } from "react";
import GalleryImages from "../../../../Components/Ui/GalleryFrameImages";
import CustomTable from "App/Components/Ui/CustomTable";
import "./GalleryTableView.scss"

const GalleryTableView = (props) => {
    const [listView, setListView] = useState([])

    const onSelectImage = (e) => {
        if (props.onClickAddedNfts) {
            props.onClickAddedNfts(e)
        }
        else if (props.gallery) {
            props.onClickHandler(e)
        }
        else {
            props.onSelect(e)

        }
    }

    useEffect(() => {
        setListView([...props?.list])
    }, [props?.list])

    const tableHeader = [{name:'Name'}]
    return  <div className="pl-6 ml-1 w-full table-view scroller">
                <CustomTable tableHead={tableHeader} tableHeadClass={'tbl-header-asset'}>
                    {listView?.map((gallery, i) => (
                        <tr className="text-left" key={i}>
                            <td className={`${props?.frameClass} table-image cursor-pointer mr-3 flex gap-2`}>
                                <div className={` ${props?.selectedAsset !== undefined ? (gallery?.asset === props?.selectedAsset?.asset && 'active') : ''} selected-new py-2 pr-2`} data-policy={gallery?.policy} data-id={gallery?.asset}
                                    onClick={onSelectImage}>
                                    <GalleryImages nftGalleryNew={true} noFrame={true} galleryPreview={true} index={i} galleryNew={gallery}
                                        src={gallery?.image} />
                                </div >
                                <div className="list-name-wid">
                                    {gallery?.name?.length >= 8 ?
                                        <FormatField type="substring" maxLength={19} startLength={12} endLength={7} value={gallery?.name} />
                                        : gallery?.name
                                    }</div>
                            </td>
                        </tr>
                    ))}
                </CustomTable>
            </div>
}

export default GalleryTableView
