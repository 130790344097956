import "./AuthPop.scss";
import RoundedButton from "../RoundedButton";
import { useNavigate } from "react-router-dom";
import ImagePopup from "../ImagePopup";
import { useStore } from 'App/hooks-store/store'
import { useCallback, useState } from "react";
import { useEffect } from "react";
import useHttp from "App/hooks/use-http";
const AuthPop = ({className, show = false, onTrialClick, onClose, title = 'View your collections and save your curated galleries'}) => {  
    const navigate = useNavigate()
    const {triggerAPI} = useHttp();
    const [{ currentWallet }] = useStore(false);
    const [registrationStatus,setRegistrationStatus] = useState(true)
    const [showTrial, setShowTrial] = useState(true);
    useEffect(() => {
        if(currentWallet?.address) {
            setShowTrial(false)
        } else {
            setShowTrial(true)
        }
    },[currentWallet?.address])
    const handleSignIn = () => {       
        onClose(false);
        navigate("/auth/sign-in")
    }
    const handleRegister = () => {        
        onClose(false);
        navigate("/auth/register")
    }
    const handleTrial = (e) => {
        onTrialClick();
        onClose(false);
    }
    const getRegistrationStatusResponse = useCallback(
        (res) => {
          setRegistrationStatus(res?.data?.allowNewRegistration);
        },
        []
      );
    
      const getRegistrationStatus = useCallback(() => {
        triggerAPI(
          {
            url: `app-preference/get`,
            method: "get",
          },
          getRegistrationStatusResponse
        );
      }, [getRegistrationStatusResponse, triggerAPI]);
      useEffect(() => {
        getRegistrationStatus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    return (
        <ImagePopup show={{mode: show} } onClose={onClose} cssClass={'auth-pop'}>        
            <div className="flex flex-col gap-4">
                <div>
                    <p className="auth-label">{title}</p>
                </div>
                <div className="flex items-center justify-center gap-4">
                    <div>
                        <RoundedButton OnClick={handleSignIn} className={'auth-button'} Type="3" Name={'Sign In'}></RoundedButton>
                    </div>
                    {!!registrationStatus&&<div>
                        <RoundedButton OnClick={handleRegister} className={'auth-button register'} Type="3" Name={'Register'}></RoundedButton>
                    </div>}
                </div>
                {showTrial && 
                    <>
                         <div className="mt-[2.625rem]">
                            <p>Trial Cur8 before registering</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <div>
                                <RoundedButton OnClick={handleTrial} className={'auth-button'} Type="3" Name={'Trial'}></RoundedButton>
                            </div>
                        </div>
                    </>
                }
                
            </div>
        </ImagePopup>
    )
}
export default AuthPop;