import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "App/hooks/use-http";
import styles from "./DisplayMyGalleries.scss";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { useStore } from "App/hooks-store/store";
import Card from "App/Components/Card";

const DisplayMyGalleries = (props) => {              
    return (
        <div className="display-gallery-list">
            {props?.galleryList?.map((info, i) => {
                info = { ...info, createdAt: info?.nftDropStartedAt }
                return (
                    <Fragment key={i}>
                        <div key={i} data-id={info?.id} className={`cursor-pointer w-[100%] ${info?.id==props?.activeGalleryId ? 'gallery-selected':''}`} onClick={(e)=> { props?.gallerySelected(info.id)}}>
                            <Card
                                key={i}
                                cardProp={info}
                                type="Gallery"
                            /></div>

                    </Fragment>)
            })}                             
        </div>
    );
};

export default DisplayMyGalleries;