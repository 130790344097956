import './GalleryCardEdit.scss';
import RoundedButton from 'App/Components/Ui/RoundedButton';

const GalleryCardEdit = (props) => {
    return (
        <>
            <div className="content-details fadeIn-left">
                <div className='cursor-pointer'>
                    <p>1 or more assets unavailable. Please edit gallery</p>
                </div>
                <div className="edit-selection flex justify-center items-center">
                    <div>
                        <RoundedButton className="edit-button" Name="Edit" Type="6"  OnClick={(e) => props?.handleEdit(e, props?.galleryID)}></RoundedButton>
                    </div>
                </div>
            </div>
         </>
    )
}

export default GalleryCardEdit;