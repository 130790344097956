import { useGLTF, useTexture } from '@react-three/drei';
import { useSelector,useDispatch } from 'react-redux'

import * as THREE from 'three';
import MetaGalleryFrame from '../Component/MetaGalleryFrame';
import { setframepositions,settotalnfts } from '../Component/MetaGalleryState';

const MetaGallery2 = ({ galleryList, captionControl }) => {  
  const dispatch = useDispatch(); 

  const colorMap = useTexture('/metagalleryassets/textures/wall3.jpg')
  colorMap.repeat.set(2,2);
  colorMap.wrapS=THREE.RepeatWrapping;
  colorMap.wrapT=THREE.RepeatWrapping;

  const { nodes, materials } = useGLTF('/metagalleryassets/models/gallery3-transformed.glb')    
  materials['window glass'].transparent = true;  
  materials['window glass'].opacity = 0.1;
  materials['glass edge'].transparent = true;
  materials['glass edge'].opacity = 0.1;
  let framepositions = useSelector((state) => state.appState.framepositions.payload)??[];      
  
  if (framepositions.length === 0)
  {         
    framepositions.push(      
      [[-4.85, 2, 7.5],[-3.35, 2, 7.5]],
      [[-4.85, 2, 4],[-3.35, 2, 4]],
      [[-4.85, 2, 0.5],[-3.35, 2, 0.5]],
      [[4.85, 2, -0.5],[3.35, 2, -0.5]],      
      [[4.85, 2, 7],[3.35, 2, 7]]      
    );

    dispatch(setframepositions(framepositions));    
  }

  if (galleryList.length < 10)
  {
    let len = galleryList.length;
    let j = -1;
    for (let i = len; i < 11; i++) {
      if (j === galleryList.length)
      {
        j = 0;
      }
      else 
      {
        j++;
      }
      galleryList.push(galleryList[j]);    
    }
  }
  //setnfts(galleryList);      
  dispatch(settotalnfts(galleryList.length));      


  let index = useSelector((state) => state.appState.index.payload)??0;
  let isviewingframe = useSelector((state) => state.appState.isviewingframe.payload)??0;     

  return (    
    <group dispose={null}>      

{galleryList.length>0 && <>   
{/* 
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.galleryunitybare002_1.geometry} receiveShadow>
          <meshStandardMaterial map={colorMap}/>
        </mesh>
        <mesh geometry={nodes.galleryunitybare002_2.geometry} receiveShadow>
          <meshStandardMaterial map={colorMap}/>
        </mesh>
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.galleryunitybareprops001_1.geometry} material={materials['TrackLightingA1_Emmisive_MAT.004']} />
        <mesh geometry={nodes.galleryunitybareprops001_2.geometry} material={materials['TrackLightingA1_MAT.004']} />        
      </group>            
                                         */}

    <ambientLight intensity={2.4} />
    <spotLight penumbra={0.1} position={[0, 25, 0]} intensity={1000} />            

    <group dispose={null} rotation={[0, 0, 0]} position={[-1.23,0.5,11]} scale={[1,1,1]}>
    <mesh geometry={nodes.ceiling_arcade_rear_cove.geometry} material={materials['ceiling cove']} position={[-6.9, 3.6, -15.025]} />
      <mesh geometry={nodes.lights_arcade_left.geometry} material={materials['ceiling light emission']} position={[-2.157, 3.5, -1.7]} />
      <mesh geometry={nodes.lights_arcade_right.geometry} material={materials['ceiling light emission']} position={[4.551, 3.5, -1.7]} />
      <mesh geometry={nodes.ceiling_arcade_left_cove.geometry} material={materials['ceiling cove']} position={[-2.7, 3.6, -0.2]} />
      <mesh geometry={nodes.lights_arcade_rear.geometry} material={materials['ceiling light emission']} position={[-1.657, 3.5, -13.64]} />
      <mesh geometry={nodes.ceiling_arcade_right_cove.geometry} material={materials['ceiling cove']} position={[3.375, 3.6, -0.2]} />
      <mesh geometry={nodes.lights_gallery.geometry} material={materials['ceiling light emission']} position={[7.314, 3.3, -1.763]} />
      <mesh geometry={nodes.wdw001.geometry} material={materials['window glass']} position={[0.25, 0.15, -15.256]} />
      <group position={[1.203, 0.9, -5.552]} scale={[1,0.5,1]}>
        <mesh geometry={nodes.Cube009.geometry} material={materials['window glass']} />
        <mesh geometry={nodes.Cube009_1.geometry} material={materials['glass edge']} />
      </group>
      <mesh geometry={nodes.skylight_glass_1.geometry} material={materials['window glass']} position={[-0.224, 5.822, -1.375]} />
      <mesh geometry={nodes.skylight_glass_2.geometry} material={materials['window glass']} position={[1.226, 6.197, -1.375]} />
      <mesh geometry={nodes.wdw_Baked.geometry} material={materials.wdw_Baked} position={[0.25, 0.15, -15.256]} />
      <mesh geometry={nodes.wall_gallery_arch_front_Baked.geometry} material={materials['wall gallery arch front_Baked']} position={[5.903, 2.1, -0.375]} />
      <mesh geometry={nodes.wall_gallery_arch_right_Baked.geometry} material={materials['wall gallery arch right_Baked']} position={[9.225, 2.1, -3.2]} />
      <mesh geometry={nodes.doors_Baked.geometry} material={materials.doors_Baked} position={[0.2, 0, 3.15]} />
      <mesh geometry={nodes.wall_entrance_Baked.geometry} material={materials['wall entrance_Baked.002']} position={[0.2, 2.4, 3.1]} />
      <mesh geometry={nodes.wall_foyer_front_Baked.geometry} material={materials['wall foyer front_Baked.002']} position={[2.6, 0, 3.1]} rotation={[0, 1.571, 0]} />
      <mesh geometry={nodes.wall_foyer_left_Baked.geometry} material={materials['wall foyer left_Baked.002']} position={[-0.825, 0, 6.2]} />
      <mesh geometry={nodes.wall_foyer_right_Baked.geometry} material={materials['wall foyer right_Baked.002']} position={[3.025, 0, 6.2]} />
      <mesh geometry={nodes.wall_gallery_front_Baked.geometry} material={materials['wall gallery front_Baked.002']} position={[6.5, 0, -0.1]} rotation={[0, 1.571, 0]} />
      <mesh geometry={nodes.wall_gallery_right_Baked.geometry} material={materials['wall gallery right_Baked.002']} position={[9.3, 0, -2.8]} />
      <mesh geometry={nodes.wall_main_front_1_Baked.geometry} material={materials['wall main front 1_Baked.002']} position={[0.6, 0, -0.1]} rotation={[0, 1.571, 0]} />
      <mesh geometry={nodes.wall_main_left_2_Baked.geometry} material={materials['wall main left 2_Baked.003']} position={[-2.9, 0, 0]} />
      <mesh geometry={nodes.wall_main_right_2_Baked.geometry} material={materials['wall main right 2_Baked.003']} position={[5.1, 0, -3]} />
      <mesh geometry={nodes.wall_main_right_1_Baked.geometry} material={materials['wall main right 1_Baked.003']} position={[5.1, 0, 0]} />
      <mesh geometry={nodes.wall_main_rear_Baked.geometry} material={materials['wall main rear_Baked.003']} position={[-2.7, 0, -15.4]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.wall_main_left_1_Baked.geometry} material={materials['wall main left 1_Baked.003']} position={[-2.9, 0, 0]} />
      <mesh geometry={nodes.wall_gallery_rear_Baked.geometry} material={materials['wall gallery rear_Baked.003']} position={[5.1, 0, -3]} />
      <mesh geometry={nodes.col_b2_Baked.geometry} material={materials['col b2_Baked']} position={[3, 0, -3]} />
      <mesh geometry={nodes.col_c2_Baked.geometry} material={materials['col c2_Baked']} position={[3, 0, -6]} />
      <mesh geometry={nodes.col_d2_Baked.geometry} material={materials['col d2_Baked']} position={[3, 0, -9]} />
      <mesh geometry={nodes.col_e2_Baked.geometry} material={materials['col e2_Baked']} position={[3, 0, -12]} />
      <mesh geometry={nodes.col_a1_Baked.geometry} material={materials['col a1_Baked']} />
      <mesh geometry={nodes.col_a2_Baked.geometry} material={materials['col a2_Baked']} position={[2, 0, 0]} />
      <mesh geometry={nodes.col_b1_Baked.geometry} material={materials['col b1_Baked']} position={[-1, 0, -3]} />
      <mesh geometry={nodes.col_c1_Baked.geometry} material={materials['col c1_Baked']} position={[-1, 0, -6]} />
      <mesh geometry={nodes.col_d1_Baked.geometry} material={materials['col d1_Baked']} position={[-1, 0, -9]} />
      <mesh geometry={nodes.col_e1_Baked.geometry} material={materials['col e1_Baked']} position={[-1, 0, -12]} />
      <mesh geometry={nodes.arch_a1_Baked.geometry} material={materials['arch a1_Baked']} position={[-0.95, 2.1, -0.2]} />
      <mesh geometry={nodes.arch_a2_Baked.geometry} material={materials['arch a2_Baked']} position={[3.05, 2.1, -0.2]} />
      <mesh geometry={nodes.arch_a_Baked.geometry} material={materials['arch a_Baked']} position={[0.2, 2.1, -0.025]} />
      <mesh geometry={nodes.arch_b1_Baked.geometry} material={materials['arch b1_Baked']} position={[-0.95, 2.1, -3.2]} />
      <mesh geometry={nodes.arch_c1_Baked.geometry} material={materials['arch c1_Baked']} position={[-0.95, 2.1, -6.2]} />
      <mesh geometry={nodes.arch_b2_Baked.geometry} material={materials['arch b2_Baked']} position={[3.05, 2.1, -3.2]} />
      <mesh geometry={nodes.arch_c2_Baked.geometry} material={materials['arch c2_Baked']} position={[3.05, 2.1, -6.2]} />
      <mesh geometry={nodes.arch_d1_Baked.geometry} material={materials['arch d1_Baked']} position={[-0.95, 2.1, -9.2]} />
      <mesh geometry={nodes.arch_d2_Baked.geometry} material={materials['arch d2_Baked']} position={[3.05, 2.1, -9.2]} />
      <mesh geometry={nodes.beam_Baked.geometry} material={materials.beam_Baked} position={[-2.7, 3.2, -12.05]} />
      <mesh geometry={nodes.floor_arcade_left_1_Baked.geometry} material={materials['floor arcade left 1_Baked']} position={[-2.9, 0, -1.1]} />
      <mesh geometry={nodes.floor_arcade_left_2_Baked.geometry} material={materials['floor arcade left 2_Baked']} position={[-2.9, 0, -7.2]} />
      <mesh geometry={nodes.floor_arcade_rear_Baked.geometry} material={materials['floor arcade rear_Baked']} position={[-2.9, 0, -13.2]} />
      <mesh geometry={nodes.floor_arcade_right_1_Baked.geometry} material={materials['floor arcade right 1_Baked']} position={[4.025, 0, -1.1]} />
      <mesh geometry={nodes.floor_arcade_right_2_Baked.geometry} material={materials['floor arcade right 2_Baked']} position={[4.025, 0, -7.2]} />
      <mesh geometry={nodes.floor_entry_Baked.geometry} material={materials['floor entry_Baked']} position={[0.375, 0, 5]} />
      <mesh geometry={nodes.floor_gallery_1_Baked.geometry} material={materials['floor gallery 1_Baked']} position={[7.1, 0, -4.25]} />
      <mesh geometry={nodes.floor_gallery_2_Baked.geometry} material={materials['floor gallery 2_Baked']} position={[7.1, 0, -4.25]} />
      <mesh geometry={nodes.floor_main_1_Baked.geometry} material={materials['floor main 1_Baked']} position={[0.375, 0, -1.1]} />
      <mesh geometry={nodes.floor_main_2_Baked.geometry} material={materials['floor main 2_Baked']} position={[0.375, 0, -7.2]} />
      <mesh geometry={nodes.moulding_Baked.geometry} material={materials.moulding_Baked} position={[-0.625, 3.95, -12.05]} />
      <mesh geometry={nodes.wall_clerestory_front_Baked.geometry} material={materials['wall clerestory front_Baked.002']} position={[-0.925, 3.7, -0.375]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.wall_clerestory_left_Baked.geometry} material={materials['wall clerestory left_Baked.002']} position={[-0.825, 3.7, -0.275]} />
      <mesh geometry={nodes.wall_clerestory_rear_Baked.geometry} material={materials['wall clerestory rear_Baked.002']} position={[-0.925, 3.7, -12.25]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.wall_clerestory_right_Baked.geometry} material={materials['wall clerestory right_Baked.002']} position={[3.025, 3.7, -0.275]} />
      <mesh geometry={nodes.ceiling_arcade_left_Baked.geometry} material={materials['ceiling arcade left_Baked']} position={[-2.7, 3.5, -0.2]} />
      <mesh geometry={nodes.ceiling_arcade_rear_Baked.geometry} material={materials['ceiling arcade rear_Baked']} position={[-6.9, 3.5, -15.025]} />
      <mesh geometry={nodes.ceiling_arcade_right_Baked.geometry} material={materials['ceiling arcade right_Baked']} position={[3.375, 3.5, -0.2]} />
      <mesh geometry={nodes.ceiling_foyer_Baked.geometry} material={materials['ceiling foyer_Baked']} position={[-0.7, 3.3, 2.9]} />
      <mesh geometry={nodes.ceiling_gallery_Baked.geometry} material={materials['ceiling gallery_Baked']} position={[5.3, 3.3, -0.3]} />
      <mesh geometry={nodes.roof_arcade_left_Baked.geometry} material={materials['roof arcade left_Baked']} position={[-2.7, 3.7, -0.2]} />
      <mesh geometry={nodes.roof_arcade_rear_Baked.geometry} material={materials['roof arcade rear_Baked']} position={[-6.9, 3.7, -15.025]} />
      <mesh geometry={nodes.roof_arcade_right_Baked.geometry} material={materials['roof arcade right_Baked']} position={[3.375, 3.7, -0.2]} />
      <mesh geometry={nodes.roof_gallery_Baked.geometry} material={materials['roof gallery_Baked']} position={[5.3, 3.6, -0.3]} />
      <mesh geometry={nodes.display_stand_1_Baked.geometry} material={materials['display stand 1_Baked']} position={[1.203, 0, -4.436]} />      
      <mesh geometry={nodes.roof_frame_long_Baked.geometry} material={materials['roof frame long_Baked']} position={[1.175, 6.064, -11.648]} />
      <mesh geometry={nodes.roof_frame_short_Baked.geometry} material={materials['roof frame short_Baked']} position={[-1.203, 5.44, -1.375]} />
      <mesh geometry={nodes.roof_Baked.geometry} material={materials.roof_Baked} position={[0.094, 6.214, -6.932]} />
      <mesh geometry={nodes.Plane.geometry} material={materials.garden} position={[1.204, -1.671, -18.87]} rotation={[Math.PI / 2, 0, 0]} scale={[5.94, 5.94, 3.827]} />
      <mesh geometry={nodes.Plane001.geometry} material={materials.sky} position={[1.188, 7.186, -8.88]} scale={[6.067, 6.067, 10.99]} />


      
    </group>
    

         <MetaGalleryFrame position={framepositions[0][0]} nft={galleryList[0]} rotation={[0,Math.PI,0]} renderonchain={index===0&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

  <MetaGalleryFrame position={framepositions[1][0]} nft={galleryList[1]} rotation={[0,Math.PI,0]} renderonchain={index===1&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>
         
       <MetaGalleryFrame position={framepositions[2][0]} nft={galleryList[2]} rotation={[0,Math.PI,0]} renderonchain={index===2&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>


          <MetaGalleryFrame position={framepositions[3][0]} nft={galleryList[3]} renderonchain={index===3&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

         <MetaGalleryFrame position={framepositions[4][0]} nft={galleryList[4]} renderonchain={index===4&&isviewingframe} captionshow={captionControl}>

</MetaGalleryFrame>

</>}
        
  </group>           
  );
};

export default MetaGallery2;
