import styles from "./ResetPassword.module.scss";
import UserAvatar from 'Assets/Icons/userAvatars.svg'
import passwordIcon from 'Assets/Icons/password.svg'
import RoundedInput from '../../../../Components/Ui/RoundedInput'
import RoundedButton from "App/Components/Ui/RoundedButton";
import { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import Validators from 'App/Components/Ui/Forms/Validators'
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";

function ResetPassword() {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        newPassword: {
            value: undefined,
            isRequired: true
        },
        confirmPassword: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup));

    const [searchParams] = useSearchParams();

    const newPasswordValidatorsList = useMemo(() => ([{
        type: 'Required',
        message: 'Required field'
      }, {
        type: 'Password',
        message: 'Password is not strong enough'
      }]), []);

    //Reset password Api
    const resetPasswordResponse = useCallback((res) => {
        dispatch('hideSpinner');
        navigate("/auth/sign-in")
        dispatch('showToast', { toast: { toastMode: 'success', message: "Reset Successful" } })
    }, [dispatch, navigate])
    const resetPassword = () => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/reset-password`, data: { token: searchParams.get("token"), password: formValues.newPassword }, method: 'post'
        }, resetPasswordResponse,()=>{
            dispatch("hideSpinner");
        });
    }

    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }

    const submitHandler = e => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        e.preventDefault();
        if (valid)
            resetPassword()
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])


    return (
        <div className={`w-full max-w-xs justify-items-center ${styles['reset-pass']}`}>
            <img alt='NoImg' className='auth-avatar' src={UserAvatar} />
            <form className="flex flex-col gap-2.5 rounded px-8 pt-6 pb-8 mb-4" onSubmit={submitHandler}>
                <div>
                    <RoundedInput className="min-w-[14.45rem]" Placeholder="New Password" icon={passwordIcon} label="newPassword" validateOnBlur controlName="newPassword" ChangeFunction={inputOnChange} type="password"
                    ></RoundedInput>
                    <Validators type="Password" value={formValues.newPassword} controlName="newPassword" onErrors={collectErrors} validators={newPasswordValidatorsList}/>
                </div>
                <div>
                    <RoundedInput className="min-w-[14.45rem]" Placeholder="Confirm Password" icon={passwordIcon} label="confirmPassword" validateOnBlur controlName="confirmPassword" ChangeFunction={inputOnChange} type="password"
                    ></RoundedInput>
                    <Validators type="Match" value={formValues.confirmPassword} matchValue={formValues.newPassword} message="Password and confirm password should be same" controlName="confirmPassword" onErrors={collectErrors} />
                    {/* <Validators type="Password" value={formValues.confirmPassword} message="Password is not strong enough" controlName="confirmPassword" onErrors={collectErrors} />
                    <Validators type="Required" value={formValues.confirmPassword} message="Required field" controlName="confirmPassword" onErrors={collectErrors} /> */}
                </div>
                <div className={`flex items-center justify-between mt-5 ${styles['reset-pass-button']}`}>
                    <RoundedButton OnClick={submitHandler} Name="Reset Password" Type="2"></RoundedButton>
                </div>
            </form>
        </div>
    );
}

export default ResetPassword