import "../Icons.scss";

const UserGroupIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99628 8.5343C7.48711 8.5343 6.98937 8.38331 6.56602 8.10043C6.14266 7.81756 5.81269 7.41549 5.61784 6.94508C5.42299 6.47467 5.37201 5.95704 5.47134 5.45766C5.57068 4.95827 5.81586 4.49956 6.1759 4.13952C6.53594 3.77949 6.99465 3.5343 7.49404 3.43497C7.99342 3.33563 8.51105 3.38661 8.98146 3.58146C9.45187 3.77631 9.85393 4.10628 10.1368 4.52964C10.4197 4.953 10.5707 5.45073 10.5707 5.9599C10.5699 6.64244 10.2984 7.2968 9.81581 7.77943C9.33318 8.26206 8.67882 8.53354 7.99628 8.5343ZM7.99628 4.24118C7.65635 4.24118 7.32405 4.34198 7.04141 4.53084C6.75876 4.71969 6.53847 4.98812 6.40839 5.30217C6.2783 5.61623 6.24426 5.96181 6.31058 6.2952C6.3769 6.6286 6.54059 6.93485 6.78096 7.17522C7.02133 7.41559 7.32757 7.57928 7.66097 7.64559C7.99437 7.71191 8.33995 7.67788 8.654 7.54779C8.96806 7.4177 9.23648 7.19741 9.42534 6.91477C9.6142 6.63213 9.715 6.29983 9.715 5.9599C9.71449 5.50422 9.53325 5.06735 9.21103 4.74514C8.88882 4.42293 8.45195 4.24169 7.99628 4.24118Z"
          fill="#A0ABBB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0093 8.42667C12.6275 8.4268 12.2541 8.31367 11.9366 8.1016C11.619 7.88953 11.3714 7.58805 11.2252 7.23528C11.079 6.8825 11.0407 6.4943 11.1152 6.11975C11.1896 5.74521 11.3735 5.40116 11.6435 5.13111C11.9135 4.86107 12.2575 4.67716 12.632 4.60266C13.0065 4.52815 13.3948 4.56639 13.7476 4.71254C14.1003 4.85869 14.4019 5.10618 14.614 5.42372C14.8261 5.74125 14.9393 6.11456 14.9392 6.49643C14.9387 7.00813 14.7351 7.4987 14.3734 7.86055C14.0116 8.2224 13.521 8.426 13.0093 8.42667ZM13.0093 5.42155C12.7967 5.42149 12.5889 5.48448 12.4121 5.60255C12.2353 5.72062 12.0974 5.88847 12.016 6.08487C11.9346 6.28128 11.9133 6.49741 11.9547 6.70594C11.9962 6.91446 12.0985 7.10602 12.2488 7.25637C12.3992 7.40673 12.5907 7.50914 12.7992 7.55064C13.0077 7.59214 13.2238 7.57087 13.4203 7.48952C13.6167 7.40818 13.7846 7.27041 13.9027 7.09364C14.0208 6.91687 14.0839 6.70904 14.0839 6.49643C14.0836 6.21149 13.9703 5.93829 13.7689 5.73677C13.5674 5.53526 13.2943 5.42189 13.0093 5.42155Z"
          fill="#A0ABBB"
        />
        <path
         fillRule="evenodd"
         clipRule="evenodd"
          d="M2.99338 8.42667C2.61153 8.42674 2.23823 8.31356 1.9207 8.10145C1.60317 7.88934 1.35568 7.58783 1.20952 7.23505C1.06336 6.88228 1.0251 6.49408 1.09958 6.11956C1.17406 5.74504 1.35794 5.40101 1.62795 5.131C1.89796 4.86099 2.24198 4.67711 2.61651 4.60263C2.99103 4.52815 3.37923 4.56641 3.732 4.71257C4.08478 4.85873 4.38629 5.10622 4.5984 5.42375C4.8105 5.74128 4.92368 6.11458 4.92362 6.49643C4.92303 7.00818 4.71947 7.4988 4.35761 7.86066C3.99575 8.22253 3.50513 8.42608 2.99338 8.42667ZM2.99338 5.42155C2.78079 5.42155 2.57297 5.48459 2.39621 5.6027C2.21945 5.72081 2.08168 5.88868 2.00032 6.08509C1.91897 6.2815 1.89768 6.49763 1.93915 6.70613C1.98063 6.91464 2.083 7.10616 2.23333 7.25649C2.38365 7.40681 2.57518 7.50918 2.78368 7.55066C2.99219 7.59213 3.20831 7.57085 3.40472 7.48949C3.60113 7.40814 3.769 7.27037 3.88711 7.0936C4.00522 6.91684 4.06826 6.70902 4.06826 6.49643C4.06792 6.21146 3.95457 5.93826 3.75306 5.73675C3.55156 5.53525 3.27835 5.42189 2.99338 5.42155Z"
          fill="#A0ABBB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5751 13.6146C11.4616 13.6144 11.3529 13.5693 11.2727 13.4891C11.1925 13.4089 11.1474 13.3002 11.1472 13.1868C11.1472 12.3514 10.8154 11.5502 10.2247 10.9595C9.63399 10.3688 8.83284 10.037 7.99747 10.037C7.1621 10.037 6.36095 10.3688 5.77025 10.9595C5.17956 11.5502 4.84771 12.3514 4.84771 13.1868C4.84927 13.2439 4.83937 13.3008 4.81858 13.354C4.79779 13.4073 4.76653 13.4559 4.72666 13.4968C4.6868 13.5378 4.63912 13.5704 4.58645 13.5926C4.53379 13.6148 4.4772 13.6263 4.42003 13.6263C4.36286 13.6263 4.30627 13.6148 4.2536 13.5926C4.20093 13.5704 4.15326 13.5378 4.11339 13.4968C4.07352 13.4559 4.04227 13.4073 4.02148 13.354C4.00069 13.3008 3.99078 13.2439 3.99235 13.1868C3.99235 12.1245 4.41431 11.1058 5.16542 10.3547C5.91653 9.60361 6.93524 9.18164 7.99747 9.18164C9.05969 9.18164 10.0784 9.60361 10.8295 10.3547C11.5806 11.1058 12.0026 12.1245 12.0026 13.1868C12.0025 13.3001 11.9574 13.4089 11.8773 13.4891C11.7971 13.5693 11.6885 13.6144 11.5751 13.6146Z"
          fill="#A0ABBB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5147 12.4341C15.4013 12.4339 15.2926 12.3888 15.2125 12.3086C15.1324 12.2283 15.0873 12.1196 15.0872 12.0062C15.0871 11.5388 14.9292 11.0851 14.6393 10.7185C14.3493 10.3519 13.9441 10.0938 13.4893 9.98605C13.0344 9.87828 12.5565 9.92709 12.1329 10.1246C11.7092 10.3221 11.3646 10.6567 11.1547 11.0744C11.1311 11.1271 11.097 11.1745 11.0545 11.2137C11.012 11.2529 10.962 11.2831 10.9076 11.3025C10.8531 11.3219 10.7953 11.33 10.7376 11.3264C10.6799 11.3229 10.6236 11.3077 10.5719 11.2817C10.5203 11.2558 10.4744 11.2196 10.4371 11.1755C10.3998 11.1313 10.3718 11.0801 10.3548 11.0248C10.3378 10.9696 10.3322 10.9115 10.3383 10.854C10.3444 10.7965 10.3621 10.7409 10.3902 10.6904C10.6868 10.1008 11.1735 9.62842 11.7718 9.34973C12.37 9.07103 13.0448 9.00229 13.6869 9.15463C14.3291 9.30696 14.9011 9.67146 15.3104 10.1892C15.7198 10.7069 15.9425 11.3475 15.9426 12.0075C15.9421 12.1207 15.8968 12.2291 15.8166 12.309C15.7365 12.389 15.6279 12.4339 15.5147 12.4341Z"
          fill="#A0ABBB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.49034 12.4341C0.376922 12.434 0.268197 12.3888 0.187998 12.3086C0.107799 12.2284 0.0626693 12.1197 0.0625 12.0063C0.0625916 11.3463 0.285311 10.7057 0.694637 10.1879C1.10396 9.67024 1.67596 9.30574 2.31812 9.1534C2.96028 9.00107 3.63505 9.06981 4.2333 9.34851C4.83155 9.6272 5.3183 10.0996 5.61482 10.6892C5.66562 10.791 5.67394 10.9089 5.63794 11.0169C5.60195 11.1249 5.52458 11.2142 5.42282 11.2652C5.36331 11.2953 5.29751 11.3109 5.23082 11.3106C5.1512 11.3111 5.07303 11.2894 5.00516 11.2477C4.9373 11.2061 4.88244 11.1463 4.84682 11.0751C4.63598 10.6594 4.29142 10.3267 3.86859 10.1306C3.44576 9.93442 2.96924 9.88622 2.51569 9.99371C2.06214 10.1012 1.65795 10.3582 1.36812 10.7232C1.0783 11.0883 0.919708 11.5402 0.91786 12.0063C0.917775 12.1197 0.872716 12.2284 0.792568 12.3086C0.71242 12.3888 0.603729 12.434 0.49034 12.4341Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default UserGroupIcon;
