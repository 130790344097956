const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setFrameAsset: ({frameAssets:currentFrames=[]}, frames = []) => {
            return {
                frameAssets: [...currentFrames,...frames]
            }
        }
    }
    initStore(actions, {
        frameAssets: []
    });
}
export default configureStore;