import userAvatar from "Assets/Icons/logo-square.svg";
import anywallImage from "Assets/Icons/anywall-logo-image.png";

const Logo = () => {
  return process.env.REACT_APP_COMPANY === "anywall"
    ? anywallImage
    : userAvatar;
};

export default Logo;
