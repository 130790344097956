import styles from './MyVerifiedNFTPolicies.module.scss'
import { useEffect, useState, useCallback } from 'react'
import EditPolicyPanel from './Components/EditPolicyPanel'
import ViewPolicyPanel from './Components/ViewPolicyPanel'
import AddPolicePanel from './Components/AddPolicyPanel'
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'

const MyVerifiedNFTPolicies = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [formVaildState, setFormVaildState] = useState({});
    const [policies, setPolicies] = useState([])
    const [editPolicies, setEditPolicies] = useState(-1)
    const [stylesList, setStylesList] = useState([])
    const formGroup = {
        policyId: {
            value: undefined,
            isRequired: true
        },
        name: {
            value: undefined,
            isRequired: true
        },
        email: {
            value: undefined,
            isRequired: false
        },
        description: {
            value: undefined,
            isRequired: false
        },
        styleId: {
            value: undefined,
            isRequired: false
        },
        twitter: {
            value: undefined,
            isRequired: true
        },
        discord: {
            value: undefined,
            isRequired: false
        },
        website: {
            value: undefined,
            isRequired: false
        }
    }
    const [currentEditPolicy, setCurrentEditPolicy] = useState(getInitial(formGroup))
    const policyAddHandler = () => {
        setEditPolicies(0)
        setCurrentEditPolicy({
            policyId: undefined,
            name: undefined,
            description: "",
            styleId: stylesList?.options[0].value,
            twitter: undefined,
            discord: "",
            website: "",
            email: ""
        })
        const newPolicy = {
            key: Date.now(),
            policyId: "",
            name: "",
            description: "",
            styleId: "",
            twitter: "",
            discord: "",
            website: "",
            email: "",
            status: 0
        }
        setPolicies((prevState) => {
            return [newPolicy, ...prevState]
        })
    }

    useEffect(() => {
        getStylesList()
        dispatch("setPageTitle", "My Verified NFT Policies")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getSavedPolicies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stylesList])

    //Styles list API
    const getStylesListResult = useCallback((res) => {
        let data = res.data.list
        let options = data.map(style => { return { id: style.id, value: style.name } })
        setStylesList({ options: options })
    }, [setStylesList])
    const getStylesList = (params) => {
        const data = new URLSearchParams({
            page: 1,
            items: 5,
        })
        triggerAPI({
            url: `user/nft-policy/style?${data}`, method: 'get'
        }, getStylesListResult);
    }

    //Saved policies populate API
    const getSavedPoliciesResult = useCallback((res) => {
        dispatch('hideSpinner');
        const data = res.data.map(e => { return { ...e, key: e.id, styleId: e.style.name } })
        setPolicies(data)
    }, [dispatch])
    const getSavedPolicies = useCallback(() => {
        const data = new URLSearchParams({
            page: 1,
            items: 5
        })
        dispatch('showSpinner');
        triggerAPI({
            url: `user/nft-policy/get?${data}`, method: 'get'
        }, getSavedPoliciesResult);
    }, [dispatch, getSavedPoliciesResult, triggerAPI])

    //Update Policies API
    const updatePolicyResponse = useCallback((res) => {
        dispatch('hideSpinner');
        getSavedPolicies()
        setEditPolicies(-1)
        // dispatch('showToast', { toast: { toastMode: 'success', message: "Policy created successfully" } })
    }, [dispatch, getSavedPolicies])
    const updatePolicyHandler = useCallback(() => {

        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: currentEditPolicy });
        if (valid) {
            const data = currentEditPolicy
            delete data['key']
            dispatch('showSpinner')
            triggerAPI({
                url: `user/nft-policy/add`, data: { ...data, styleId: getIdByName(stylesList.options, data.styleId) }, method: 'post'
            }, updatePolicyResponse,()=>{
                dispatch("hideSpinner");
            });
        }
        else {
            const newValues = markAsTouched(currentEditPolicy);
            setCurrentEditPolicy(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, updatePolicyResponse, triggerAPI, currentEditPolicy, formGroup, formVaildState])

    //Input change handler
    const inputOnChange = (e) => {
        setCurrentEditPolicy((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }

    //Form error Handler
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    //Delete policy API
    const deletePolicyResponse = useCallback((res) => {
        dispatch('hideSpinner');
        getSavedPolicies()
        setEditPolicies(-1)
        // dispatch('showToast', { toast: { toastMode: 'success', message: "Policy deleted successfully" } })
    }, [dispatch, getSavedPolicies])
    const deletePolicyHandler = useCallback(id => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/nft-policy/delete`, data: { id: id }, method: 'delete'
        }, deletePolicyResponse,()=>{
            dispatch("hideSpinner");
        });
    }, [dispatch, deletePolicyResponse, triggerAPI])

    //Function to get id value of string
    const getIdByName = (arr, value) => {
        let id = null
        for (let items of arr) {
            if (items.value === value)
                id = items.id
        }
        return id
    }
    useEffect(() => {
        if (editPolicies === 0) {
            const element = document.getElementById('outerDiv');
            if (element) {
                setTimeout(() => {
                    element.scroll({ behavior: 'smooth', top: 0 })
                }, 500)
            }
        }
    }, [editPolicies])
    return (
        <div className={`${styles['my-NFT']} w-full`} id='outerDiv'>
            <AddPolicePanel editPolicies={editPolicies} onAddButtonClick={policyAddHandler}></AddPolicePanel>
            <div className='pt-[1rem]'>
                <div className={`${styles['scroll-box']} gap-2.5 flex flex-col h-full`}>
                    {policies.map((policy, index) => (
                        <div key={policy.key} className={`${styles['policy-form']}`}>
                            {editPolicies === index && <EditPolicyPanel stylesList={stylesList} currentEditPolicy={currentEditPolicy} onUpdateButtonClick={updatePolicyHandler} onInputChange={inputOnChange} collectErrors={collectErrors}></EditPolicyPanel>}
                            {editPolicies !== index && <ViewPolicyPanel policy={policy} index={index} onDeleteButtonClick={deletePolicyHandler}></ViewPolicyPanel>}
                        </div>)
                    )}
                </div>
            </div>
        </div>
    )
}

export default MyVerifiedNFTPolicies