import SubMenu from "../SubMenu";

const MenuDrop = ({data, selected, onClick}) => {
    const onClickHandler = (e) => {                
        if(e.checked)
        {
          selected.push(e.id)
        }
        else 
        {
          selected.splice(selected.indexOf(e.id),1);
        }
        onClick(selected);
    }
    return (
        data?.map((menuItem) => (
            <SubMenu key={menuItem?.id} selected={selected ?? []} onCheckClick={onClickHandler} menuData={menuItem}>
              {menuItem?.subcategories && (
                <MenuDrop data={menuItem?.subcategories} onClick={onClick} selected={selected} />
              )}
            </SubMenu>
          ))
    )
}
export default MenuDrop;