import Validators from "App/Components/Ui/Forms/Validators";
import Input from "App/Components/Ui/Input";
import Label from "App/Components/Ui/Label";
import RoundedButton from "App/Components/Ui/RoundedButton";
import styles from "../../../Profile/Profile.module.scss";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/Helper/formValidation";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "App/hooks-store/store";

const EditSection = ({ onCancel, wallet, editWallet, index }) => {
  const [formVaildState, setFormVaildState] = useState({});
  const formGroup = {
    walletName: {
      value: undefined,
      isRequired: true,
    },
  };
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };

  useEffect(() => {
    if (wallet?.walletAddress)
      inputOnChange({
        walletName: wallet?.walletName ?? `Wallet Nº${index+1}`,
      });
  }, [wallet?.walletAddress, wallet?.walletName, index]);

  const submitHandler = (e) => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    e.preventDefault();
    if (valid) {
      editWallet(
        {
          walletName: formValues?.walletName,
          walletAddress: wallet?.walletAddress,
          id:wallet?.id
        },
        () => onCancel()
      );
    } else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  };

  //Form error handler
  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  return (
    <div className="edit-section mt-[0.5rem] w-full">
      <div className="flex">
        <div className={`${styles["profile-row"]} flex flex-col  w-full`}>
          <div className={`${styles["label-box"]} font-14`}>
            <Label
              className={`${styles["label-color"]} text-left mb-[0.375rem]`}
            >
              Wallet Name
            </Label>
          </div>
          <div className={styles["input"]}>
            <Input
              placeholder="Wallet Name"
              label="walletName"
              validateOnBlur
              maxLength={25}
              onChange={inputOnChange}
              controlName="walletName"
              value={formValues.walletName}
              type="text"
              className="input-field"
            ></Input>
            <Validators
              type="Required"
              message="Required field"
              value={formValues.walletName}
              controlName="walletName"
              onErrors={collectErrors}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-start profile-head mob-left-align mt-[0.25rem] mr-[0.25rem]">
        <div className="flex gap-2">
          <RoundedButton
            Name="Cancel"
            Type="10"
            OnClick={onCancel}
          ></RoundedButton>
          <RoundedButton
            Name="Save"
            Type="9"
            OnClick={submitHandler}
          ></RoundedButton>
        </div>
      </div>
    </div>
  );
};

export default EditSection;
